import { ApiTableRef } from 'src/components/core/apiTable/ApiTable';
import { DefaultApiResult } from 'src/interface/management';
import { useLocale } from 'src/locales';
import { addNoticeAsync, NoticeMode, setDashboardState, updateNoticeAsync } from 'src/stores/dashboard.store';
import { Button, Descriptions, Input, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { MutableRefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


interface NoticeWriterProps {
   apiTableRef: MutableRefObject<ApiTableRef | undefined>
}

const NoticeWriter = (props: NoticeWriterProps) => {
   const dispatch = useDispatch();
   const { formatMessage } = useLocale();
   const { selectedNotice, noticeMode } = useSelector(state => state.dashboard)
   const [inputSubject, setInputSubject] = useState('')
   const [inputContents, setInputContents] = useState('')
   const [noticeType, setNoticeType] = useState(0) // 0: 일반 게시글, 1:주요공지

   const addNotice = async () => {
      dispatch(addNoticeAsync({
         subject: inputSubject,
         author: 'coo_hhc@ukmedia.co.kr',
         type: noticeType,
         contents: inputContents,
         callbackFunc: (result: DefaultApiResult<unknown>, type) => {
            if (result.isSuccess) {
               dispatch(setDashboardState({ selectedNotice: undefined, noticeMode: NoticeMode.List }))
               props.apiTableRef.current?.callApi()
            }
         }
      }))
   }

   const updateNotice = async () => {
      if (selectedNotice && selectedNotice?.id) {
         dispatch(updateNoticeAsync({
            id: selectedNotice?.id,
            subject: inputSubject,
            type: noticeType,
            contents: inputContents,
            callbackFunc: (result: DefaultApiResult<unknown>, type) => {
               if (result.isSuccess) {
                  dispatch(setDashboardState({ selectedNotice: undefined, noticeMode: NoticeMode.List }))
                  props.apiTableRef.current?.callApi()
               }
            }
         }))
      }
   }

   useEffect(() => {
      if (selectedNotice) {
         if (selectedNotice.subject) setInputSubject(selectedNotice.subject)
         if (selectedNotice.contents) setInputContents(selectedNotice.contents)
         if (selectedNotice.type) setNoticeType(selectedNotice.type)
      }
   }, [])

   return (
      <div className='noticeWriter'>
         <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 10px 0px 10px' }}>
            <Button onClick={(e) => dispatch(setDashboardState({ selectedNotice: undefined, noticeMode: NoticeMode.List }))}>뒤로</Button>
            <Button onClick={selectedNotice ? updateNotice : addNotice}>등록</Button>
         </div>
         <Descriptions style={{ margin: '7px', borderRadius: '10px', overflow: 'hidden', border: '1px solid #eaeaea' }} column={2} bordered labelStyle={{ background: '#f3f3f3' }} contentStyle={{ background: '#f9f9f9' }}>
            <Descriptions.Item style={{ width: '80px' }} span={2} label={formatMessage({ id: 'menu.dashboard.Notice.row.subject' })}><Input style={{ width: '100%' }} value={inputSubject} onChange={(e) => setInputSubject(e.target.value)} /></Descriptions.Item>
            <Descriptions.Item span={2} label={formatMessage({ id: 'menu.dashboard.Notice.row.type' })}>
               <Radio.Group value={noticeType} onChange={(e) => setNoticeType(e.target.value)}>
                  <Radio value={0}>일반</Radio>
                  <Radio value={1}>주요공지</Radio>
               </Radio.Group>
            </Descriptions.Item>
            <Descriptions.Item label={formatMessage({ id: 'menu.dashboard.Notice.row.contents' })}><TextArea rows={20} style={{ width: '100%' }} value={inputContents} onChange={(e) => setInputContents(e.target.value)} /></Descriptions.Item>
         </Descriptions>
      </div>
   );
}

export default NoticeWriter;