export enum ApiType {
   Registration = 1,
   Update = 2,
   Delete = 3,
   User = 4,
}

export interface SearchDefaultParams {
   name?: string;
}

export interface DeleteDefaultParams {
   id?: number;
   callbackFunc?: (res: DefaultApiResult<unknown>, type: ApiType) => void
}

export interface DefaultApiResult<T>{
   apiId: string,
   data: T,
   isSuccess : boolean
}

export interface PageParams {
   pageIdx?: number;
   pageItemCnt?: number;
}