export const enUS_publicMenu = {
   "global.menu.dashboard": "Dashboard",
   "global.menu.advertisement": "Advertisement",
   "global.menu.advertisement.company": "Advertisement Company Management",
   "global.menu.advertisement.brand": "Brand Management",
   "global.menu.advertisement.management": "Advertisement Management",
   "global.menu.advertisement.organize": "Advertisement Organize",
   "global.menu.advertisement.expunge": "Advertisement Expunge",
   "global.menu.statistics": "Statistics",
   "global.menu.statistics.advertisement": "Statistics by Advertisement",
   "global.menu.statistics.device": "Statistics by Device",
   "global.menu.device": "Device",
   "global.menu.device.company": "Device Company Management",
   "global.menu.device.location": "Location Management",
   "global.menu.device.management": "Device Management",
   "global.menu.device.group": "Group Management",
   "global.menu.contract": "Contract Management",
   "global.menu.manager": "Manager Management",
   "global.menu.log": "Log",
   "global.menu.log.device": "Device Log",
   "global.menu.log.call": "API Call Log",
   "global.menu.log.event": "AProm Event Log",
};
