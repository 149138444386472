import { Suspense, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { localeConfig, LocaleFormatter } from './locales';
import { ConfigProvider, Spin } from 'antd';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import koKR from 'antd/es/locale/ko_KR';
import enUS from 'antd/es/locale/en_US';
import moment from 'moment';
import 'moment/locale/ko';
import RenderRouter from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { history, HistoryRouter } from 'src/routes/history';
import { setConfigState } from './stores/config.store';
import ReactGA from 'react-ga';

ReactGA.initialize('G-X3E9TDND1H');

const themes = {
   light: 'https://cdn.jsdelivr.net/npm/antd@4.24.7/dist/antd.css',
   dark: 'https://cdn.jsdelivr.net/npm/antd@4.24.7/dist/antd.dark.css',
};

const App: React.FC = () => {
   const { locale } = useSelector(state => state.user);
   const { theme, loading } = useSelector(state => state.config);
   const dispatch = useDispatch();

   const setTheme = (dark = true) => {
      dispatch(
         setConfigState({
            theme: dark ? 'dark' : 'light',
         }),
      );
   };

   /** initial theme */
   useEffect(() => {
      setTheme(false);
      // setTheme(theme === 'dark');
      // // watch system theme change
      // if (!localStorage.getItem('theme')) {
      //    const mql = window.matchMedia('(prefers-color-scheme: dark)');

      //    function matchMode(e: MediaQueryListEvent) {
      //       setTheme(e.matches);
      //    }

      //    mql.addEventListener('change', matchMode);
      // }
   }, []);

   // set the locale for the user
   // more languages options can be added here
   useEffect(() => {
      if (locale === 'en_US') {
         moment.locale('en');
      } else if (locale === 'ko_KR') {
         moment.locale('ko');
      }
   }, [locale]);

   /**
    * handler function that passes locale
    * information to ConfigProvider for
    * setting language across text components
    */
   const getAntdLocale = () => {
      let lang;
      if (locale === 'en_US') {
         lang = enUS;
      } else {
         lang = koKR;
      }

      return lang;
   };

   const getAntdLocaleLang = () => {
      let lang;
      if (locale === 'en_US') lang = 'en';
      else if(locale == 'ko_KR') lang = 'ko';
      else lang = 'ko';

      return lang;
   }

   return (
      <ConfigProvider locale={getAntdLocale()} componentSize="middle">
         <ThemeSwitcherProvider defaultTheme={theme} themeMap={themes}>
            <IntlProvider locale={getAntdLocaleLang()} messages={localeConfig[locale]}>
               <HistoryRouter history={history}>
                  <Suspense fallback={null}>
                     <Spin
                        spinning={loading}
                        className="app-loading-wrapper"
                        tip={<LocaleFormatter id="gloabal.tips.loading" />}
                     ></Spin>
                     <RenderRouter />
                  </Suspense>
               </HistoryRouter>
            </IntlProvider>
         </ThemeSwitcherProvider>
      </ConfigProvider>
   );
};

export default App;
