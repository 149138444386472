export const koKR_dashboard = {
   'menu.dashboard.circleGraph.title': '광고 점유율',
   'menu.dashboard.circleGraph.sector1': '회사별 점유율',
   'menu.dashboard.circleGraph.sector2': '브랜드별 점유율',
   'menu.dashboard.statisticsGraph.viewCount': '시청자',
   'menu.dashboard.statisticsGraph.playCount': '광고노출',
   'menu.dashboard.AdStatus.today': '오늘의 광고',
   'menu.dashboard.AdStatus.week': '7일간 광고',
   'menu.dashboard.AdStatus.month': '30일간 광고',
   'menu.dashboard.AdStatus.row.adName': '광고',
   'menu.dashboard.AdStatus.row.startDate': '시작일',
   'menu.dashboard.AdStatus.row.endDate': '종료일',
   'menu.dashboard.AdStatus.row.period': '기간',
   'menu.dashboard.AdStatus.row.accepted': '승인',
   'menu.dashboard.AdStatus.row.deviceCount': '기기',
   // 게시판
   'menu.dashboard.Notice.title': '공지사항',
   'menu.dashboard.Notice.close': '닫기',
   'menu.dashboard.Notice.row.subject': '제목',
   'menu.dashboard.Notice.row.author': '작성자',
   'menu.dashboard.Notice.row.date': '작성일',
   'menu.dashboard.Notice.row.contents': '내용',
   'menu.dashboard.Notice.row.type': '타입'
};
