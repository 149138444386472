import { ApiTableRef } from 'src/components/core/apiTable/ApiTable';
import { DefaultApiResult } from 'src/interface/management';
import { useLocale } from 'src/locales';
import { deleteNoticeAsync, NoticeMode, setDashboardState } from 'src/stores/dashboard.store';
import { Button, Descriptions } from 'antd';
import { MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';


interface NoticeDetailProps{
   apiTableRef: MutableRefObject<ApiTableRef | undefined>
}

const NoticeDetail = (props:NoticeDetailProps) => {
   const dispatch = useDispatch();
   const { formatMessage } = useLocale();
   const { selectedNotice } = useSelector(state => state.dashboard)

   const deleteNotice = async () => {
      if (selectedNotice)
         dispatch(deleteNoticeAsync({ id: selectedNotice.id, callbackFunc: (result: DefaultApiResult<unknown>, type)=>{
            if(result.isSuccess){
               dispatch(setDashboardState({ selectedNotice: undefined, noticeMode: NoticeMode.List }))
               props.apiTableRef.current?.callApi()
            }
         }}));
   }

   function goToUpdate(){
      dispatch(setDashboardState({noticeMode: NoticeMode.Write}))
   }

   return (
      <>
         <div style={{ display: 'flex', justifyContent:'space-between', margin:'0px 10px 0px 10px'}}>
            <Button onClick={(e) => dispatch(setDashboardState({ selectedNotice: undefined, noticeMode: NoticeMode.List }))}>뒤로</Button>
            <div>
               <Button onClick={deleteNotice}>삭제</Button>
               <Button onClick={goToUpdate}>수정</Button>
            </div>
         </div>
         <Descriptions style={{ margin: '7px', borderRadius: '10px', overflow: 'hidden', border: '1px solid #eaeaea' }} column={3} bordered labelStyle={{ background: '#f3f3f3' }} contentStyle={{ background: '#f9f9f9' }}>
            <Descriptions.Item style={{ width: '80px' }} label={'No'}>{selectedNotice?.id}</Descriptions.Item>
            <Descriptions.Item label={formatMessage({ id: 'menu.dashboard.Notice.row.author' })}>{selectedNotice?.author}</Descriptions.Item>
            <Descriptions.Item label={formatMessage({ id: 'menu.dashboard.Notice.row.date' })}>{selectedNotice?.regDate}</Descriptions.Item>
            <Descriptions.Item span={3} label={formatMessage({ id: 'menu.dashboard.Notice.row.subject' })}><span className='ellipsesTableContents'>{selectedNotice?.subject}</span></Descriptions.Item>
            <Descriptions.Item label={formatMessage({ id: 'menu.dashboard.Notice.row.contents' })}><pre><span style={{ display: 'block', whiteSpace: 'pre-wrap' }}>{selectedNotice?.contents}</span></pre></Descriptions.Item>
         </Descriptions>
      </>
   );
}

export default NoticeDetail;