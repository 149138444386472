export const koKR_userManagement = {
   'menu.userManagement.email': '계정',
   'menu.userManagement.role': '권한',
   'menu.userManagement.name': '이름',
   'menu.userManagement.phone': '전화번호',
   'menu.userManagement.approval': '승인',
   'menu.userManagement.description': '상세설명',
   'menu.userManagement.search.email': '계정',
   'menu.userManagement.search.name': '이름',
   'menu.userManagement.manager.super': '슈퍼 관리자',
   'menu.userManagement.manager.company': '광고 회사 관리자',
   'menu.userManagement.manager.brand': '브랜드 관리자',
   'menu.userManagement.manager.device': '기기 회사 관리자',
   'menu.userManagement.manager.location': '매장 관리자',
   'menu.userManagement.menu.approval': '승인됨',
   'menu.userManagement.menu.approval.open': '승인하시겠습니까?',
   'menu.userManagement.modal.error.email': '이메일은 필수 입력 항목입니다.',
   'menu.userManagement.modal.error.validate': '유효하지 않은 이메일 형식입니다.',
   'menu.userManagement.modal.error.password': '비밀번호는 필수 입력 항목입니다.',
   'menu.userManagement.modal.error.authority': '권한은 필수 입력 항목입니다.',
   'menu.userManagement.modal.registration.title': '사용자 등록',
   'menu.userManagement.modal.update.title': '사용자 정보 수정',
   'menu.userManagement.modal.delete.title': '사용자 삭제',
   'menu.userManagement.modal.approval.title': '사용자 승인',
   'menu.userManagement.modal.delete.description': '삭제하시겠습니까?',
   'menu.userManagement.modal.approval.description': '기존 관리자가 있는 경우 삭제되고 새로 등록됩니다.',
   'menu.userManagement.modal.registration.ok': '등록',
   'menu.userManagement.modal.update.ok': '수정',
   'menu.userManagement.modal.approval.ok': '승인',
   'menu.userManagement.modal.registration.email': '이메일',
   'menu.userManagement.modal.registration.password': '비밀번호',
   'menu.userManagement.modal.registration.name': '이름',
   'menu.userManagement.modal.registration.phone': '연락처',
   'menu.userManagement.modal.registration.description': '설명',
   'menu.userManagement.modal.registration.authority': '권한',
   'menu.userManagement.modal.registration.permission': '접근권한',
   'menu.userManagement.modal.approval.role': '담당',
};
