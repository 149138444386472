import { apiReqDeleteCompany, apiReqDeleteNotice, apiReqNoticeList, apiReqRegistrationNotice, apiReqUpdateNotice } from '../api/management.api';
import { ApiType, DefaultApiResult, DeleteDefaultParams } from '../interface/management';
import { Notice, RegistrationNoticeParams, SearchNoticeParams } from '../interface/management/dashboard';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from './utils';

export enum NoticeMode {
   List, Write, Detail
}

export interface DashboardState {
   selectedNotice: Notice|undefined;
   noticeMode: NoticeMode;
}

export interface NoticeListState {
   noticeList: Notice[];
}

const initialState: DashboardState & NoticeListState = {
   selectedNotice : undefined,
   noticeMode: NoticeMode.List,
   noticeList: []
};

const dashboardSlice = createSlice({
   name: 'dashboard',
   initialState,
   reducers: {
      setDashboardState(state, action: PayloadAction<Partial<DashboardState>>) {
         Object.assign(state, action.payload);
      },
      setNoticeListState(state, action: PayloadAction<Partial<NoticeListState>>) {
         const { noticeList } = action.payload;

         state.noticeList = JSON.parse(JSON.stringify(noticeList));

         Object.assign(state, action.payload);
      },
   },
});

export const noticeListAsync = createAsyncAction<SearchNoticeParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqNoticeList(payload);

      console.log(result);

      if (result) {
         dispatch(setNoticeListState({ noticeList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const addNoticeAsync = createAsyncAction<RegistrationNoticeParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationNotice(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)

      return result;
   };
});

export const updateNoticeAsync = createAsyncAction<RegistrationNoticeParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateNotice(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)

      return result;
   };
});

export const deleteNoticeAsync = createAsyncAction<DeleteDefaultParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteNotice(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)

      return result;
   };
});

export const { setDashboardState, setNoticeListState } = dashboardSlice.actions;

export default dashboardSlice.reducer;
