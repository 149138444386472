import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyReg, SearchCompanyParams } from 'src/interface/management/user';
import { apiReqCompanyListForReg, apiReqDeleteContract, apiReqLocationListForReg, apiReqRegistrationContract, apiReqUpdateContract } from 'src/api/management.api';
import { createAsyncAction } from './utils';
import { DvLocationReg, SearchLocationParams } from 'src/interface/management/device';
import { ApiType, DefaultApiResult } from 'src/interface/management';
import { DeleteContractParams, RegistrationContractParams } from 'src/interface/management/contract';

export interface CompanyListState {
   companyList: CompanyReg[];
   companyLocationList: DvLocationReg[];
}

export interface LocationListState {
   locationList: DvLocationReg[];
}

export interface ContractLocationData {
   key: React.Key;
   selectedLocationName?: string;
   locationId: number;
   locationName: string;
   locationAddress: string;
   contractCount: number;
   deliveryCount: number;
   deliveryTotal: number;
   availableCount: number;
}

export interface ContractLocationState {
   contractLocationlist: ContractLocationData[]
}

const initialState: CompanyListState & LocationListState & ContractLocationData & ContractLocationState = {
   companyList: [], 
   companyLocationList: [], 
   locationList: [], 
   key: "", 
   selectedLocationName: undefined,
   locationId: 0,
   locationName: "",
   locationAddress: "",
   contractCount: 0,
   deliveryCount: 0,
   deliveryTotal: 0,
   availableCount: 0,
   contractLocationlist: [],
};

const contractManagementSlice = createSlice({
   name: 'deviceManagment',
   initialState,
   reducers: {
      setCompanyList(state, action: PayloadAction<Partial<CompanyListState>>) {
         const { companyList } = action.payload;

         state.companyList = JSON.parse(JSON.stringify(companyList));

         Object.assign(state, action.payload);
      },
      setCompanyLocationList(state, action: PayloadAction<Partial<CompanyListState>>) {
         const { companyLocationList } = action.payload;
         
         state.companyLocationList = JSON.parse(JSON.stringify(companyLocationList));
         
         Object.assign(state, action.payload);
      },
      setLocationList(state, action: PayloadAction<Partial<LocationListState>>) {
         const { locationList } = action.payload;

         state.locationList = JSON.parse(JSON.stringify(locationList));

         Object.assign(state, action.payload);
      },
      setContractCount(state, action: PayloadAction<Partial<ContractLocationData>>) {
         let cnt = 0;
         for (let i = 0; i < state.contractLocationlist.length; i++) {
            cnt += state.contractLocationlist[i].deliveryCount;
         }
         if (action.payload.contractCount!! - cnt < 0) return; 
         Object.assign(state, action.payload);
         state.availableCount = state.contractCount - cnt;
         state.deliveryTotal = state.availableCount - (action.payload.deliveryCount ? action.payload.deliveryCount : 0);
      },
      setContractLocationData(state, action: PayloadAction<Partial<ContractLocationData>>) {
         let cnt = 0;
         for (let i = 0; i < state.contractLocationlist.length; i++) {
            cnt += state.contractLocationlist[i].deliveryCount;
         }
         state.availableCount = state.contractCount - cnt;
         state.deliveryTotal = state.availableCount - (action.payload.deliveryCount ? action.payload.deliveryCount : 0);
         Object.assign(state, action.payload);
      },
      setContractLocationState(state, action: PayloadAction<Partial<ContractLocationState>>) {
         Object.assign(state, action.payload);
      },
   },
});

export const { setCompanyList, setLocationList, setCompanyLocationList, setContractCount, setContractLocationData, setContractLocationState } = contractManagementSlice.actions;

export default contractManagementSlice.reducer;

export const companyListAsync = createAsyncAction<SearchCompanyParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqCompanyListForReg(payload);

      console.log(result);

      if (result) {
         dispatch(setCompanyList({ companyList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const locationListAsync = createAsyncAction<SearchLocationParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqLocationListForReg(payload);

      console.log(result);

      if (result) {
         dispatch(setLocationList({ locationList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const companyLocationListAsync = createAsyncAction<SearchLocationParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqLocationListForReg(payload);

      console.log(result);

      if (result) {
         dispatch(setCompanyLocationList({ companyLocationList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const registrationContractAsync = createAsyncAction<RegistrationContractParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationContract(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration);

      return result;
   };
});

export const updateContractAsync = createAsyncAction<RegistrationContractParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateContract(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update);

      return result;
   };
});

export const deleteContractAsync = createAsyncAction<DeleteContractParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteContract(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete);

      return result;
   };
});