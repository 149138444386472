import { Notice } from 'src/interface/management/dashboard';
import { useLocale } from 'src/locales';
import { NoticeMode, setDashboardState } from 'src/stores/dashboard.store';
import { Button, Modal } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { useDispatch } from 'react-redux';
import NoticeList from './NoticeList';

export interface NoticeModalRef {
   showModal: (notice?: Notice | undefined) => void
}

const NoticeModal = forwardRef((props, ref) => {
   const [open, setOpen] = useState(false);
   const [disabled, setDisabled] = useState(false);
   const dispatch = useDispatch();
   const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
   const draggleRef = useRef<HTMLDivElement>(null);
   const { formatMessage } = useLocale()
   // { formatMessage({ id: 'menu.dashboard.AdStatus.title' }) }

   const showModal = (notice?:Notice|undefined) => {
      const mode = notice ? NoticeMode.Detail : NoticeMode.List
      console.log(`>>>>>>>>>>>>> showNoticeModal : ${mode}`)
      dispatch(setDashboardState({ selectedNotice: notice, noticeMode: mode }))
      setOpen(true);
   };

   const handleClose = (e: React.MouseEvent<HTMLElement>) => {
      console.log(e);
      setOpen(false);
   };

   const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
      const { clientWidth, clientHeight } = window.document.documentElement;
      const targetRect = draggleRef.current?.getBoundingClientRect();
      if (!targetRect) {
         return;
      }
      setBounds({
         left: -targetRect.left + uiData.x,
         right: clientWidth - (targetRect.right - uiData.x),
         top: -targetRect.top + uiData.y,
         bottom: clientHeight - (targetRect.bottom - uiData.y),
      });
   };

   useImperativeHandle(ref, () => ({
      showModal
   }))

   return (
      <Modal
         style={{marginTop:'10px', marginBottom:'10px'}}
         className='noticeModal'
         centered
         title={
            <div
               // style={{ width: '100%', cursor: 'move', }}
               // onMouseOver={() => { if (disabled) setDisabled(false) }}
               // onMouseOut={() => { setDisabled(true) }}
               // onFocus={() => { }}
               // onBlur={() => { }}
            // end
            >
               {formatMessage({ id: 'menu.dashboard.Notice.title' })}
            </div>
         }
         open={open}
         onCancel={handleClose}
         // modalRender={(modal) => (
         //    <Draggable
         //       disabled={disabled}
         //       bounds={bounds}
         //       onStart={(event, uiData) => onStart(event, uiData)}
         //    >
         //       <div ref={draggleRef}>{modal}</div>
         //    </Draggable>
         // )}
         footer={[
            <Button key="back" onClick={handleClose}>{formatMessage({ id: 'menu.dashboard.Notice.close' })}</Button>
         ]}
         width={'70%'}
         >
         <NoticeList/>
      </Modal>
   );
})

export default NoticeModal;