import { combineReducers } from '@reduxjs/toolkit';
import tagsViewReducer from './tags-view.store';
import configReducer from './config.store';
import userReducer from './user.store';
import adManagementReducer from './adManagement.store';
import searchReducer from './search.store';
import dashboardReducer from './dashboard.store';
import userManagementReducer from './userManagement.store';
import deviceManagementReducer from './deviceManagement.store';
import contractManagementReducer from './contractManagement.store';
import dataManagementReducer from './dataManagement.store';

const rootReducer = combineReducers({
   tagsView: tagsViewReducer,
   config: configReducer,
   user: userReducer,
   adMng: adManagementReducer,
   search: searchReducer,
   dashboard: dashboardReducer,
   userMng: userManagementReducer,
   deviceMng: deviceManagementReducer,
   contractMng: contractManagementReducer,
   dataMng: dataManagementReducer,
});

export default rootReducer;
