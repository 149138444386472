export const koKR_title = {
   "title.login": "로그인",
   "title.findId": "아이디찾기",
   "title.register": "회원가입",
   "title.userInfo": "회원정보",
   "title.update": "회원정보수정",
   "title.dashboard": "대시보드",
   "title.notice": "공지사항",
   "title.adManagement": "광고 관리",
   "title.adManagement.registration": "광고 관리",
   "title.adManagement.deviceOrganize": "광고 관리",
   "title.adManagement.deviceExpunge": "광고 관리",
   "title.dataManagement": "데이터 관리",
   "title.dataManagement.adBased": "데이터 관리",
   "title.dataManagement.deviceBased": "데이터 관리",
   "title.deviceManagement": "기기 관리",
   "title.deviceManagement.deviceReg": "기기 관리",
   "title.deviceManagement.groupReg": "기기 관리",
   "title.deviceManagement.locationReg": "기기 관리",
   "title.contractManagement.contractReg": "계약 관리",
   "title.userManagement": "사용자 관리",
   "title.userManagement.company": "사용자 관리",
   "title.userManagement.brand": "사용자 관리",
   "title.userManagement.userInfo": "사용자 관리",
   "title.log.device": "디바이스 로그",
   "title.log.call": "디바이스 로그",
   "title.log.event": "디바이스 로그",
};
