export const enUS_brandManagement = {
   'menu.brandManagement.company': 'Company',
   'menu.brandManagement.name': 'Brand',
   'menu.brandManagement.code': 'Code',
   'menu.brandManagement.adCount': 'AdCount',
   'menu.brandManagement.description': 'Description',
   'menu.brandManagement.manager': 'Manager',
   'menu.brandManagement.email': 'Email',
   'menu.brandManagement.phone': 'Phone',
   'menu.brandManagement.search.company': 'Company',
   'menu.brandManagement.search.name': 'Brand',
   'menu.brandManagement.info.message': ' includes the brands below.',
   'menu.brandManagement.modal.error': 'Please Input Brand Name',
   'menu.brandManagement.modal.error.company': 'Please Select Company',
   'menu.brandManagement.modal.registration.title': 'Brand Registration',
   'menu.brandManagement.modal.update.title': 'Brand Update',
   'menu.brandManagement.modal.delete.title': 'Brand Delete',
   'menu.brandManagement.modal.delete.description': 'Do you want delete?',
   'menu.brandManagement.modal.registration.ok': 'Registration',
   'menu.brandManagement.modal.update.ok': 'Update',
   'menu.brandManagement.modal.registration.name': 'Name',
   'menu.brandManagement.modal.registration.description': 'Description',
   'menu.brandManagement.modal.registration.companyName': 'Company',
   'menu.brandManagement.modal.registration.code': 'Code',
   'menu.brandManagement.modal.registration.manager': 'Manager',
   'menu.brandManagement.modal.registration.email': 'Email',
   'menu.brandManagement.modal.registration.phone': 'Phone',
};
