import { MenuData } from "../interface/layout/menu.interface";
import { FormatMessageProps } from "../locales";

export function GetMenu(formatMessage: FormatMessageProps): Map<string, MenuData> {
   const menuDataMap = new Map<string, MenuData>();
   menuDataMap.set('dashboard', {
      code: 'dashboard',
      label: formatMessage({ id: 'global.menu.dashboard' }),
      icon: 'dashboard',
   });
   menuDataMap.set('adManagement', {
      code: 'adManagement',
      label: formatMessage({ id: 'global.menu.advertisement' }),
      icon: 'advertisement',
      children: [],
   });
   menuDataMap.set('adCompanyRegAndMng', {
      code: 'adCompanyRegAndMng',
      label: formatMessage({ id: 'global.menu.advertisement.company' }),
   });
   menuDataMap.set('adBrandRegAndMng', {
      code: 'adBrandRegAndMng',
      label: formatMessage({ id: 'global.menu.advertisement.brand' }),
   });
   menuDataMap.set('adRegAndMng', {
      code: 'adRegAndMng',
      label: formatMessage({ id: 'global.menu.advertisement.management' }),
   });
   menuDataMap.set('adDeviceOrganize', {
      code: 'adDeviceOrganize',
      label: formatMessage({ id: 'global.menu.advertisement.organize' }),
   });
   menuDataMap.set('adDeviceExpunge', {
      code: 'adDeviceExpunge',
      label: formatMessage({ id: 'global.menu.advertisement.expunge' }),
   });
   menuDataMap.set('dataManagement', {
      code: 'dataManagement',
      label: formatMessage({ id: 'global.menu.statistics' }),
      icon: 'monitor',
      children: [],
   });
   menuDataMap.set('adBasedDataMng', {
      code: 'adBasedDataMng',
      label: formatMessage({ id: 'global.menu.statistics.advertisement' }),
   });
   menuDataMap.set('deviceBasedDataMng', {
      code: 'deviceBasedDataMng',
      label: formatMessage({ id: 'global.menu.statistics.device' }),
   });
   menuDataMap.set('deviceManagement', {
      code: 'deviceManagement',
      label: formatMessage({ id: 'global.menu.device' }),
      icon: 'device',
      children: [],
   });
   menuDataMap.set('locCompanyRegAndMng', {
      code: 'locCompanyRegAndMng',
      label: formatMessage({ id: 'global.menu.device.company' }),
   });
   menuDataMap.set('locationRegAndMng', {
      code: 'locationRegAndMng',
      label: formatMessage({ id: 'global.menu.device.location' }),
   });
   menuDataMap.set('deviceRegAndMng', {
      code: 'deviceRegAndMng',
      label: formatMessage({ id: 'global.menu.device.management' }),
   });
   menuDataMap.set('groupRegAndMng', {
      code: 'groupRegAndMng',
      label: formatMessage({ id: 'global.menu.device.group' }),
   });
   menuDataMap.set('contractRegAndMng', {
      code: 'contractRegAndMng',
      label: formatMessage({ id: 'global.menu.contract' }),
      icon: 'users',
   });
   menuDataMap.set('userManagement', {
      code: 'userManagement',
      label: formatMessage({ id: 'global.menu.manager' }),
      icon: 'management',
   });
   menuDataMap.set("log", {
      code: "log",
      label: formatMessage({ id: "global.menu.log" }),
      icon: "logs",
      children: [],
   });
   menuDataMap.set('deviceLog', {
      code: 'deviceLog',
      label: formatMessage({ id: 'global.menu.log.device' }),
      icon: 'logs',
   });
   menuDataMap.set("callLog", {
      code: "callLog",
      label: formatMessage({ id: "global.menu.log.call" }),
      icon: "logs",
   });
   menuDataMap.set("apromEventLog", {
      code: "apromEventLog",
      label: formatMessage({ id: "global.menu.log.event" }),
      icon: "logs",
   });
   return menuDataMap;
}
