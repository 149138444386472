import { FC, useState } from 'react';
import { Modal, Tabs } from 'antd';
import './index.less';
import logo from 'src/assets/login/ukmedia_logo.png';
import errorIcon from 'src/assets/login/sb_login_error.png';
import exitIcon from 'src/assets/login/exit.png';
import { useDispatch, useSelector } from 'react-redux';
import { findIdAsync, initPasswordAsync, setLoginState } from "src/stores/user.store";
import { useLocale } from 'src/locales';
import { useNavigate } from 'react-router-dom';

const FindIdForm: FC = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { formatMessage } = useLocale();
   const { findIdTab } = useSelector(state => state.user);
   const [email, setEmail] = useState<string>('');
   const [manager, setManager] = useState<string>('');
   const [phoneNumber1, setPhoneNumber1] = useState<string>('');
   const [phoneNumber2, setPhoneNumber2] = useState<string>('');

   const [ment, setMent] = useState<string>('');
   const [isComp, setComp] = useState<boolean>(false);
   const [findId, setFindId] = useState<string>('');

   const [isModalOpen, setModalOpen] = useState<boolean>(false);

   const renderMent = (title: string, desc: string) => {
      return <div className='find-ment'><b>{title}</b>{ment ? <p style={{color:'#ED1C24'}}>{ment}</p> : <p>{desc}</p>}</div>
   }

   const initialize = () => {
      setEmail('');
      setManager('');
      setPhoneNumber1('');
      setPhoneNumber2('');
      setComp(false);
   }
   
   const handleTab = (e: any) => {
      initialize();
      dispatch(setLoginState({ findIdTab: e }))
   }

   const handleEmail = (e: any) => {
      if (ment) setMent('');
      setEmail(e.target.value);
   }

   const handleManager = (e: any) => {
      if (ment) setMent('');
      setManager(e.target.value);
   }

   const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>, setPhoneNumberFunc?: (phoneNumber: string) => void) => {
      if (ment) setMent('');
      const { value } = e.target
      const onlyNumber = value.replace(/[^0-9]/g, '');
      setPhoneNumberFunc && setPhoneNumberFunc(onlyNumber);
   }

   const onFind = () => {
      if(!manager) setMent(formatMessage({ id: 'findId.err.manager' }));
      else if(!phoneNumber1 || !phoneNumber2) setMent(formatMessage({ id: 'findId.err.phone' }));
      else {
         dispatch(findIdAsync({
            manager: manager, phoneNumber: `010${phoneNumber1}${phoneNumber2}`, callbackFunc: (res) => {
               if (res.data.isSuccess) {
                  setComp(true);
                  setFindId(res.data.id);
               } else {
                  initialize();
                  setModalOpen(true);
               }
            }
         }));
      }
   }

   const modifyFindId = (id: string): string => {
      let idx = id.indexOf('@');
      let modifiedId = '';
      if (idx < 0) for (let i = 0; i < id.length; i++) modifiedId += i < 5 ? id[i] : '*';
      else for (let i = 0; i < id.length; i++) modifiedId += i < 5 ? id[i] : i < idx ? '*' : i == idx ? '@' : '*';
      return modifiedId;
   }

   const onInit = () => {
      if (!email) setMent(formatMessage({ id: 'initPassword.err.email' }));
      else if (!manager) setMent(formatMessage({ id: 'findId.err.manager' }));
      else if (!phoneNumber1 || !phoneNumber2) setMent(formatMessage({ id: 'findId.err.phone' }));
      else {
         dispatch(initPasswordAsync({
            email: email, manager: manager, phoneNumber: `010${phoneNumber1}${phoneNumber2}`, callbackFunc: (res) => {
               if (res.data.isSuccess) {
                  sessionStorage.removeItem('pwdCnt');
                  setComp(true);
               } else {
                  initialize();
                  setModalOpen(true);
               }
            }
         }));
      }
   }

   const findIdContents = [
      {
         content: <div className='find-content'>
            {
               isComp ? <>
                  {renderMent(formatMessage({ id: 'findId.title.complete' }), formatMessage({ id: 'findId.ment.complete' }))}
                  <div className='complete-result'>
                     <div>
                        <p>{formatMessage({ id: 'findId.detail1.complete' })}</p>&nbsp;
                        <span>{modifyFindId(findId)}</span>&nbsp;
                        <p>{formatMessage({ id: 'findId.detail2.complete' })}</p>
                     </div>
                  </div>
                  <button className='login-button' onClick={() => navigate('/login')}>{formatMessage({ id: 'findId.complete.button' })}</button>
               </> : <>
                  {renderMent(formatMessage({ id: 'findId.title.findId' }), formatMessage({ id: 'findId.ment.findId' }))}
                  <div>
                     <br /><br />
                     {/* <div className='input-find'>
                        <p>{formatMessage({ id: 'findId.company' })}</p>
                        <input type='text' required value={company} onChange={handleCompany} />
                     </div> */}
                     <div className='input-find'>
                        <p>{formatMessage({ id: 'findId.manager' })}</p>
                        <input type='text' required value={manager} onChange={handleManager} />
                     </div>
                     <div className='input-find'>
                        <p>{formatMessage({ id: 'findId.phone' })}</p>
                        <div className='input-phone'>
                           <input type='text' required value={'010'} readOnly /><p>-</p>
                           <input type='text' required maxLength={4} value={phoneNumber1} onChange={e => handlePhoneNumber(e, setPhoneNumber1)} /><p>-</p>
                           <input type='text' required maxLength={4} value={phoneNumber2} onChange={e => handlePhoneNumber(e, setPhoneNumber2)} />
                        </div>
                     </div>
                     <button className='login-button' onClick={onFind}>{formatMessage({ id: 'findId.button' })}</button>
                  </div>
               </>
            }
         </div>
      },
      { content: <div className='find-content'>
         {
            isComp ? <>
               <div style={{height:'100px'}}/>
               {renderMent(formatMessage({ id: 'initPassword.title.complete' }), formatMessage({ id: 'initPassword.ment.complete' }))}
               <button className='login-button' onClick={() => navigate('/login')}>{formatMessage({ id: 'findId.complete.button' })}</button>
            </> : <>
               {renderMent(formatMessage({ id: 'initPassword.title.initPassword' }), formatMessage({ id: 'findId.ment.findId' }))}
               <div className='input-find'>
                  <p>{formatMessage({ id: 'initPassword.email' })}</p>
                  <input type='text' required value={email} onChange={handleEmail} />
               </div>
               <div className='input-find'>
                  <p>{formatMessage({ id: 'findId.manager' })}</p>
                  <input type='text' required value={manager} onChange={handleManager} />
               </div>
               <div className='input-find'>
                  <p>{formatMessage({ id: 'findId.phone' })}</p>
                  <div className='input-phone'>
                     <input type='text' required value={'010'} readOnly /><p>-</p>
                     <input type='text' required maxLength={4} value={phoneNumber1} onChange={e => handlePhoneNumber(e, setPhoneNumber1)} /><p>-</p>
                     <input type='text' required maxLength={4} value={phoneNumber2} onChange={e => handlePhoneNumber(e, setPhoneNumber2)} />
                  </div>
               </div>
               <button className='login-button' onClick={onInit}>{formatMessage({ id: 'initPassword.button' })}</button>
            </>
         }
      </div> },
      { content: <></> },
   ];

   const renderBottom = () => {
      return <>
         <div className='find-seperator' />
         <div className='find-bottom-ment'>{formatMessage({ id: 'footer.ment' })}</div>
         <div className='find-bottom-info'>{formatMessage({ id: 'footer.info' })}</div>
      </>
   }

   const renderModal = () => {
      return <Modal
         open={isModalOpen}
         onCancel={() => setModalOpen(false)}
         maskClosable={false}
         width={'550px'}
         bodyStyle={{height:'330px', borderRadius:'10px', padding:'0px'}}
         footer={false}
      >
         <div className='error-modal-content'>
            <img src={errorIcon} />
            <p>{formatMessage({ id: 'findId.err.title' })}</p>
            <div><p>{formatMessage({ id: 'findId.err.ment' })}</p></div>
         </div>
         <div className='error-modal-footer'>
            <p>{formatMessage({ id: 'findId.err.footer' })}</p>
            <div><p>{formatMessage({ id: 'findId.err.info' })}</p></div>
         </div>
      </Modal>
   }

   return (
      <div>
         <div className="background">
            <div className="find-form">
               <img width={'25px'} height={'25px'} style={{marginLeft:'1065px', marginTop:'10px', cursor:'pointer'}} src={exitIcon} onClick={() => navigate(-1)}/>
               <div className='find-logo'><img src={logo} /></div>
               <Tabs style={{marginTop:'30px'}} activeKey={findIdTab} onChange={handleTab}>
                  <Tabs.TabPane tab={<div style={{width:'484px', textAlign:'center'}}>{formatMessage({ id: 'findId.tab.findId' })}</div>} key="1">
                     {findIdContents[0].content}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<div style={{width:'484px', textAlign:'center'}}>{formatMessage({ id: 'findId.tab.initPassword' })}</div>} key="2">
                     {findIdContents[1].content}
                  </Tabs.TabPane>
               </Tabs>
               {renderBottom()}
            </div>
            {renderModal()}
         </div>
      </div>
   );
};

export default FindIdForm;
