import { getRandomInt } from './mathUtil';
import moment from 'moment';

export function addComma(src: string | number): string {
   src = src + "";
   var temp_str = src.replace(/,/g, "");

   for (var i = 0, retValue = String(), stop = temp_str.length; i < stop; i++) {
      retValue = ((i % 3) == 0) && i != 0 ? temp_str.charAt((stop - i) - 1) + "," + retValue : temp_str.charAt((stop - i) - 1) + retValue;
   }

   return retValue;
};

export function getRandomKey(name: string): string {
   let now = (moment.now());
   return `${name}_${moment(now).format("YYYY,MM,DD")}_${moment.unix(now)}_${getRandomInt(0, Number.MAX_SAFE_INTEGER)}`;
}

export function phoneNumberFormat(input?: string) {
   try {
      if (!input) return
      const cleanInput = input.replaceAll(/[^0-9]/g, "");
      let result: string = "";
      const length = cleanInput.length;
      if (length === 8) {
         result = cleanInput.replace(/(\d{4})(\d{4})/, '$1-$2');
      } else if (cleanInput.startsWith("02") && (length === 9 || length === 10)) {
         result = cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3');
      } else if (!cleanInput.startsWith("02") && (length === 10 || length === 11)) {
         result = cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
      } else {
         result = "";
      }
      return result;
   } catch (e) {
      console.log("failed to Parse (cannot parse to Phone's Format)", e);
      return input;
   }
}

export function identifyIdFormat(number: string) {
   if (number.length == 10 || number.length == 11) {
      //사업자 번호
      return number.replace(/-/g, '').replace(/(\d{3})(\d{2})(\d{5})/g, '$1-$2-$3');

   } else if (number.length == 13) {
      return number.replace(/-/g, '').replace(/(\d{6})(\d{7})/g, '$1-$2');
   } else return number

}

export function getTimeStringByDate(dateObj: Date, format: string = "YYYY.MM.DD HH:mm:ss", todayWithTime = true, showToday = true, setUtc = false) {
   let date = moment(dateObj)
   if(setUtc) date = date.utc()
   return getTimeString(date, format, todayWithTime, showToday);
}

export function getTimeStringByTimestamp(timeStamp: number, format: string = "YYYY.MM.DD HH:mm:ss", todayWithTime = true, showToday = true) {
   var date = moment(timeStamp)
   return getTimeString(date, format, todayWithTime, showToday);
}

function getTimeString(date: moment.Moment, format: string = "YYYY.MM.DD HH:mm:ss", todayWithTime = true, showToday = true){
   let today = moment()
   return (showToday && date.format('YYYYMMDD') == today.format('YYYYMMDD')) ? "오늘 " + (todayWithTime ? date.format('HH:mm:ss') : '') : date.format(format)
}


export function validateEmail(email: string) {
   var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
   return re.test(email);
}

export function isTrue(value: string | null): boolean {
   return value != null && 'TtTruetrue'.includes(value);
}

export function getNumber(value: string | null): number {
   return value == null ? 0 : Number(value);
}

/**
 * Javascript Object 객체의 value값을 uriEncode 적용한다.
 */
export function uriEncodeObjectsValue(o: any) {
   if (typeof o === 'object') {
      Object.keys(o).forEach(function (k) {
         console.log("###########!!@#", k, ":", o[k], "type => ", typeof o[k]);

         if (o[k] !== null && typeof o[k] === 'object') {
            uriEncodeObjectsValue(o[k]);
            return;
         }
         if (typeof o[k] === 'string') {
            if (o[k].includes('\\')) o[k] = o[k].replaceAll('\\', '\\\\');
            if (o[k].includes('\"')) o[k] = o[k].replaceAll('\"', '\\\"');

            console.log("###########!!@######### replaced", k, ":", o[k], "type => ", typeof o[k]);

            o[k] = encodeURIComponent(o[k]);
         }
      });
   }
   return o;
}