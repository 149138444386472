export const enUS_groupRegistration = {
   'menu.groupRegistration.name': 'name',
   'menu.groupRegistration.description': 'Description',
   'menu.groupRegistration.deviceCount': 'DeviceCount',
   'menu.groupRegistration.search.name': 'Search by Name',
   'menu.groupRegistration.search.desc': 'Search by Description',
   'menu.groupRegistration.modal.error': 'Please Input Group Name',
   'menu.groupRegistration.modal.registration.title': 'Group Registration',
   'menu.groupRegistration.modal.update.title': 'Group Update',
   'menu.groupRegistration.modal.delete.title': 'Group Delete',
   'menu.groupRegistration.modal.delete.description': 'Do you want delete?',
   'menu.groupRegistration.modal.registration.ok': 'Registration',
   'menu.groupRegistration.modal.update.ok': 'Update',
   'menu.groupRegistration.modal.registration.name': 'Name',
   'menu.groupRegistration.modal.registration.description': 'Description',
   'menu.groupRegistration.modal.registration.device': 'Device',

};