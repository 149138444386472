export const koKR_deviceRegistration = {
   'menu.deviceRegistration.id': '시리얼넘버',
   'menu.deviceRegistration.activationCode': '활성화코드',
   'menu.deviceRegistration.location': '위치',
   'menu.deviceRegistration.address': '주소',
   'menu.deviceRegistration.email': '메일',
   'menu.deviceRegistration.name': '관리자',
   'menu.deviceRegistration.phone': '연락처',
   'menu.deviceRegistration.description': '비고',
   'menu.deviceRegistration.activated': '개통',
   'menu.deviceRegistration.activatedDate': '작동 시작일',
   'menu.deviceRegistration.activatedPeriod': '작동일',
   'menu.deviceRegistration.washCount': '세척',
   'menu.deviceRegistration.state': '상태',
   'menu.deviceRegistration.adCount': '유치광고',
   'menu.deviceRegistration.cumulativeAdCount': '누적 유치광고',
   'menu.deviceRegistration.totalWashCount': '총 누적 작동',
   'menu.deviceRegistration.totalPlayCount': '총 누적 광고 재생',
   'menu.deviceRegistration.totalViewCount': '총 누적 시청',
   'menu.deviceRegistration.totalURLCount': '총 누적 URL',
   'menu.deviceRegistration.totalQRCount': '총 누적 QR',
   'menu.deviceRegistration.search.name': '시리얼넘버',
   'menu.deviceRegistration.search.address': '주소',
   'menu.deviceRegistration.search.description': '비고',
   'menu.deviceRegistration.organizedAd': '편성정보',
   'menu.deviceRegistration.history': '개통이력',
   'menu.deviceRegistration.info.message.group': ' 그룹에 속한 기기 목록입니다.',
   'menu.deviceRegistration.info.message.location': '에 설치된 기기 목록입니다.',
   'menu.deviceRegistration.info.message.contract': ' 계약, ',
   'menu.deviceRegistration.modal.error.id': '시리얼넘버는 필수 입력 항목입니다.',
   'menu.deviceRegistration.modal.error.location': '주소는 필수 입력 항목입니다.',
   'menu.deviceRegistration.modal.error.cancel.name': '취소하시는 분의 이름을 입력해 주세요.',
   'menu.deviceRegistration.modal.error.cancel.phone': '취소하시는 분의 전화번호를 입력해 주세요.',
   'menu.deviceRegistration.modal.error.cancel.company': '취소하시는 분의 소속을 입력해 주세요.',
   'menu.deviceRegistration.modal.registration.title': '기기 등록',
   'menu.deviceRegistration.modal.update.title': '기기 정보 수정',
   'menu.deviceRegistration.modal.delete.title': '기기 삭제',
   'menu.deviceRegistration.modal.organizedAd.title': '광고 편성 정보',
   'menu.deviceRegistration.modal.history.title': '기기 개통 이력',
   'menu.deviceRegistration.modal.cancel.title': '개통 취소',
   'menu.deviceRegistration.modal.cancel.ment': '현재 기기 개통 취소하시는 분의 정보를 입력해 주세요',
   'menu.deviceRegistration.modal.delete.description': '삭제하시겠습니까?',
   'menu.deviceRegistration.modal.registration.ok': '등록',
   'menu.deviceRegistration.modal.update.ok': '수정',
   'menu.deviceRegistration.modal.adjust': '편성순서 변경',
   'menu.deviceRegistration.modal.close': '닫기',
   'menu.deviceRegistration.modal.cancel': '취소',
   'menu.deviceRegistration.modal.registration.name': '시리얼넘버',
   'menu.deviceRegistration.modal.registration.notRegist': '미등록',
   'menu.deviceRegistration.modal.registration.activateCode': '작동코드',
   'menu.deviceRegistration.modal.registration.location': '주소',
   'menu.deviceRegistration.modal.registration.description': '비고',
   'menu.deviceRegistration.modal.registration.group': '그룹',
   'menu.deviceRegistration.modal.cancel.name': '이름',
   'menu.deviceRegistration.modal.cancel.phone': '전화번호',
   'menu.deviceRegistration.modal.cancel.company': '회사',
   'menu.deviceRegistration.modal.cancel.memo': '메모',
   'menu.deviceRegistration.modal.cancel.description': '취소 설명',
};