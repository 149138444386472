import { koKR_avatorDropMenu } from './user/avatorDropMenu';
import { koKR_tagsViewDropMenu } from './user/tagsViewDropMenu';
import { koKR_title } from './user/title';
import { koKR_login } from './user/login';
import { koKR_publicMenu } from './public/menu';
import { koKR_publicTips } from './public/tips';
import { koKR_commonMenu } from './menu/commonMenu';
import { koKR_userManagement } from './menu/userManagement';
import { koKR_brandManagement } from './menu/brandManagement';
import { koKR_companyManagement } from './menu/companyManagement';
import { koKR_contractRegistration } from './menu/contractRegistration';
import { koKR_deviceRegistration } from './menu/deviceRegistration';
import { koKR_groupRegistration } from './menu/groupRegistration';
import { koKR_locationRegistration } from './menu/locationRegistration';
import { koKR_adRegistration } from './menu/adRegistration';
import { koKR_dashboard } from './menu/dashboard';
import { koKR_etcMenu } from './menu/etc';
import { koKR_etc } from './public/etc';

const ko_KR = {
   ...koKR_avatorDropMenu,
   ...koKR_tagsViewDropMenu,
   ...koKR_title,
   ...koKR_login,
   ...koKR_publicMenu,
   ...koKR_publicTips,
   ...koKR_commonMenu,
   ...koKR_userManagement,
   ...koKR_brandManagement,
   ...koKR_companyManagement,
   ...koKR_contractRegistration,
   ...koKR_deviceRegistration,
   ...koKR_groupRegistration,
   ...koKR_locationRegistration,
   ...koKR_adRegistration,
   ...koKR_dashboard,
   ...koKR_etcMenu,
   ...koKR_etc,
};

export default ko_KR;
