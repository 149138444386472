export const koKR_companyManagement = {
   'menu.companyManagement.company': '회사',
   'menu.companyManagement.code': '소속코드',
   'menu.companyManagement.type': '타입',
   'menu.companyManagement.brand': '브랜드',
   'menu.companyManagement.location': '매장',
   'menu.companyManagement.description': '상세설명',
   'menu.companyManagement.manager': '담당자',
   'menu.companyManagement.email': '메일',
   'menu.companyManagement.phone': '전화번호',
   'menu.companyManagement.search.name': '회사명 검색',
   'menu.companyManagement.search.desc': '설명으로 검색',
   'menu.companyManagement.modal.error': '회사 이름은 필수 입력 항목입니다.',
   'menu.companyManagement.modal.registration.title': '회사 등록',
   'menu.companyManagement.modal.update.title': '회사 수정',
   'menu.companyManagement.modal.delete.title': '회사 삭제',
   'menu.companyManagement.modal.delete.description': '삭제하시겠습니까?',
   'menu.companyManagement.modal.registration.ok': '등록',
   'menu.companyManagement.modal.update.ok': '수정',
   'menu.companyManagement.modal.registration.name': '이름',
   'menu.companyManagement.modal.registration.type': '타입',
   'menu.companyManagement.modal.registration.description': '설명',
   'menu.companyManagement.modal.registration.manager': '담당자',
   'menu.companyManagement.modal.registration.email': '이메일',
   'menu.companyManagement.modal.registration.phone': '연락처',
};
