export function getRandomInt(min: number=0, max: number=Number.MAX_SAFE_INTEGER) {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
}

/**
 * True or False 반환
 * @param chanceOfTrue true가 나올 확률. 기본값은 50%
 */
export function getRandomBool(chanceOfTrue = 50): boolean{
   return Math.random() < chanceOfTrue / 100
}
