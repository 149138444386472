import { FC } from 'react';
import { LogoutOutlined, UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout, Dropdown, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LanguageSvg } from 'src/assets/header/language.svg';
import { ReactComponent as EnUsSvg } from 'src/assets/header/en_US.svg';
import { ReactComponent as KoKrSvg } from 'src/assets/header/ko_KR.svg';
import { LocaleFormatter, useLocale } from 'src/locales';
import ReactSvg from 'src/assets/logo/react.svg';
import { setUserItem } from 'src/stores/user.store';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigState } from 'src/stores/config.store';
import logo from 'src/assets/login/ukmedia_logo.png';

const { Header } = Layout;

interface HeaderProps {
   collapsed: boolean;
   toggle: () => void;
}

type Action = 'userInfo' | 'userSetting' | 'logout';

const HeaderComponent: FC<HeaderProps> = ({ collapsed, toggle }) => {
   const { logged, nickname, locale, device } = useSelector(state => state.user);
   const { theme } = useSelector(state => state.config);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { formatMessage } = useLocale();

   const onActionClick = async (action: Action) => {
      switch (action) {
         case 'userInfo':
            console.log('userInfo');
            navigate('/userInfo');
            return;
         case 'userSetting':
            console.log('userSetting');
            return;
         case 'logout':
            dispatch(setUserItem({ saveId: false, userId: '', logged: false }));
            navigate('/login');
            return;
      }
   };

   const toLogin = () => {
      navigate('/login');
   };

   const selectLocale = ({ key }: { key: any }) => {
      dispatch(setUserItem({ locale: key }));
      localStorage.setItem('locale', key);
   };

   const onChangeTheme = () => {
      const newTheme = theme === 'dark' ? 'light' : 'dark';

      localStorage.setItem('theme', newTheme);
      dispatch(
         setConfigState({
            theme: newTheme,
         }),
      );
   };
   const menu = (
      <Menu>
         <Menu.Item key="1">
            <span>
               <UserOutlined />
               <span onClick={() => onActionClick('userInfo')}>
                  <LocaleFormatter id="header.avator.account" />
               </span>
            </span>
         </Menu.Item>
         <Menu.Divider />
         <Menu.Item key="2">
            <span>
               <LogoutOutlined />
               <span onClick={() => onActionClick('logout')}>
                  <LocaleFormatter id="header.avator.logout" />
               </span>
            </span>
         </Menu.Item>
      </Menu>
   );

   return (
      <Header className="layout-page-header bg-2">
         {device !== 'MOBILE' && (
            // <div className="logo" style={{ width: collapsed ? 80 : 300, fontSize: '1.6em' }}>
            //    {collapsed ? <img style={{ marginLeft: '6px', width: '40px' }} src={ReactSvg} /> : <span>Selling Board</span>}
            //    {/* <img src={paloIcon} alt="" style={{  }} /> */}
            // </div>
            <div style={{marginLeft:'20px'}}><img height={'35px'} src={logo} /></div>
         )}
         <div className="layout-page-header-main">
            <div onClick={toggle}>
               {/* <span id="sidebar-trigger">{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</span> */}
            </div>
            <div className="actions">
               <Dropdown
                  overlay={
                     <Menu onClick={selectLocale}>
                        <Menu.Item style={{ textAlign: 'left' }} disabled={locale === 'ko_KR'} key="ko_KR">
                           <KoKrSvg /> 한국어
                        </Menu.Item>
                        <Menu.Item style={{ textAlign: 'left' }} disabled={locale === 'en_US'} key="en_US">
                           <EnUsSvg /> English
                        </Menu.Item>
                     </Menu>
                  }
               >
                  <span>
                     <LanguageSvg id="language-change" />
                  </span>
               </Dropdown>
               {logged ? (
                  <Dropdown overlay={menu}>
                     <span className="user-action">
                        {nickname}님
                     </span>
                  </Dropdown>
               ) : (
                  <span className='textlink' onClick={toLogin}>
                     {formatMessage({ id: 'gloabal.tips.login' })}
                  </span>
               )}
            </div>
         </div>
      </Header>
   );
};

export default HeaderComponent;
