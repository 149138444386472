export const enUS_userManagement = {
   'menu.userManagement.email': 'Email',
   'menu.userManagement.role': 'Role',
   'menu.userManagement.name': 'Name',
   'menu.userManagement.phone': 'Phone',
   'menu.userManagement.approval': 'Approval',
   'menu.userManagement.description': 'Description',
   'menu.userManagement.search.email': 'Email',
   'menu.userManagement.search.name': 'Name',
   'menu.userManagement.manager.super': 'Super Manager',
   'menu.userManagement.manager.company': 'Advertise Company Manager',
   'menu.userManagement.manager.brand': 'Brand Manager',
   'menu.userManagement.manager.device': 'Device Company Manager',
   'menu.userManagement.manager.location': 'Location Manager',
   'menu.userManagement.menu.approval': 'approved',
   'menu.userManagement.menu.approval.open': 'Do you want approve?',
   'menu.userManagement.modal.error.email': 'Please Input Email',
   'menu.userManagement.modal.error.validate': 'Not Validate Email',
   'menu.userManagement.modal.error.password': 'Please Input Password',
   'menu.userManagement.modal.error.authority': 'Please Input Authority',
   'menu.userManagement.modal.registration.title': 'Manager Registration',
   'menu.userManagement.modal.update.title': 'Manager Info Update',
   'menu.userManagement.modal.delete.title': 'Manager Delete',
   'menu.userManagement.modal.approval.title': 'Manager Approval',
   'menu.userManagement.modal.delete.description': 'Do you want delete?',
   'menu.userManagement.modal.approval.description': 'If there is an existing administrator, it will be deleted and newly registered.',
   'menu.userManagement.modal.registration.ok': 'Registration',
   'menu.userManagement.modal.update.ok': 'Update',
   'menu.userManagement.modal.approval.ok': 'Approval',
   'menu.userManagement.modal.registration.email': 'Email',
   'menu.userManagement.modal.registration.password': 'Password',
   'menu.userManagement.modal.registration.name': 'Name',
   'menu.userManagement.modal.registration.phone': 'Phone',
   'menu.userManagement.modal.registration.description': 'Description',
   'menu.userManagement.modal.registration.authority': 'Authority',
   'menu.userManagement.modal.registration.permission': 'Menu Permission',
   'menu.userManagement.modal.approval.role': 'Role',
};
