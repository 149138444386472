const devUrl = 'http://localhost';
const testUrl = 'http://3.37.52.5';
const liveUrl = 'http://uklab.org';
export const apiUrl = `${liveUrl}:6006/`;

export const apiPath = {
   'reqSearchManager': apiUrl + 'admin/reqSearchManager',
   'reqSearchBrand': apiUrl + 'admin/reqSearchBrand',
   'reqSearchCompany': apiUrl + 'admin/reqSearchCompany',
   'reqSearchDevice': apiUrl + 'admin/reqSearchDevice',
   'reqSearchContract': apiUrl + 'admin/reqSearchContract',
   'reqSearchGroup': apiUrl + 'admin/reqSearchDvGroup',
   'reqSearchLocation': apiUrl + 'admin/reqSearchLocation',
   'reqSearchAdvertisement': apiUrl + 'admin/reqSearchAd',
   'reqAdStatisticDashboard': apiUrl + 'admin/reqAdStatisticDashboard',
   'reqDeviceStatisticDashboard': apiUrl + 'admin/reqDeviceStatisticDashboard',
   'reqAdDailyAdList': apiUrl + 'admin/reqAdListStatisticDaily',
   'reqAdDailyStatistic': apiUrl + 'admin/reqAdStatisticDaily',
   'reqAdStatisticByTime': apiUrl + 'admin/reqAdStatisticByTime',
   'reqStatisticDeviceList': apiUrl + 'admin/reqStatisticDeviceList',
   'reqDeviceStatisticDaily': apiUrl + 'admin/reqDeviceStatisticDaily',
   'reqS3Config': apiUrl + 'admin/reqS3ConfigInformation',
   'reqSearchBrandListForReg': apiUrl + 'admin/reqBrandListForRegistration',
   'reqSearchAdInDevSetting': apiUrl + 'admin/reqSearchAdDevSetting',
   'reqSearchDvGroupInDevSetting': apiUrl + 'admin/reqSearchDvGroupDevSetting',
   'reqSearchDeviceInDevSetting': apiUrl + 'admin/reqSearchDeviceDevSetting',
   'reqSearchDeviceExpunge': apiUrl + 'admin/reqSearchDeviceExpunge',
   'reqManagerListForReg': apiUrl + 'admin/reqManagerListForRegistration',
   'reqRegistrationCompany': apiUrl + 'admin/reqAddCompany',
   'reqUpdateCompany': apiUrl + 'admin/reqUpdateCompany',
   'reqDeleteCompany': apiUrl + 'admin/reqDelCompany',
   'reqSearchNotice': apiUrl + 'admin/reqSearchNotice',
   'reqCompanyListForReg': apiUrl + 'admin/reqCompanyListForRegistration',
   'reqRegistrationBrand': apiUrl + 'admin/reqAddBrand',
   'reqUpdateBrand': apiUrl + 'admin/reqUpdateBrand',
   'reqDeleteBrand': apiUrl + 'admin/reqDelBrand',
   'reqRegistrationManager': apiUrl + 'admin/reqAddManager',
   'reqUpdateManager': apiUrl + 'admin/reqUpdateManager',
   'reqApproveManager': apiUrl + 'admin/reqApproveRequest',
   'reqDeleteManager': apiUrl + 'admin/reqDelManager',
   'reqRegistrationGroup': apiUrl + 'admin/reqAddDvGroup',
   'reqUpdateGroup': apiUrl + 'admin/reqUpdateDvGroup',
   'reqDeleteGroup': apiUrl + 'admin/reqDelDvGroup',
   'reqRegistrationLocation': apiUrl + 'admin/reqAddLocation',
   'reqUpdateLocation': apiUrl + 'admin/reqUpdateLocation',
   'reqDeleteLocation': apiUrl + 'admin/reqDelLocation',
   'reqDeleteNotice': apiUrl + 'admin/reqDelNotice',
   'reqAddNotice': apiUrl + 'admin/reqAddNotice',
   'reqUpdateNotice': apiUrl + 'admin/reqUpdateNotice',
   'reqRegistrationDevice': apiUrl + 'admin/reqAddDevice',
   'reqUpdateDevice': apiUrl + 'admin/reqUpdateDevice',
   'reqDeleteDevice': apiUrl + 'admin/reqDelDevice',
   'reqLocationListForReg': apiUrl + 'admin/reqLocationListForRegistration',
   'reqGroupListForReg': apiUrl + 'admin/reqGroupListForRegistration',
   'reqOrganizedAdList': apiUrl + 'admin/reqOrganizedAdHistory',
   'reqOrganizeAdToDevice': apiUrl + 'admin/reqOrganizeAdToDevice',
   'reqRemoveAdFromDevice': apiUrl + 'admin/reqRemoveAdFromDevice',
   'reqCancelRegistration': apiUrl + 'admin/reqCancelRegistration',
   'reqDeviceListForReg': apiUrl + 'admin/reqDeviceListForRegistration',
   'reqRegistrationAdvertisement': apiUrl + 'admin/reqAddAd',
   'reqUpdateAdvertisement': apiUrl + 'admin/reqUpdateAd',
   'reqDeleteAdvertisement': apiUrl + 'admin/reqDelAd',
   'reqApproveAdvertisement': apiUrl + 'admin/reqApproveAd',
   'reqOrganizeAdList': apiUrl + 'admin/reqOrganizeAdList',
   'reqSerialNumber': apiUrl + 'etc/reqActivationCode',
   'reqCodePagePassword': apiUrl + 'etc/reqCodePagePassword',
   'reqRegistrationContract': apiUrl + 'admin/reqAddContract',
   'reqUpdateContract': apiUrl + 'admin/reqUpdateContract',
   'reqDeleteContract': apiUrl + 'admin/reqDelContract',
   'reqDeviceHistoryList': apiUrl + 'admin/reqSearchDeviceHistory',
   'reqAdInfo': apiUrl + 'admin/reqAdInfo',
   'reqAdjustOrganizedAdList': apiUrl + 'admin/reqAdjustOrganizedAdList',
   'reqLogin': apiUrl + 'admin/reqLogin',
   'reqFindId': apiUrl + 'admin/reqFindId',
   'reqInitPassword': apiUrl + 'admin/reqInitPassword',
   'reqCheckCode': apiUrl + 'admin/reqCheckCode',
   'reqCheckEmail': apiUrl + 'admin/reqCheckEmail',
   'reqJoinRequest': apiUrl + 'admin/reqJoinRequest',
   'reqUserInfo': apiUrl + 'admin/reqUserInfo',
   'reqUserInfoUpdate': apiUrl + 'admin/reqUserInfoUpdate',
   'reqSearchDeviceLog': apiUrl + 'admin/reqSearchDvLog',
   'reqSearchCallLog': apiUrl + 'inner/reqCallLog',
   'reqSearchTableLog': apiUrl + 'admin/reqSearchTableLog',
};