import { enUS_avatorDropMenu } from './user/avatorDropMenu';
import { enUS_tagsViewDropMenu } from './user/tagsViewDropMenu';
import { enUS_title } from './user/title';
import { enUS_login } from './user/login';
import { enUS_publicMenu } from './public/menu';
import { enUS_publicTips } from './public/tips';
import { enUS_commonMenu } from './menu/commonMenu';
import { enUS_userManagement } from './menu/userManagement';
import { enUS_brandManagement } from './menu/brandManagement';
import { enUS_companyManagement } from './menu/companyManagement';
import { enUS_contractRegistration } from './menu/contractRegistration';
import { enUS_deviceRegistration } from './menu/deviceRegistration';
import { enUS_groupRegistration } from './menu/groupRegistration';
import { enUS_locationRegistration } from './menu/locationRegistration';
import { enUS_adRegistration } from './menu/adRegistration';
import { enUS_dashboard } from './menu/dashboard';
import { enUS_etcMenu } from './menu/etc';
import { enUS_etc } from './public/etc';

const en_US = {
   ...enUS_avatorDropMenu,
   ...enUS_tagsViewDropMenu,
   ...enUS_title,
   ...enUS_login,
   ...enUS_publicMenu,
   ...enUS_publicTips,
   ...enUS_commonMenu,
   ...enUS_userManagement,
   ...enUS_brandManagement,
   ...enUS_companyManagement,
   ...enUS_contractRegistration,
   ...enUS_deviceRegistration,
   ...enUS_groupRegistration,
   ...enUS_locationRegistration,
   ...enUS_adRegistration,
   ...enUS_dashboard,
   ...enUS_etcMenu,
   ...enUS_etc,
};

export default en_US;
