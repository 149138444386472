export const koKR_etcMenu = {
   'menu.deviceLog.id': 'ID',
   'menu.deviceLog.app': '타입',
   'menu.deviceLog.deviceId': '기기ID',
   'menu.deviceLog.serialNumber': '시리얼넘버',
   'menu.deviceLog.address': '장소명',
   'menu.deviceLog.eventDate': '발생시각',
   'menu.deviceLog.eventType': '타입',
   'menu.deviceLog.eventContent': '내용',
   'menu.deviceLog.description': '상세설명',
   'menu.deviceLog.eventType.all': '전체',
   'menu.deviceLog.eventType.error': '오류발생',
   'menu.deviceLog.eventType.appStart': 'App실행',
   'menu.deviceLog.eventType.appFinish': 'App종료',
   'menu.deviceLog.eventType.systemFinish': '시스템종료',
   'menu.deviceLog.eventType.activate': '개통',
   'menu.deviceLog.eventType.survivalReport': '생존 신고',
   'menu.deviceLog.eventType.deviceEvent': '기기 이벤트',
   'menu.deviceLog.eventType.deviceReboot': '시스템 리부팅',
   'menu.deviceLog.eventType.deviceRegistration': '기기 신규등록',
   'menu.deviceLog.eventType.deviceUpdate': '기기 정보 업데이트',
   'menu.deviceLog.eventType.deviceDelete': '기기 삭제',
   'menu.deviceLog.eventType.deviceRegistrationFromContract': '기기 신규등록(계약 자동등록)',
   'menu.deviceLog.eventType.unknown': 'Unknown',
   'menu.deviceLog.search.deviceId': '기기ID',
   'menu.deviceLog.search.eventType': '타입',
   'menu.callLog.id': 'ID',
   'menu.callLog.api': 'API',
   'menu.callLog.processTime': '처리시간',
   'menu.callLog.request.from': '요청지',
   'menu.callLog.request.time': '요청시간',
   'menu.callLog.request.param': '요청파라미터',
   'menu.callLog.response.from': '수신지',
   'menu.callLog.response.code': '응답코드',
   'menu.callLog.response.resData': '응답데이터',
   'menu.tableLog.id': 'ID',
   'menu.tableLog.name': '이벤트',
   'menu.tableLog.tableName': '테이블',
   'menu.tableLog.maker': '작성자',
   'menu.tableLog.description': '상세설명',
   'menu.tableLog.date': '작성시각',
   'menu.tableLog.type': '타입',
};
