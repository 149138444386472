import { uriEncodeObjectsValue } from 'src/utils/StringUtil';

/**
 * API 통신을 위한 파라미터를 만들어 준다.
 * @param dataParams API 통신에 필요한 파라미터가 있을 경우 Object형태로 전달
 * @returns API 통신에 필요한 형태로 가공한 string 리턴
 */
export function makeParam(dataParams: Object|null = null): string {
  console.log();
  var paramObj = Object();
  if(dataParams != null) {
    paramObj = {...dataParams}
  }
   console.log("dataParams : ", paramObj);
   paramObj = uriEncodeObjectsValue(paramObj);
   var param = `data=${JSON.stringify(paramObj)}`;
   console.log("request Param :", param);
   return param;
}


/**
 * 서버에서 사용하는 시간 형식과 같은 형식으로 가공
 * 서울 시간에 맞추기 위해 3240 * 10000(9시간) 더해줌 (추후 서울 시간이 아닌 시간이 필요할 경우 API 수정 필요)
 * TODO 파라미터로 시간 정보 입력 받아 입력 받은 시간에 맞는 string 리턴 하도록 수정 필요
 * @returns 가공한 string 리턴
 */
export function formatDate(): string {
  return new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '');
}