import { Button, Input, Select, Table, TablePaginationConfig, TableProps } from 'antd';
import { useState, useEffect, ChangeEvent, useImperativeHandle, forwardRef, ForwardedRef } from 'react';
import { Response } from 'src/api/request';
import { getRandomInt } from 'src/utils/mathUtil';
import { current } from '@reduxjs/toolkit';
import { PageParams } from 'src/interface/management';

export interface ApiTableRef {
   callApi: () => void
   setApiParam: (param: Object) => void
   searchValue: () => Array<SearchCallbackParams>
   currentPage: () => number
}

export interface ASearchProps {
   id: string;
   text: string | undefined;
}

export interface SearchBoxState {
   selected?: ASearchProps;
   searchText: string | undefined;
}

export interface SearchCallbackParams {
   data: SearchBoxState
   searchBox: ASearchBox // 어떤 SearchBox에 대한 값인지 판단할 때 사용
}

interface AKeyData {
   key: string;
}

export type SearchAreaLocation = "top" | "top-left" | "top-right" | "bottom-left" | "bottom-right" | "bottom";

export interface ASearchBox {
   id: string; // SearchBox의 Id. 고유값이어야 하며 반드시 입력해야 함
   searchTitle?: string; // SearchBox에 표시될 Title. 미입력 시 표기되지 않는다.
   showSelect: boolean; // Select Component 사용 여부
   selectItemsProp?: ASearchProps[]; // Select Component 사용시, Select 아이템 하나에 대한 Props
   selectProps?: {}; // Select Component 사용시, Select Component에 적용될 Props
   inputProps?: {}; // input Component 에 적용될 Props
   callBackFunc?: (param: SearchCallbackParams) => void; // 검색버튼 눌렀을 때 호출될 콜백(각개검색 Only)
   forcedInputValue?: string; // input value를 programmatically하게 변경할 때 사용
}

interface ApiPageFunc extends PageParams {
   (params?: any): Response<any>;
}

interface Pagination {
   total: number;
   pageSize: number;
   showSizeChanger: boolean;
}

export interface ApiTableProps {
   pageApi: ApiPageFunc;
   tableProps?: TableProps<any>;
   columns?: {}[];
   pageItemCnt?: number;
   searchBox?: ASearchBox[];
   initSearchCallback?: () => void;
   infoMessage?: string;
   setInfoMessageFunc?: (msg:string|undefined) => void
   apiCallWhenUndefinedParam?: boolean;
   searchAreaTailElements?: JSX.Element[];
   searchAreaLocation?: SearchAreaLocation;

   // 검색버튼 단일화 시에만 사용되는 Props
   combineSearchButton?: boolean;
   combineSearchButtonsCallback?: (keywords: SearchCallbackParams[]) => void;
}

function initSearchCallbackValues(props: ApiTableProps): SearchCallbackParams[] {
   const combineSearchValues = Array<SearchCallbackParams>();
   props.searchBox?.map((s) => {
      combineSearchValues.push({
         data: { selected: (s.selectItemsProp && s.selectItemsProp.length > 0) ? s.selectItemsProp[0] : undefined } as SearchBoxState,
         searchBox: s
      } as SearchCallbackParams)
   })

   return combineSearchValues;
}

const ApiTable = forwardRef(<T extends PageParams>(props: ApiTableProps, ref: ForwardedRef<unknown>) => {
   const [totalItemCnt, setTotalItemCnt] = useState({} as Pagination);
   const [dataSource, setData] = useState<Array<AKeyData>>([])
   const [reRender, setReRender] = useState(0)
   const [reCallApi, setReCallApi] = useState(0)
   const [apiParam, setApiParam] = useState<PageParams | undefined>(undefined)
   // 공통버튼 사용 시 검색어 반환을 위한 State. 각개 검색 옵션에선 사용되지 않음
   // const [searchInputList, setSearchInputList] = useState<Array<ASearchProps>>()
   const [searchValueArr, setSearchValueArr] = useState<Array<SearchCallbackParams>>(initSearchCallbackValues(props))

   async function callApiImpl() {
      if (!apiParam && !props.apiCallWhenUndefinedParam) return;

      console.log("callApi : ", props.pageApi, "\npageItemCnt: ", props.pageItemCnt, "\nparams: ", apiParam);
      const result = await props.pageApi(props.pageItemCnt? {...apiParam, pageItemCnt:props.pageItemCnt} : apiParam);
      console.log("callApi Result : ", result);
      if (result) {
         const resultList: Array<AKeyData> = result.data.list as Array<AKeyData>;
         resultList.map((item) => item.key = getRandomInt().toString());
         totalItemCnt.total = result.data.total;
         props.pageItemCnt && (totalItemCnt.pageSize = props.pageItemCnt);
         totalItemCnt.showSizeChanger = false;
         setTotalItemCnt(totalItemCnt);
         setData(resultList);
         return result.data;
      }
      return;
   }

   useEffect(() => {
      callApiImpl();
   }, [apiParam, reCallApi]);

   useImperativeHandle(ref, () => ({
      callApi, setApiParam, searchValue, currentPage
   }))

   function callApi() {
      setReCallApi(Date.now);
   }
   
   function searchValue(): Array<SearchCallbackParams> {
      return searchValueArr;
   }
   
   function currentPage(): number | undefined {
      console.log('currentPage', apiParam?.pageIdx);
      return apiParam!!.pageIdx;
   }

   function onSearchBtnClick() {
      if (props.combineSearchButtonsCallback)
         props.combineSearchButtonsCallback(searchValueArr)
   }

   function onInitBtnClick() {
      searchValueArr.map((i) => i.data.searchText = undefined)
      props.searchBox?.map((i) => {
         i.forcedInputValue = undefined
      })
      if (props.initSearchCallback)
         props.initSearchCallback()
      else {
         setApiParam({} as T)
      }
      if (props.setInfoMessageFunc)
         props.setInfoMessageFunc(undefined)
   }

   return (
      <>
         {renderSearchArea(true)}
         {props.infoMessage ? <div className='searchAreaInfoMessage'><i>{props.infoMessage}</i></div> : undefined}
         <Table dataSource={dataSource} columns={props.columns} {...props.tableProps} pagination={totalItemCnt} onChange={onChangeTablePage} ></Table>
         {renderSearchArea(false)}
         <br />
      </>
   )

   function updatePageInfo(current: number = -1, total: number = -1) {
      if (total != -1) {
         totalItemCnt.total = total;
         totalItemCnt.showSizeChanger = false;
         setTotalItemCnt(totalItemCnt);
      }

      if (current != -1) {
         if (!apiParam) setApiParam({} as PageParams);
         apiParam!!.pageIdx = current;
         setApiParam(apiParam);
      }
   }

   function onChangeTablePage(e: TablePaginationConfig) {
      console.log('onChangeTablePage', e.current);
      if (e.current)
         updatePageInfo(e.current - 1, e.total);
      callApiImpl()
   }

   function renderSearchArea(fromTop: boolean) {
      if (!props.searchBox && !props.searchAreaTailElements) return
      if (!fromTop && !props.searchAreaLocation?.includes('bottom')) return
      if (fromTop && props.searchAreaLocation && !props.searchAreaLocation?.includes('top')) return

      function getRootClassName(){
         let cls = 'searchArea'
         if(fromTop) cls += ' top'
         cls += fromTop? ' top' : ' bottom'
         if (props.tableProps && props.tableProps.pagination == false) cls += ' nonPagination'
         else if (!props.tableProps) cls += ' pagination'
         else if (props.tableProps.pagination) cls += ' pagination'
         return cls
      }

      return (
         <div className={getRootClassName()}>
            <table className='searchTable'>
               <thead>
                  <tr>
                     <th className='searchTableHead'>
                        <div className='searchBoxInTh'>
                           {props.searchBox ? props.searchBox.map((item) => {
                              return renderSearchBox(item)
                           }) : undefined}
                           <span className='searchBtnsearchBtn'>
                              {props.searchBox && props.combineSearchButton ? <Button onClick={onSearchBtnClick}>검색</Button> : undefined}
                              {props.searchBox && props.searchBox.length > 0 ? <Button onClick={onInitBtnClick}>초기화</Button> : undefined}
                           </span>
                        </div>
                     </th>
                     <th className='searchTableTail'>
                        {props.searchAreaTailElements ? props.searchAreaTailElements.map((i) => i) : undefined}
                     </th>
                  </tr>
               </thead>
            </table>
         </div>
      );
   }

   function renderSearchBox(item: ASearchBox) {
      // 각개 검색에서 사용되는 State
      // const searchValue = {
      //    selected: item.selectItemsProp && item.selectItemsProp.length > 0 ? item.selectItemsProp[0] : undefined
      // } as SearchBoxState

      //현재 ASearchBox에 해당하는 CallbackValue
      const searchBoxState = searchValueArr.find((i) => i.searchBox.id == item.id)

      // 셀렉트박스를 위한 옵션 구성
      let selectOptions = Array<{ value: string, label: string | undefined }>()
      if (item.showSelect) {
         item.selectItemsProp?.map((prop) => {
            selectOptions.push({ value: prop.id.toString(), label: prop.text })
         })
      }

      // Input에 대한 ClassName 정의. showSelect 여부 및 단일검색 버튼 사용여부에 따라 변동됨
      let inputClassName = 'selectBoxInput'
      if (!item.showSelect) inputClassName += ' notShowSelect'
      if (props.combineSearchButton) inputClassName += ' justOneBtn' 

      return (
         <div id={item.id} className='selectBoxArea' key={item.id}>
            <Input.Group compact>
               {item.searchTitle ? <span className='selectBoxTitle'>{item.searchTitle + " : "}</span> : undefined}
               {item.showSelect ?
                  // 셀렉트 컴포넌트 Render
                  <Select
                     defaultValue={selectOptions[0].label}
                     options={selectOptions}
                     {...item.selectProps}
                     onSelect={(e) => {
                        const props = item.selectItemsProp?.find((i) => i.id === e)
                        if (searchBoxState) searchBoxState.data.selected = props
                     }}
                  /> : undefined}
               {!props.combineSearchButton ? renderEachBtnInput() : renderJustOneBtnInput2()}
            </Input.Group>
         </div>
      )


      function onFocus() { item.forcedInputValue = undefined }
      function getValue() { return (item.forcedInputValue) ? item.forcedInputValue : searchValueArr.find((i) => i.searchBox.id == item.id)?.data.searchText }
      function onTextChanged(e: ChangeEvent<HTMLInputElement>) {
         item.forcedInputValue = undefined
         const curVal = searchValueArr.find((i) => i.searchBox.id == item.id)
         if (curVal) curVal.data.searchText = e.target.value
         setReRender(getRandomInt())
      }

      function renderJustOneBtnInput2() {
         // 공통 검색 버튼 구성(검색버튼 1개일때)
         return (
            <Input className={inputClassName}
               onChange={onTextChanged}
               onFocus={onFocus}
               onKeyDown={e => (e.key == 'Enter') ? onSearchBtnClick() : undefined}
               value={getValue()}
            />)
      }

      function renderEachBtnInput() {
         // 개별 검색 버튼 구성
         return (
            <Input.Search className={inputClassName}
               onChange={onTextChanged}
               onFocus={onFocus}
               value={getValue()}
               onSearch={() => {
                  const curVal = searchValueArr.find((i) => i.searchBox.id == item.id)
                  console.log("onSearch.curVal", curVal)
                  if (item.callBackFunc && curVal)
                     item.callBackFunc(curVal)
               }}
            />
         );
      }
   }
})

export default ApiTable;