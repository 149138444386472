import { FC, useState } from 'react';
import './index.less';
import logo from 'src/assets/login/ukmedia_logo.png';
import checkOn from 'src/assets/login/check_on.png';
import checkOff from 'src/assets/login/check_off.png';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsync, setLoginState, setUserItem } from "src/stores/user.store";
import { removeAllTag } from 'src/stores/tags-view.store';
import { useNavigate } from 'react-router-dom';
import { useLocale } from 'src/locales';
import { getNumber } from 'src/utils/StringUtil';

const LoginForm: FC = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { saveId, userId } = useSelector(state => state.user);
   const { formatMessage } = useLocale();
   const [id, setId] = useState<string>(saveId ? userId : '');
   const [pwd, setPwd] = useState<string>('');
   const [errorId, setErrorId] = useState<string>('');
   const [errorPwd, setErrorPwd] = useState<string>('');
   const [isCheckSaveId, setCheckSaveId] = useState(saveId);
   
   const [ment, setMent] = useState<string>('');

   const handleId = (e: any) => {
      if (ment && !errorPwd) setMent('');
      if (errorId && errorId != e.target.value) {
         setErrorId('');
         setMent('');
      }
      setId(e.target.value);
   }

   const handlePassword = (e: any) => {
      if (ment && !errorId) setMent('');
      if (errorPwd && errorPwd != e.target.value) {
         setErrorPwd('');
         setMent('');
      }
      setPwd(e.target.value);
   }

   const checkSaveId = () => {
      console.log('checkSaveId', isCheckSaveId);
      setCheckSaveId(!isCheckSaveId);
   }

   const onLogin = () => {
      if (!id || !pwd) {
         setMent(formatMessage({ id: 'login.err.input' }));
         return;
      }
      if (getNumber(sessionStorage.getItem('pwdCnt')) >= 5) {
         setMent(formatMessage({ id: 'login.err.count' }));
         return;
      }
      dispatch(loginAsync({ email: id, password: pwd, callbackFunc: (res) => {
         if (res.data.isSuccess) {
            sessionStorage.removeItem('pwdCnt');
            dispatch(setUserItem({ saveId: isCheckSaveId, userId: id, nickname: res.data.userName, logged: true, menu: res.data.menu }));
            navigate('/dashboard');
            dispatch(removeAllTag());
         } else {
            let pwdCnt;
            switch (res.data.resultCode) {
               case 10:
                  setErrorId(id);
                  pwdCnt = getNumber(sessionStorage.getItem('pwdCnt')) + 1;
                  sessionStorage.setItem('pwdCnt', pwdCnt.toString())
                  setMent(formatMessage({ id: 'login.err.email' }) + (pwdCnt > 0 ? ` (${pwdCnt}/5)` : ''));
                  break;
               case 11:
                  setErrorPwd(pwd);
                  pwdCnt = getNumber(sessionStorage.getItem('pwdCnt')) + 1;
                  sessionStorage.setItem('pwdCnt', pwdCnt.toString())
                  setMent(formatMessage({ id: 'login.err.password' }) + (pwdCnt > 0 ? ` (${pwdCnt}/5)` : ''));
                  break;
               case 12:
                  setMent(formatMessage({ id: 'login.err.notApprove' }));
                  break;
            }
         }
      }}));
   }

   const onFind = () => {
      dispatch(setLoginState({ findIdTab: '1' }))
      navigate('/findId');
   }

   const onInit = () => {
      dispatch(setLoginState({ findIdTab: '2' }))
      navigate('/findId');
   }

   const onJoin = () => {
      navigate('/register');
   }
   
   return (
      <div>
         <div className="background">
            <div className="login-form">
               <div className='login-logo'><img src={logo}/></div>
               <div className='input-area'>
                  <div className='input-box'>
                     <input className={errorId && id == errorId && ment == formatMessage({ id: 'login.err.email' }) ? 'input input-error' : 'input'}
                        type='text' required value={id} onChange={handleId} />
                     <label className='input-label'>{formatMessage({ id: 'login.id' })}</label>
                     <span className={errorId && id == errorId && ment == formatMessage({ id: 'login.err.email' }) ? 'input-span input-span-error' : 'input-span'}></span>
                  </div>
                  <div className='input-box'>
                     <input className={errorPwd && pwd == errorPwd && ment == formatMessage({ id: 'login.err.password' }) ? 'input input-error' : 'input'}
                        type='password' required value={pwd} onChange={handlePassword} />
                     <label className='input-label'>{formatMessage({ id: 'login.password' })}</label>
                     <span className={errorPwd && pwd == errorPwd && ment == formatMessage({ id: 'login.err.password' }) ? 'input-span input-span-error' : 'input-span'}></span>
                  </div>
               </div>
               <div className='additional-area'>
                  {ment ? <div className='error-ment'>{ment}</div> : <></>}
                  <div className='check-box' onClick={checkSaveId}>
                     <img width={'22px'} height={'22px'} src={isCheckSaveId ? checkOn : checkOff}/>&nbsp;&nbsp;{formatMessage({ id: 'login.saveId' })}
                  </div>
               </div>
               <button className='login-button' onClick={onLogin}>{formatMessage({ id: 'login.button.login' })}</button>
               <div className='find-area'>
                  <div className='find-button' onClick={onFind}>{formatMessage({ id: 'login.button.findId' })}</div>
                  <div className='seperator'/>
                  <div className='find-button' onClick={onInit}>{formatMessage({ id: 'login.button.initPassword' })}</div>
               </div>
               <div className='ment-account'>{formatMessage({ id: 'login.ment.register' })}</div>
               <button className='join-button' onClick={onJoin}>{formatMessage({ id: 'login.button.register' })}</button>
            </div>
         </div>
      </div>
   );
};

export default LoginForm;
