export const enUS_adRegistration = {
   'menu.adRegistration.name': 'Name',
   'menu.adRegistration.description': 'Description',
   'menu.adRegistration.thumbnail': 'Thumbnail',
   'menu.adRegistration.company': 'Company',
   'menu.adRegistration.brand': 'Brand',
   'menu.adRegistration.detail': 'Detail Description',
   'menu.adRegistration.approval': 'Approval',
   'menu.adRegistration.startDate': 'StartDate',
   'menu.adRegistration.endDate': 'EndDate',
   'menu.adRegistration.duration': 'PostedDays',
   'menu.adRegistration.remain': 'RemainingDays',
   'menu.adRegistration.accounts': 'Accounts',
   'menu.adRegistration.cumulativeAccounts': 'CumulativeAccounts',
   'menu.adRegistration.dailyAccounts': 'DailyAccounts',
   'menu.adRegistration.totalPlayCount': 'TotalPlayCount',
   'menu.adRegistration.totalViewCount': 'TotalViewCount',
   'menu.adRegistration.totalURLCount': 'TotalURLCount',
   'menu.adRegistration.totalQRCount': 'TotalQRCount',
   'menu.adRegistration.organize.step.ad': '광고 선택',
   'menu.adRegistration.organize.step.device': '편성 기기 선택',
   'menu.adRegistration.organize.step.date': '편성일 선택',
   'menu.adRegistration.organize.step.next': '다음',
   'menu.adRegistration.organize.step.prev': '이전',
   'menu.adRegistration.organize.step.organize': '편성',
   'menu.adRegistration.organize.ad.selectAd': '편성할 광고를 선택해 주세요',
   'menu.adRegistration.organize.ad.selectedAd': '선택한 광고',
   'menu.adRegistration.organize.ad.notSigned': '미승인',
   'menu.adRegistration.organize.ad.selectGroup': '그룹 선택',
   'menu.adRegistration.organize.ad.selectDevice': '기기 선택',
   'menu.adRegistration.organize.ad.selectedDevice': '선택한 그룹이나 기기',
   'menu.adRegistration.organize.ad.selectDate': '편성일 선택',
   'menu.adRegistration.organize.ad.selectTime': '편성시간 선택',
   'menu.adRegistration.expunge.selectAd': '편성에서 제외할 광고를 선택해 주세요',
   'menu.adRegistration.expunge.selectDevice': '선택한 광고를 제외할 기기를 선택해 주세요',
   'menu.adRegistration.expunge.delete': '삭제',
   'menu.adRegistration.search.name': 'Name',
   'menu.adRegistration.search.company': 'Company',
   'menu.adRegistration.search.brand': 'Brand',
   'menu.adRegistration.organizeDevice': '편성정보',
   'menu.adRegistration.preview': 'Preview',
   'menu.adRegistration.organize.complete': 'Organize Advertisement Complete',
   'menu.adRegistration.modal.error.name': 'Please Input Advertisement Name',
   'menu.adRegistration.modal.error.brand': 'Please Input Brand',
   'menu.adRegistration.modal.error.type': 'Please Input Type',
   'menu.adRegistration.modal.error.file': 'Please Select Video or Image File',
   'menu.adRegistration.modal.registration.title': 'Advertisement Registration',
   'menu.adRegistration.modal.update.title': 'Advertisement Information Update',
   'menu.adRegistration.modal.delete.title': 'Advertisement Delete',
   'menu.adRegistration.modal.organize.title': 'Organized Device List',
   'menu.adRegistration.modal.preview.title': 'Advertisement Preview',
   'menu.adRegistration.modal.delete.description': 'Do you want delete?',
   'menu.adRegistration.modal.registration.ok': 'Registration',
   'menu.adRegistration.modal.update.ok': 'Update',
   'menu.adRegistration.modal.preview.info': 'Info',
   'menu.adRegistration.modal.preview.approve': 'Approve',
   'menu.adRegistration.modal.preview.approve.cancel': 'Cancel Approve',
   'menu.adRegistration.modal.preview.close': 'Close',
   'menu.adRegistration.modal.registration.name': 'Name',
   'menu.adRegistration.modal.registration.description': 'Description',
   'menu.adRegistration.modal.registration.detail': 'Detail',
   'menu.adRegistration.modal.registration.brand': 'Brand',
   'menu.adRegistration.modal.registration.type': 'Type',
   'menu.adRegistration.modal.registration.detailUrl': 'Link URL',
   'menu.adRegistration.modal.registration.qrUrl': 'QRCode URL',
   'menu.adRegistration.modal.registration.file': 'Advertisement File',
   'menu.adRegistration.modal.registration.file.button': 'Find File',
   'menu.adRegistration.modal.registration.file.none': 'No file selected',
   'menu.adRegistration.modal.registration.video': 'Video',
   'menu.adRegistration.modal.registration.thumbnail': 'Thumbnail',
   'menu.adRegistration.modal.registration.image.top': 'Top Image',
   'menu.adRegistration.modal.registration.image.bottom': 'Bottom Image',
   'menu.adRegistration.modal.registration.image': 'Image',
   'menu.adRegistration.modal.registration.type.vertical': 'Video Only(Vertical or Etc)',
   'menu.adRegistration.modal.registration.type.horizontal': 'Horizontal',
   'menu.adRegistration.modal.registration.type.image': 'Image',
};