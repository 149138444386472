import React, { FC, useState } from 'react';
import PasswordModal from './modal/PasswordModal';
import { CameraFilled, WarningOutlined } from '@ant-design/icons';
import scanImg from './../../assets/lottie/83994-search-file.json'
import Lottie from 'lottie-react';
import { Button, Input, notification, Select } from 'antd';
import './index.css';
import { apiReqActivateCode } from '../../api/management.api' //'./api/management.api';
import { ReqCodeCheckParam, ResActiovateCode } from '../../interface/etc/codeChecker';
import { ResError } from '../../interface/etc/common';

const CodeCheckerPage: FC = () => {
   const [deviceType, setDeviceType] = useState(0);
   const [isUnlock, setIsUnlock] = useState(false);
   const [inputSerial, setInputSerial] = useState("");
   const [showResult, setShowResult] = useState(false);
   const [recvSerialNumber, setRecvSerialNumber] = useState('');
   const [recvActivateCode, setRecvActiovateCode] = useState('');

   async function onActionCodeBtnClicked() {
      if (isUnlock) {
         if (inputSerial.length == 0) {
            notification.open({
               message: <div> 시리얼번호를 입력해 주세요</div>,
               icon: <WarningOutlined style={{ color: '#ff5555' }} />,
            });
            return;
         }
         console.log(`deviceType:${deviceType}, inputSerial:${inputSerial}`);

         const result = await apiReqActivateCode({ serialNumber: inputSerial, deviceType: deviceType } as ReqCodeCheckParam);
         // var result = {isSuccess : false, data:{}}
         console.log("########", result)
         if (result.isSuccess) {
            setShowResult(true);
            var res = result.data as ResActiovateCode
            setRecvActiovateCode(res.activationCode);
            setRecvSerialNumber(res.serialNumber);
         } else {
            if (result.data) {
               const errorData = result.data as ResError
               const description = errorData.errorMessage ? errorData.errorMessage :
                  errorData.logMessage ? errorData.logMessage :
                     errorData.resCode && errorData.resCodeName && errorData.resCodeDescription ? errorData.resCodeName + `(${errorData.resCode}): ` + errorData.resCodeDescription :
                        "errorData 알 수 없음";
               const title = errorData.resCode && errorData.resCodeName && errorData.resCodeDescription ? errorData.resCodeName + `(${errorData.resCode}): ` + errorData.resCodeDescription : "실패"

               notification.error({
                  message: title,
                  description: description
               });
               return;
            } else {
               notification.error({
                  message: <div>통신 오류</div>,
                  description: "서버에 접속할 수 없습니다. 네트워크 및 서버 상태를 확인해 주세요."
               });
               return;
            }
         }
      } else {
         alert("통신 실패");
         console.log("페이지가 정상 작동하지 않습니다.");
      }
   }

   const onNewScanResult = (decodedText:any, decodedResult:any) => {
      console.log("decodedText : ", decodedText)
      console.log("decodedResult : ", decodedResult)
      alert("qr : " + decodedText)
      // handle decoded results here
   };

   function renderChecker() {
      return (
         <div className='checker'>
            <table style={{ textAlign: 'center', width: '100%' }}>
               <tr>
                  <td align='center'>
                     <Lottie animationData={scanImg} style={{ width: '50%' }} />
                  </td>
               </tr>
               <tr>
                  <td style={{ width: '75%' }}>
                     <div style={{ display: 'inline-block', width: '100%' }}>
                        <span style={{ fontSize: '0.8em', marginRight: '5px', width: '30%' }}>디바이스 타입</span>
                        <Select
                           defaultValue="0"
                           style={{ width: '90%', marginBottom: '10px', marginTop: '6px' }}
                           onChange={(value) => setDeviceType(+value)}
                           options={[
                              { value: '0', label: 'Palo' },
                              { value: '1', label: 'Lucy', disabled: true },
                              { value: '2', label: 'SellingBoard' },
                           ]}
                        />
                        <div style={{ marginBottom: '30px', display: 'block' }}>
                           <Input style={{ width: '90%', textAlign: 'center'}} maxLength={15} placeholder='시리얼 번호 입력' value={inputSerial} onChange={(e) => {
                              setInputSerial(e.target.value.toUpperCase())
                           }} onKeyDown={(e) => (e.key == 'Enter') ? onActionCodeBtnClicked() : undefined }></Input>
                        </div>

                        <Button style={{ width: '90%', marginBottom: '30px' }} type='primary' onClick={onActionCodeBtnClicked}>활성화 코드 조회</Button>
                     </div>
                  </td>
               </tr>
            </table>
         </div>
      );
   }

   function renderResult() {
      return (
         <>
            <div className='checker' style={{ marginTop: '25px', marginBottom: '25px' }}>
               <div style={{ margin: '10px', marginTop: '25px', marginBottom: '25px', textAlign: 'left' }}>
                  <span style={{ color: 'gray', fontSize: '0.9em', marginRight: '10px' }}>시리얼 넘버 :</span>
                  <span style={{ color: 'black', fontSize: '1em' }}>{recvSerialNumber}</span>
                  <br />
                  <span style={{ color: 'gray', fontSize: '0.9em', marginRight: '10px' }}>활성화 코드 :</span>
                  <span style={{ color: 'black', fontSize: '1em' }}>{recvActivateCode}</span>
               </div>
            </div>
            <Button type='primary' style={{ marginTop: '10px' }} onClick={(e) => {
               setInputSerial('');
               setShowResult(false);
               setDeviceType(0);
            }}>조회화면 돌아가기</Button>
         </>
      )
   }

   return (
      <div className='codeCheckRoot'>
         <div className='codeCheckTitle'>
            <span style={{ fontSize: '1.5em' }}>UKW Application</span><br />
            <span style={{ fontSize: '1.0em' }}>Activation code checker</span>
         </div>

         {isUnlock ? (!showResult ? renderChecker() : renderResult()) : undefined}
         <PasswordModal setIsUnlock={setIsUnlock} />

         <span style={{ fontSize: '0.5em', marginTop: '10px', color: 'silver'}}>Copyright 2023. UK-Lab All rights reserved.</span>
      </div>
   );
}

export default CodeCheckerPage;
