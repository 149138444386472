import { apiReqSearchAdvertisementForMain } from 'src/api/management.api';
import ApiTable, { ApiTableRef } from 'src/components/core/apiTable/ApiTable';
import { LocaleFormatter, useLocale } from 'src/locales';
import { FC, useEffect, useRef, useState } from 'react';
import { getRandomInt } from 'src/utils/mathUtil';
import { addComma } from 'src/utils/StringUtil';
import { Button, Dropdown, MenuProps, Progress, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

const tableColums = [
   { title: <LocaleFormatter id="menu.dashboard.AdStatus.row.adName" />, width: '22%', dataIndex: 'name', key: 'name', align: 'center', render: (data: string) => <span className='ellipsesTableContents'>{data}</span> },
   // { title: "진행률", width: '15%', align: 'center', render: () => <div style={{textAlign:'center'}}><Progress percent={getRandomInt(50, 100)} strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} width={50}/></div> },
   { title: "재생", width: '12%', dataIndex: 'playCount', key: 'playCount', align: 'center' },
   { title: "시청", width: '12%', dataIndex: 'viewCount', key: 'viewCount', align: 'center' },
   { title: "URL 클릭", width: '12%', dataIndex: 'urlCount', key: 'urlCount', align: 'center' },
   { title: "QR 클릭", width: '12%', dataIndex: 'qrCount', key: 'qrCount', align: 'center' },
   // { title: "가동률", width: '8%', align: 'center', render: () => <span className='ellipsesTableContents'><Progress size="small" steps={10} percent={getRandomInt(70, 100)}  /></span> },
   // { title: <LocaleFormatter id="menu.dashboard.AdStatus.row.startDate" />, width: '13%', dataIndex: 'startDate', key: 'startDate', align: 'center', render: (data: string) => (<span className='ellipsesTableContents'>{getTimeStringByDate(new Date(data), "YYYY. MM. DD", false)}</span>) },
   // { title: <LocaleFormatter id="menu.dashboard.AdStatus.row.endDate" />, width: '13%', dataIndex: 'endDate', key: 'endDate', align: 'center', render: (data: string) => (<span className='ellipsesTableContents'>{getTimeStringByDate(new Date(data), "YYYY. MM. DD", false)}</span>) },
   // { title: <LocaleFormatter id="menu.dashboard.AdStatus.row.period" />, width: '8%', dataIndex: 'period', key: 'period', align: 'center', render: (data: string, record: Advertisement) => (record.startDate && record.endDate) ? <span className='ellipsesTableContents'>{DateTimeUtil.getPeriod(record.startDate!!, record.endDate!!, "일")}</span> : "-" },
   { title: <LocaleFormatter id="menu.dashboard.AdStatus.row.deviceCount" />, dataIndex: 'deviceCount', key: 'deviceCount', align: 'center', width: '7.5%' },
]

const MainAdList: FC = () => {
   const navigate = useNavigate();
   const { formatMessage } = useLocale();
   const apiTableRef = useRef<ApiTableRef>();
   const [currentMenu, setCurrentMenu] = useState<string>(formatMessage({ id: 'menu.dashboard.AdStatus.today' }));

   const items = [
      { label: formatMessage({ id: 'menu.dashboard.AdStatus.today' }), key: '1', period: 1 },
      { label: formatMessage({ id: 'menu.dashboard.AdStatus.week' }), key: '2', period: 7 },
      { label: formatMessage({ id: 'menu.dashboard.AdStatus.month' }), key: '3', period: 30 },
   ];

   useEffect(() => {
      console.log('useEffect!!!');
      apiTableRef.current?.setApiParam({ period: 1 });
   }, []);

   const handleMenuClick: MenuProps['onClick'] = e => {
      console.log('handleMenuClick');
      let item = items[Number(e.key) - 1];
      setCurrentMenu(item.label);
      apiTableRef.current?.setApiParam({ period: item.period });
   };

   return (
      <>
         <div className='mainListTitle' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Dropdown menu={{items, onClick: handleMenuClick}} trigger={['click']}>
               <Button><Space>{currentMenu}<DownOutlined /></Space></Button>
            </Dropdown>
            <span style={{ cursor: 'pointer', marginTop: '5px' }} onClick={() => navigate('/dataManagement/adBasedDataMng')}>더보기</span>
         </div>
         <ApiTable
            pageApi={apiReqSearchAdvertisementForMain}
            ref={apiTableRef}
            pageItemCnt={5}
            columns={tableColums}
            combineSearchButton
         />
      </>
   );
}

export default MainAdList;
