export const koKR_brandManagement = {
   'menu.brandManagement.company': '회사',
   'menu.brandManagement.name': '브랜드',
   'menu.brandManagement.code': '소속코드',
   'menu.brandManagement.adCount': '광고수',
   'menu.brandManagement.description': '상세설명',
   'menu.brandManagement.manager': '담당자',
   'menu.brandManagement.email': '메일',
   'menu.brandManagement.phone': '전화번호',
   'menu.brandManagement.search.company': '회사',
   'menu.brandManagement.search.name': '브랜드',
   'menu.brandManagement.info.message': '에 속한 브랜드 목록입니다.',
   'menu.brandManagement.modal.error': '브랜드 이름은 필수 입력 항목입니다.',
   'menu.brandManagement.modal.error.company': '회사는 필수 입력 항목입니다.',
   'menu.brandManagement.modal.registration.title': '브랜드 등록',
   'menu.brandManagement.modal.update.title': '브랜드 수정',
   'menu.brandManagement.modal.delete.title': '브랜드 삭제',
   'menu.brandManagement.modal.delete.description': '삭제하시겠습니까?',
   'menu.brandManagement.modal.registration.ok': '등록',
   'menu.brandManagement.modal.update.ok': '수정',
   'menu.brandManagement.modal.registration.name': '이름',
   'menu.brandManagement.modal.registration.description': '설명',
   'menu.brandManagement.modal.registration.companyName': '회사',
   'menu.brandManagement.modal.registration.code': '소속코드',
   'menu.brandManagement.modal.registration.manager': '담당자',
   'menu.brandManagement.modal.registration.email': '이메일',
   'menu.brandManagement.modal.registration.phone': '연락처',
};
