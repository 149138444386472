import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SearchState {
   searchNavigatePath: string;
   companyIdInBrand: number;
   companyNameInBrand: string;
   companyIdInLocation: number;
   companyNameInLocation: string;
   brandIdInAd: number;
   brandNameInAd: string;
   groupIdInDevice: number;
   groupNameInDevice: string;
   locationIdInDevice: number;
   locationNameInDevice: string;
   contractIdInDevice: number;
   contractorNameInDevice: string;
}

const initialState: SearchState = {
   searchNavigatePath: '',
   companyIdInBrand: 0,
   companyNameInBrand: '',
   companyIdInLocation: 0,
   companyNameInLocation: '',
   brandIdInAd: 0,
   brandNameInAd: '',
   groupIdInDevice: 0,
   groupNameInDevice: '',
   locationIdInDevice: 0,
   locationNameInDevice: '',
   contractIdInDevice: 0,
   contractorNameInDevice: '',
};

const searchSlice = createSlice({
   name: 'search',
   initialState,
   reducers: {
      setSearchState(state, action: PayloadAction<Partial<SearchState>>) {
         Object.assign(state, action.payload);
      },
   },
});

export const { setSearchState } = searchSlice.actions;

export default searchSlice.reducer;
