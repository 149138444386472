import { FC, useEffect, useState } from 'react';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { UserInfo, userInfoAsync } from "src/stores/user.store";
import { useNavigate } from 'react-router-dom';
import { useLocale } from 'src/locales';
import { authorityToRoleList } from '../util';
import { Tooltip } from 'antd';
import { SettingOutlined, ShopOutlined, TableOutlined, UserOutlined } from '@ant-design/icons';
import { phoneNumberFormat } from 'src/utils/StringUtil';

const UserInfoForm: FC = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { userId, userInfoList } = useSelector(state => state.user);
   const { formatMessage } = useLocale();
   const [email, setEmail] = useState<string>('');
   const [name, setName] = useState<string>('');
   const [phone, setPhone] = useState<string>('');
   const [authority, setAuthority] = useState<string>();
   const [company, setCompany] = useState<string>();
   const [brand, setBrand] = useState<string>();
   const [location, setLocation] = useState<string>();
   
   const [loading, setLoading] = useState(false);

   const loadUserInfo = async () => {
      if (loading) { return; }
      setLoading(true);
      dispatch(userInfoAsync({ email: userId }));
   };

   useEffect(() => { loadUserInfo(); }, []);

   useEffect(() => {
      console.log(userInfoList);
      if (userInfoList && userInfoList[0]) setUserInfo(userInfoList[0]);
      setLoading(false);
   }, [userInfoList]);

   const setUserInfo = (userInfo: UserInfo) => {
      setEmail(userInfo.email);
      setName(userInfo.name);
      setPhone(userInfo.phone);
      setAuthority(userInfo.authority);
      setCompany(userInfo.company?.join(", "));
      setBrand(userInfo.brand?.join(", "));
      setLocation(userInfo.location?.join(", "));
   }

   const onUpdate = () => {
      navigate('/update');
   }
   
   return (
      <div>
         <div className="background">
            <div className="userInfo-form">
               <div className='userInfo-title'>회원정보</div>
               <div className='userInfo-input'>
                  <p>{formatMessage({ id: 'userInfo.id' })}</p>
                  <i>{email}</i>
               </div>
               <div className='userInfo-input'>
                  <p>{formatMessage({ id: 'userInfo.name' })}</p>
                  <i>{name}</i>
               </div>
               <div className='userInfo-input'>
                  <p>{formatMessage({ id: 'userInfo.phone' })}</p>
                  <i>{phoneNumberFormat(phone)}</i>
               </div>
               <div className='userInfo-input'>
                  <p>{formatMessage({ id: 'userInfo.role' })}</p>
                  <i>{authorityToRoleList(authority).map(role => authorityToIcon(role))}</i>
               </div>
               <div className='userInfo-input'>
                  <p>{formatMessage({ id: 'userInfo.company' })}</p>
                  <i>{company}</i>
               </div>
               <div className='userInfo-input'>
                  <p>{formatMessage({ id: 'userInfo.brand' })}</p>
                  <i>{brand}</i>
               </div>
               <div className='userInfo-input'>
                  <p>{formatMessage({ id: 'userInfo.location' })}</p>
                  <i>{location}</i>
               </div>
               <button className='join-button' onClick={onUpdate}>정보수정</button>
            </div>
         </div>
      </div>
   );

   function authorityToIcon(type: number) {
      let roleString = "";
      let icon = null
      let toolTopString = ""
      switch (type) {
         case 0:
            icon = <UserOutlined style={{ fontSize: '20px' }} />
            roleString = "S "
            toolTopString = formatMessage({ id: 'menu.userManagement.manager.super' })
            break;
         case 1:
            icon = <TableOutlined style={{ fontSize: '20px' }} />
            roleString = "C "
            toolTopString = formatMessage({ id: 'menu.userManagement.manager.company' })
            break;
         case 2:
            icon = <ShopOutlined style={{ fontSize: '20px' }} />
            roleString = "B "
            toolTopString = formatMessage({ id: 'menu.userManagement.manager.brand' })
            break;
         case 3:
            icon = <SettingOutlined style={{ fontSize: '20px' }} />
            roleString = "L "
            toolTopString = formatMessage({ id: 'menu.userManagement.manager.location' })
            break;
      }
      return (
         <Tooltip title={toolTopString}>
            <span>
               {icon}
            </span>
         </Tooltip>
      );
   }
};

export default UserInfoForm;
