import { LocaleFormatter } from "src/locales";

export const setRequiredTxt = (txt: string) => {
   return <div style={{ display: 'flex' }}><div style={{ color: '#CC0000' }}>*&nbsp;</div>{txt}</div>
}

export const menuAddditonalItems = [
   { key: '1', label: <LocaleFormatter id="menu.common.update" /> },
   { key: '2', label: <LocaleFormatter id="menu.common.delete" /> },
];

export function authorityToRoleList(authority?: string) {
   let roleList: number[] = [];
   if (authority && authority.includes('1')) roleList.push(0);
   if (authority && authority.includes('2')) roleList.push(1);
   if (authority && authority.includes('3')) roleList.push(2);
   if (authority && authority.includes('4')) roleList.push(3);
   if (authority && authority.includes('5')) roleList.push(4);
   return roleList;
}

export const checkTextOverflow = (e: any) => {
  // text-overflow가 발생하지 않은 경우
  if (e.target.clientWidth >= e.target.scrollWidth) {
    return false;
  }

  return true;
};

