export const koKR_publicMenu = {
   "global.menu.dashboard": "대시보드",
   "global.menu.advertisement": "광고",
   "global.menu.advertisement.company": "광고 회사 관리",
   "global.menu.advertisement.brand": "브랜드 관리",
   "global.menu.advertisement.management": "광고 관리",
   "global.menu.advertisement.organize": "광고 편성",
   "global.menu.advertisement.expunge": "광고 편성 제외",
   "global.menu.statistics": "통계",
   "global.menu.statistics.advertisement": "광고기준 통계",
   "global.menu.statistics.device": "기기기준 통계",
   "global.menu.device": "기기",
   "global.menu.device.company": "매장 회사 관리",
   "global.menu.device.location": "매장 관리",
   "global.menu.device.management": "기기 관리",
   "global.menu.device.group": "그룹 관리",
   "global.menu.contract": "계약 관리",
   "global.menu.manager": "담당자 관리",
   "global.menu.log": "로그",
   "global.menu.log.device": "디바이스 로그",
   "global.menu.log.call": "API 로그",
   "global.menu.log.event": "AProm 이벤트 로그",
};
