import { makeParam } from './apiUtil';
import { apiPath } from './apiConst';
import { request } from './request';
import { PageData } from '../interface';
import { DefaultApiResult, DeleteDefaultParams, SearchDefaultParams } from '../interface/management';
import { Advertisement, DeviceSetAd, DeviceSetDvGroup, DeviceSetDevice, SearchAdvertisementParams, SearchDeviceSetDeviceParams, OrganizeAdToDeviceParams, BrandReg, RegistrationAdvertisementParams, DeleteAdvertisementParams, ApproveAdvertisementParams, ExpungeAdFromDeviceParams, DeviceExpunge, SearchDeviceSetAdParams, DashboardAdParams, DashboardAd, DashboardDeviceParams, DashboardDevice } from '../interface/management/advertisement';
import { Brand, Company, Manager, SearchManagerParams, ManagerReg, RegistrationCompanyParams, SearchBrandParams, SearchCompanyParams, CompanyReg, RegistrationBrandParams, RegistrationManagerParams, DeleteManagerParams, ApproveManagerParams, DeleteBrandParams } from '../interface/management/user';
import { Notice, RegistrationNoticeParams, SearchNoticeParams } from '../interface/management/dashboard';
import { AdInfo, CancelRegistrationParams, DeleteDeviceParams, Device, DeviceReg, DvHistory, DvLocation, DvLocationReg, Group, GroupReg, OrganizedAd, OrganizedAdParams, RegistrationDeviceParams, RegistrationGroupParams, SearchAdInfoParams, SearchDeviceHistoryParams, SearchDeviceParams, SearchGroupParams, SearchLocationParams, adjustOrganizedAdListParams } from '../interface/management/device';
import { S3Config } from '../stores/adManagement.store';
import { UserInfo } from '../stores/user.store';
import { CheckCodeParams, CheckCodeResult, FindIDParams, FindIDResult, InitPasswordParams, DefaultResult, LoginParams, LoginResult, JoinRequestParams, CheckEmailParams, UserInfoUpdateParams } from '../interface/user/login';
import { ReqCodeCheckParam } from '../interface/etc/codeChecker';
import { Contract, DeleteContractParams, RegistrationContractParams, SearchContractParams } from '../interface/management/contract';
import { AdDailyAd, AdDailyStatistic, AdStatisticParams, AdStatisticByTime, DeviceStatisticDaily, DeviceStatisticParams, StatisticDeviceGroup } from '../interface/management/data';
import { CallLog, DeviceLog, SearchDeviceLogParams, SearchTableLogParams, TableLog } from '../interface/management/etc';

export const apiLogin = (data: LoginParams) =>
   request<DefaultApiResult<LoginResult>>('post', apiPath['reqLogin'], makeParam(data));

export const apiFindID = (data: FindIDParams) =>
   request<DefaultApiResult<FindIDResult>>('post', apiPath['reqFindId'], makeParam(data));

export const apiInitPassword = (data: InitPasswordParams) =>
   request<DefaultApiResult<DefaultResult>>('post', apiPath['reqInitPassword'], makeParam(data));

export const apiCheckCode = (data: CheckCodeParams) =>
   request<DefaultApiResult<CheckCodeResult>>('post', apiPath['reqCheckCode'], makeParam(data));

export const apiCheckEmail = (data: CheckEmailParams) =>
   request<DefaultApiResult<CheckCodeResult>>('post', apiPath['reqCheckEmail'], makeParam(data));

export const apiJoinRequest = (data: JoinRequestParams) =>
   request<DefaultApiResult<DefaultResult>>('post', apiPath['reqJoinRequest'], makeParam(data));

export const apiReqUserInfo = (data: CheckEmailParams) =>
   request<PageData<UserInfo>>('post', apiPath['reqUserInfo'], makeParam(data));

export const apiReqUserInfoUpdate = (data: UserInfoUpdateParams) =>
   request<DefaultApiResult<DefaultResult>>('post', apiPath['reqUserInfoUpdate'], makeParam(data));

export const apiReqSearchManager = (data: SearchManagerParams) =>
   request<PageData<Manager>>('post', apiPath['reqSearchManager'], makeParam(data));

export const apiReqSearchBrand = (data: SearchBrandParams) =>
   request<PageData<Brand>>('post', apiPath['reqSearchBrand'], makeParam(data));

export const apiReqSearchCompany = (data: SearchCompanyParams) =>
   request<PageData<Company>>('post', apiPath['reqSearchCompany'], makeParam(data));

export const apiReqSearchDevice = (data: SearchDeviceParams) =>
   request<PageData<Device>>('post', apiPath['reqSearchDevice'], makeParam(data));

export const apiReqSearchContract = (data: SearchContractParams) =>
   request<PageData<Contract>>('post', apiPath['reqSearchContract'], makeParam(data));

export const apiReqSearchGroup = (data: SearchDefaultParams) =>
   request<PageData<Group>>('post', apiPath['reqSearchGroup'], makeParam(data));

export const apiReqSearchLocation = (data: SearchLocationParams) =>
   request<PageData<DvLocation>>('post', apiPath['reqSearchLocation'], makeParam(data));

export const apiReqSearchAdvertisement = (data: SearchAdvertisementParams) =>
   request<PageData<Advertisement>>('post', apiPath['reqSearchAdvertisement'], makeParam(data));

export const apiReqSearchAdvertisementForMain = (data: DashboardAdParams) =>
   request<PageData<DashboardAd>>('post', apiPath['reqAdStatisticDashboard'], makeParam(data));

export const apiReqSearchDeviceForMain = (data: DashboardDeviceParams) =>
   request<PageData<DashboardDevice>>('post', apiPath['reqDeviceStatisticDashboard'], makeParam(data));

export const apiReqAdDailyAdList = () => 
   request<PageData<AdDailyAd>>('post', apiPath['reqAdDailyAdList'], makeParam());

export const apiReqAdDailyStatistic = (data: AdStatisticParams) => 
   request<DefaultApiResult<AdDailyStatistic>>('post', apiPath['reqAdDailyStatistic'], makeParam(data));

export const apiReqAdStatisticByTime = (data: AdStatisticParams) => 
   request<PageData<AdStatisticByTime>>('post', apiPath['reqAdStatisticByTime'], makeParam(data));

export const apiReqStatisticDeviceList = () =>
   request<DefaultApiResult<StatisticDeviceGroup>>('post', apiPath['reqStatisticDeviceList'], makeParam());

export const apiReqDeviceStatisticDaily = (data: DeviceStatisticParams) => 
   request<PageData<DeviceStatisticDaily>>('post', apiPath['reqDeviceStatisticDaily'], makeParam(data));

export const apiReqNoticeList = (data: SearchNoticeParams) =>
   request<PageData<Notice>>('post', apiPath['reqSearchNotice'], makeParam(data));
   
export const apiReqNoticeListForMain = (data: SearchNoticeParams) =>
   request<PageData<Notice>>('post', apiPath['reqSearchNotice'], makeParam(data));

export const apiReqS3Config = (data: SearchDefaultParams) =>
   request<PageData<S3Config>>('post', apiPath['reqS3Config'], makeParam(data));

export const apiReqBrandListForReg = (data: SearchDefaultParams) =>
   request<PageData<BrandReg>>('post', apiPath['reqSearchBrandListForReg'], makeParam(data));

export const apiReqSearchAdInDevSetting = (data: SearchDeviceSetAdParams) =>
   request<PageData<DeviceSetAd>>('post', apiPath['reqSearchAdInDevSetting'], makeParam(data));

export const apiReqSearchBrandInDevSetting = (data: SearchDefaultParams) =>
   request<PageData<DeviceSetDvGroup>>('post', apiPath['reqSearchDvGroupInDevSetting'], makeParam(data));

export const apiReqSearchDeviceInDevSetting = (data: SearchDeviceSetDeviceParams) =>
   request<PageData<DeviceSetDevice>>('post', apiPath['reqSearchDeviceInDevSetting'], makeParam(data));

export const apiReqSearchDeviceExpunge = (data: SearchDeviceSetDeviceParams) =>
   request<PageData<DeviceExpunge>>('post', apiPath['reqSearchDeviceExpunge'], makeParam(data));

export const apiReqManagerListForReg = (data: SearchManagerParams) =>
   request<PageData<ManagerReg>>('post', apiPath['reqManagerListForReg'], makeParam(data));

export const apiReqRegistrationCompany = (data: RegistrationCompanyParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRegistrationCompany'], makeParam(data));

export const apiReqUpdateCompany = (data: RegistrationCompanyParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateCompany'], makeParam(data));

export const apiReqDeleteCompany = (data: DeleteDefaultParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteCompany'], makeParam(data));

export const apiReqCompanyListForReg = (data: SearchCompanyParams) =>
   request<PageData<CompanyReg>>('post', apiPath['reqCompanyListForReg'], makeParam(data));

export const apiReqRegistrationBrand = (data: RegistrationBrandParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRegistrationBrand'], makeParam(data));

export const apiReqUpdateBrand = (data: RegistrationBrandParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateBrand'], makeParam(data));

export const apiReqDeleteBrand = (data: DeleteBrandParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteBrand'], makeParam(data));

export const apiReqRegistrationManager = (data: RegistrationManagerParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRegistrationManager'], makeParam(data));

export const apiReqUpdateManager = (data: RegistrationManagerParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateManager'], makeParam(data));

export const apiReqApproveManager = (data: ApproveManagerParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqApproveManager'], makeParam(data));

export const apiReqDeleteManager = (data: DeleteManagerParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteManager'], makeParam(data));

export const apiReqRegistrationGroup = (data: RegistrationGroupParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRegistrationGroup'], makeParam(data));

export const apiReqUpdateGroup = (data: RegistrationGroupParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateGroup'], makeParam(data));

export const apiReqDeleteGroup = (data: DeleteDefaultParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteGroup'], makeParam(data));

export const apiReqRegistrationLocation = (data: RegistrationGroupParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRegistrationLocation'], makeParam(data));

export const apiReqUpdateLocation = (data: RegistrationGroupParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateLocation'], makeParam(data));

export const apiReqDeleteLocation = (data: DeleteDefaultParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteLocation'], makeParam(data));

export const apiReqRegistrationNotice = (data: RegistrationNoticeParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqAddNotice'], makeParam(data));

export const apiReqDeleteNotice = (data: DeleteDefaultParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteNotice'], makeParam(data));

export const apiReqUpdateNotice = (data: RegistrationNoticeParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateNotice'], makeParam(data));

export const apiReqRegistrationDevice = (data: RegistrationDeviceParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRegistrationDevice'], makeParam(data));

export const apiReqUpdateDevice = (data: RegistrationDeviceParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateDevice'], makeParam(data));

export const apiReqDeleteDevice = (data: DeleteDeviceParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteDevice'], makeParam(data));

export const apiReqLocationListForReg = (data: SearchLocationParams) =>
   request<PageData<DvLocationReg>>('post', apiPath['reqLocationListForReg'], makeParam(data));

export const apiReqGroupListForReg = (data: SearchGroupParams) =>
   request<PageData<GroupReg>>('post', apiPath['reqGroupListForReg'], makeParam(data));

export const apiReqOrganizedAdList = (data: OrganizedAdParams) =>
   request<PageData<OrganizedAd>>('post', apiPath['reqOrganizedAdList'], makeParam(data));

export const apiReqOrganizeAdToDevice = (data: OrganizeAdToDeviceParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqOrganizeAdToDevice'], makeParam(data));

export const apiReqRemoveAdFromDevice = (data: ExpungeAdFromDeviceParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRemoveAdFromDevice'], makeParam(data));

export const apiReqCancelRegistration = (data: CancelRegistrationParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqCancelRegistration'], makeParam(data));

export const apiReqDeviceListForReg = () =>
   request<PageData<DeviceReg>>('post', apiPath['reqDeviceListForReg'], makeParam());

export const apiReqRegistrationAdvertisement = (data: RegistrationAdvertisementParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRegistrationAdvertisement'], makeParam(data));

export const apiReqUpdateAdvertisement = (data: RegistrationAdvertisementParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateAdvertisement'], makeParam(data));

export const apiReqDeleteAdvertisement = (data: DeleteAdvertisementParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteAdvertisement'], makeParam(data));

export const apiReqApproveAdvertisement = (data: ApproveAdvertisementParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqApproveAdvertisement'], makeParam(data));

export const apiReqActivateCode = (data: ReqCodeCheckParam) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqSerialNumber'], makeParam(data));

export const apiReqCodePagePassword = () =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqCodePagePassword'], makeParam());

export const apiReqRegistrationContract = (data: RegistrationContractParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqRegistrationContract'], makeParam(data));

export const apiReqUpdateContract = (data: RegistrationContractParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqUpdateContract'], makeParam(data));

export const apiReqDeleteContract = (data: DeleteContractParams) =>
   request<DefaultApiResult<unknown>>('post', apiPath['reqDeleteContract'], makeParam(data));

export const apiReqDeviceHistoryList = (data: SearchDeviceHistoryParams) =>
   request<PageData<DvHistory>>('post', apiPath['reqDeviceHistoryList'], makeParam(data));

export const apiReqAdInfo = (data: SearchAdInfoParams) =>
   request<DefaultApiResult<AdInfo>>('post', apiPath['reqAdInfo'], makeParam(data));

export const apiReqAdjustOrganizedAdList = (data: adjustOrganizedAdListParams) =>
   request<PageData<OrganizedAd>>('post', apiPath['reqAdjustOrganizedAdList'], makeParam(data));

export const apiReqSearchDeviceLog = (data: SearchDeviceLogParams) =>
   request<PageData<DeviceLog>>('post', apiPath['reqSearchDeviceLog'], makeParam(data));

export const apiReqSearchCallLog = (data: SearchDeviceLogParams) =>
   request<PageData<CallLog>>('post', apiPath['reqSearchCallLog'], makeParam(data));

export const apiReqSearchTableLog = (data: SearchTableLogParams) =>
   request<PageData<TableLog>>('post', apiPath['reqSearchTableLog'], makeParam(data));