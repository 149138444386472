import { FC, useState, useEffect, useRef } from 'react';
import './index.less';
import PossessionGraph from './PossessionGraph';
import StatisticGraph from './StatisticGraph';
import { Button, Modal } from 'antd';
import MainAdList from './MainAdList';
import NoticeModal, { NoticeModalRef } from './NoticeModal';
import MainDeviceList from './MainDeviceList';
import MainKoreaMap from './MainKoreaMap';
import TopNoticeList from './TopNoticeList';

const DashBoardPage: FC = () => {
   const [loading, setLoading] = useState(true);
   const noticeModalRef = useRef<NoticeModalRef>();

   // mock timer to mimic dashboard data loading
   useEffect(() => {
   const timer = setTimeout(() => {
         setLoading(undefined as any);
      }, 2000);

      return () => {
         clearTimeout(timer);
      };
   }, []);

   return (
      <div className='dashboardRoot' style={{paddingLeft:'40px', paddingRight:'40px'}}>
         <div style={{width:'99%', height:'24px', marginInline:'0.5%', marginBottom:'10px', display:'flex', justifyContent:'center'}}>
            <TopNoticeList noticeModalRef={noticeModalRef} />
         </div>
         <div style={{display:'flex', paddingBottom:'20px'}}>
            <div style={{ width: '49.5%', marginRight:'1%'}}>
               <MainAdList/>
            </div>
            <div style={{ width: '49.5%' }}>
               <MainDeviceList/>
            </div>
         </div>
         {/* <div style={{display:'flex', paddingBottom:'20px'}}>
            <div style={{ width: '49.5%', marginRight:'1%'}}>
               <MainKoreaMap/>
            </div>
         </div> */}
         <NoticeModal ref={noticeModalRef}/>
      </div>
   );
};

export default DashBoardPage;
