export const enUS_locationRegistration = {
   'menu.locationRegistration.name': 'Location',
   'menu.locationRegistration.code': 'Code',
   'menu.locationRegistration.address': 'Address',
   'menu.locationRegistration.phone': 'Phone',
   'menu.locationRegistration.deviceCount': 'DeviceCount',
   'menu.locationRegistration.description': 'Description',
   'menu.locationRegistration.company': 'Company',
   'menu.locationRegistration.manager': 'Manager',
   'menu.locationRegistration.manager.email': 'Email',
   'menu.locationRegistration.manager.phone': 'Phone',
   'menu.locationRegistration.search.name': 'Search by Location',
   'menu.locationRegistration.search.address': 'Search by Address',
   'menu.locationRegistration.info.message': ' includes the locations below.',
   'menu.locationRegistration.modal.error.name': 'Please Input name',
   'menu.locationRegistration.modal.error.address': 'Please Input address',
   'menu.locationRegistration.modal.error.detail': 'Please Input detail address',
   'menu.locationRegistration.modal.registration.title': 'Location Registration',
   'menu.locationRegistration.modal.update.title': 'Location Update',
   'menu.locationRegistration.modal.delete.title': 'Location Delete',
   'menu.locationRegistration.modal.delete.description': 'Do you want delete?',
   'menu.locationRegistration.modal.registration.ok': 'Registration',
   'menu.locationRegistration.modal.update.ok': 'Update',
   'menu.locationRegistration.modal.registration.name': 'Name',
   'menu.locationRegistration.modal.registration.address': 'Address',
   'menu.locationRegistration.modal.registration.address.detail': 'Detail Address',
   'menu.locationRegistration.modal.registration.phone': 'Phone',
   'menu.locationRegistration.modal.registration.description': 'Description',
   'menu.locationRegistration.modal.registration.company': 'Company',
   'menu.locationRegistration.modal.registration.manager': 'Manager',
   'menu.locationRegistration.modal.registration.manager.email': 'Email',
   'menu.locationRegistration.modal.registration.manager.phone': 'Phone',
};
