import { FC, useEffect, useState } from 'react';
import logo from 'src/assets/login/ukmedia_logo.png';
import checkOn from 'src/assets/login/check_on.png';
import checkOff from 'src/assets/login/check_off.png';
import exitIcon from 'src/assets/login/exit.png';
import './index.less';
import { useLocale } from 'src/locales';
import { CheckSquareOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { checkCodeAsync, checkEmailAsync, joinRequestAsync } from 'src/stores/user.store';
import { useNavigate } from 'react-router-dom';

interface Step {
   idx: number;
   name: string;
   detail: string;
}

const RegisterForm: FC = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { formatMessage } = useLocale();
   const [curStep, setCurStep] = useState<number>(1);
   
   const [checkTermsAll, setCheckTermsAll] = useState<boolean>(false);
   const [checkTermsUK, setCheckTermsUK] = useState<boolean>(false);
   const [checkTermsPrivate, setCheckTermsPrivate] = useState<boolean>(false);
   const [checkTermsCompany, setCheckTermsCompany] = useState<boolean>(false);
   const [openTermsContentUK, setOpenTermsContentUK] = useState<boolean>(false);
   const [openTermsContentPrivate, setOpenTermsContentPrivate] = useState<boolean>(false);
   const [openTermsContentCompany, setOpenTermsContentCompany] = useState<boolean>(false);

   const [code, setCode] = useState<string>('');
   const [codeError, setCodeError] = useState<string>('');
   const [company, setCompany] = useState<string>('');
   const [brand, setBrand] = useState<string>('');
   const [location, setLocation] = useState<string>('');
   const [approvalId, setApprovalId] = useState<string>('');
   const [email, setEmail] = useState<string>('');
   const [emailPossible, setEmailPossible] = useState<number>(0);
   const [emailResult, setEmailResult] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const [passwordError, setPasswordError] = useState<string>('');
   const [rePassword, setRepassword] = useState<string>('');
   const [rePasswordError, setRepasswordError] = useState<string>('');
   const [name, setName] = useState<string>('');
   const [phoneNumber1, setPhoneNumber1] = useState<string>('');
   const [phoneNumber2, setPhoneNumber2] = useState<string>('');

   const registerSteps: Step[] = [
      { idx: 1, name: 'STEP.01', detail: formatMessage({ id: 'register.step.1' }) },
      { idx: 2, name: 'STEP.02', detail: formatMessage({ id: 'register.step.2' }) },
      { idx: 3, name: 'STEP.03', detail: formatMessage({ id: 'register.step.3' }) },
      { idx: 4, name: 'STEP.04', detail: formatMessage({ id: 'register.step.4' }) }
   ]

   const renderMent = (title: string, desc: string) => {
      return <div className='register-ment'><p className='register-title'>{title}</p><p className='register-ment'>{desc}</p></div>
   }

   const renderTermsCheckBox = (isCheck: boolean, clickFunc: () => void) => {
      return <CheckSquareOutlined style={isCheck ? { fontSize: '20px', color: '#FFFFFF', backgroundColor:'#00A199', marginRight: '10px' } : 
         { fontSize: '20px', color: '#DBDBDB', marginRight:'10px' }} onClick={clickFunc}/>
   }

   const renderTermsArrow = (isOpen: boolean, openFunc: (isOpen: boolean) => void) => {
      return <div style={{marginLeft:'auto', marginRight:'10px'}}>{isOpen ?
         <UpOutlined width={'20px'} height={'10px'} style={{color: '#707070'}} onClick={() => openFunc(false)}/> : 
         <DownOutlined width={'20px'} height={'10px'} style={{color: '#707070'}} onClick={() => openFunc(true)}/>
      }</div>
   }

   const checkAll = () => {
      if (checkTermsAll) {
         setCheckTermsAll(false);
         setCheckTermsUK(false);
         setCheckTermsPrivate(false);
         setCheckTermsCompany(false);
      } else {
         setCheckTermsAll(true);
         setCheckTermsUK(true);
         setCheckTermsPrivate(true);
         setCheckTermsCompany(true);
      }
   }

   const checkTerms = (isCheck: boolean, checkFunc: (isCheck: boolean) => void) => {
      checkFunc(!isCheck);
   }

   useEffect(() => {
      setCheckTermsAll(checkTermsUK && checkTermsPrivate && checkTermsCompany);
   }, [checkTermsUK, checkTermsPrivate, checkTermsCompany]);

   const clickTerms = () => {
      if (checkTermsAll) {
         setCurStep(2);
      } else {
         message.error(formatMessage({id: 'register.terms.error'}), 1);
      }
   }

   const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>, setPhoneNumberFunc?: (phoneNumber: string) => void) => {
      const { value } = e.target
      const onlyNumber = value.replace(/[^0-9]/g, '');
      setPhoneNumberFunc && setPhoneNumberFunc(onlyNumber);
   }

   const handleCode = (e: any) => {
      if (codeError) setCodeError('');
      setCode(e.target.value);
   }

   const clickCheckCode = () => {
      let type = code.substring(0, 1);
      if (type == 'C' || type == 'B' || type == 'D' || type == 'L') {
         dispatch(checkCodeAsync({
            type: type, code: code.substring(1), callbackFunc: (res) => {
               if (res.data.isSuccess) {
                  setCompany(res.data.companyName);
                  setBrand(res.data.brandName);
                  setLocation(res.data.locationName);
                  setApprovalId(res.data.approvalId);
               } else {
                  setCodeError(formatMessage({ id: 'register.input.code.error' }));
               }
            }
         }));
      } else {
         setCodeError(formatMessage({ id: 'register.input.code.error' }));
      }
   }

   const handleEmail = (e: any) => {
      if (emailPossible) setEmailPossible(0);
      setEmail(e.target.value);
   }

   const clickCheckEmail = () => {
      dispatch(checkEmailAsync({
         email: email, callbackFunc: (res) => {
            if (res.data.isSuccess) {
               setEmailPossible(1);
               setEmailResult(formatMessage({ id: 'register.input.email.possible' }));
            } else {
               setEmailPossible(2);
               setEmailResult(formatMessage({ id: 'register.input.email.error' }));
            }
         }
      }));
   }

   const handlePassword = (e: any) => {
      if (passwordError) setPasswordError('');
      setPassword(e.target.value);
   }   

   const checkPassword = () => {
      // 공백이 있거나 8자리 미만이거나 8~16자의 영문 소문자, 숫자, 특수문자 외 문자 사용시
      var isPW = /^[A-Za-z0-9`\-=\\\[\];',\./~!@#\$%\^&\*\(\)_\+|\{\}:"<>\?]{8,16}$/;
      if (password.search(/\s/) > -1 || password.length < 8 || !isPW.test(password)) {
         setPasswordError(formatMessage({ id: 'register.input.password.placeholder' }));
      }
      if(rePassword) checkRepassword();
   }

   const handleRepassword = (e: any) => {
      if (rePasswordError) setRepasswordError('');
      setRepassword(e.target.value);
   }

   const checkRepassword = () => {
      if (password != rePassword) {
         setRepasswordError(formatMessage({ id: 'register.input.repassword.error' }));
      }
   }

   const clickJoinRequest = () => {
      if (company.length == 0) message.error(formatMessage({ id: 'register.input.err.code' }), 1);
      else if (emailPossible != 1) message.error(formatMessage({ id: 'register.input.err.email' }), 1);
      else if (password.length == 0 || password != rePassword) message.error(formatMessage({ id: 'register.input.err.password' }), 1);
      else if (name.length == 0) message.error(formatMessage({ id: 'register.input.err.name' }), 1);
      else if (!phoneNumber1 || !phoneNumber2) message.error(formatMessage({ id: 'register.input.err.phone' }));
      else {
         dispatch(joinRequestAsync({
            email: email, password: password, name: name, phone: `010${phoneNumber1}${phoneNumber2}`, approvalId: approvalId, callbackFunc: (res) => {
               if (res.data.isSuccess) {
                  setCurStep(3);
               } else {
                  message.error(formatMessage({ id: 'register.input.err.request' }));
               }
            }
         }));
      }
   }

   const registerContents = [
      {},
      {
         content: <div className='register-center'>
            {
               <div style={{display:'block'}}>
                  {renderMent(formatMessage({ id: 'register.terms.title' }), formatMessage({ id: 'register.terms.ment' }))}
                  <div className='register-start-seperator'/>
                  <div className='register-terms-background register-terms-all'>
                     {renderTermsCheckBox(checkTermsAll, checkAll)}{formatMessage({ id: 'register.terms.name.all' })}</div>
                  <div className='register-terms-background register-terms'>{renderTermsCheckBox(checkTermsUK, () => checkTerms(checkTermsUK, setCheckTermsUK))}
                     {formatMessage({ id: 'register.terms.name.uk' })}<span>&nbsp;{formatMessage({ id: 'register.terms.essential' })}</span>
                     {renderTermsArrow(openTermsContentUK, setOpenTermsContentUK)}</div>
                  <div className='register-terms-content scrollBar' style={openTermsContentUK ? {display:'flex'} : {display:'none'}}>
                     {formatMessage({ id: 'register.terms.content.uk' })}</div>
                  <div className='register-terms-background register-terms'>{renderTermsCheckBox(checkTermsPrivate, () => checkTerms(checkTermsPrivate, setCheckTermsPrivate))}
                     {formatMessage({ id: 'register.terms.name.priavte' })}<span>&nbsp;{formatMessage({ id: 'register.terms.essential' })}</span>
                     {renderTermsArrow(openTermsContentPrivate, setOpenTermsContentPrivate)}</div>
                  <div className='register-terms-content scrollBar' style={openTermsContentPrivate ? {display:'flex'} : {display:'none'}}>
                     {formatMessage({ id: 'register.terms.content.private' })}</div>
                  <div className='register-terms-background register-terms'>{renderTermsCheckBox(checkTermsCompany, () => checkTerms(checkTermsCompany, setCheckTermsCompany))}
                     {formatMessage({ id: 'register.terms.name.company' })}<span>&nbsp;{formatMessage({ id: 'register.terms.essential' })}</span>
                     {renderTermsArrow(openTermsContentCompany, setOpenTermsContentCompany)}</div>
                  <div className='register-terms-content scrollBar' style={openTermsContentCompany ? {display:'flex'} : {display:'none'}}>
                     {formatMessage({ id: 'register.terms.content.company' })}</div>
                  <div className='register-end-seperator' />
                  <div className='register-center'><button className='register-button' onClick={clickTerms}>{formatMessage({id:'register.input.button'})}</button></div>
               </div>  
            }
         </div>
      },
      {
         content: <div className='register-center'>
            {
               <div style={{ display: 'block' }}>
                  {renderMent(formatMessage({ id: 'register.input.title' }), formatMessage({ id: 'register.input.ment' }))}
                  <div style={{height:'20px'}}/>
                  <div className='register-input'>
                     <p>{formatMessage({ id: 'register.input.code.name' })}</p>
                     <input className={codeError ? 'register-input-error' : ''} type='text' required value={code} placeholder={formatMessage({id: 'register.input.code.placeholder' })} style={{width:'250px'}} onChange={handleCode}/>
                     <button className='register-code-button' onClick={clickCheckCode}>{formatMessage({ id: 'register.input.code.button' })}</button>
                  </div>
                  {codeError ? <div className='register-error'>{codeError}</div> : <></>}
                  <div className='register-input'>
                     <p>{formatMessage({ id: 'register.input.company.name' })}</p>
                     <input type='text' readOnly value={company} placeholder={formatMessage({ id: 'register.input.company.placeholder' })} />
                  </div>
                  <div className='register-input'>
                     <p>{formatMessage({ id: location ? 'register.input.location.name' : 'register.input.brand.name' })}</p>
                     <input type='text' readOnly value={location ? location : brand} placeholder={formatMessage({ id: 'register.input.company.placeholder' })}/>
                  </div>
                  <div className='register-input'>
                     <p>{formatMessage({ id: 'register.input.id.name' })}</p>
                     <input type='text' value={email} onChange={handleEmail} placeholder={formatMessage({ id: 'register.input.id.placeholder' })} style={{ width: '250px' }} />
                     <button className='register-code-button' onClick={clickCheckEmail}>{formatMessage({ id: 'register.input.code.button' })}</button>
                  </div>
                  {emailPossible > 0 ? <div className={emailPossible == 1 ? 'register-error register-email-possible' : 'register-error'}>{emailResult}</div> : <></>}
                  <div className='register-input'>
                     <p>{formatMessage({ id: 'register.input.password.name' })}</p>
                     <input className={passwordError ? 'register-input-error' : ''} type='password' maxLength={16} required value={password} onChange={handlePassword} onBlur={checkPassword} placeholder={formatMessage({ id: 'register.input.password.placeholder' })} />
                  </div>
                  {passwordError ? <div className='register-error'>{passwordError}</div> : <></>}
                  <div className='register-input'>
                     <p>{formatMessage({ id: 'register.input.repassword.name' })}</p>
                     <input className={rePasswordError ? 'register-input-error' : ''} type='password' maxLength={16} required value={rePassword} onChange={handleRepassword} onBlur={checkRepassword} />
                  </div>
                  {rePasswordError ? <div className='register-error'>{rePasswordError}</div> : <></>}
                  <div className='register-input'>
                     <p>{formatMessage({ id: 'register.input.manager.name' })}</p>
                     <input type='text' required value={name} onChange={e => setName(e.target.value)} />
                  </div>
                  <div className='register-input'>
                     <p>{formatMessage({ id: 'register.input.phone.name' })}</p>
                     <div className='input-phone'>
                        <input type='text' required value={'010'} readOnly /><p>-</p>
                        <input type='text' required maxLength={4} value={phoneNumber1} onChange={e => handlePhoneNumber(e, setPhoneNumber1)} /><p>-</p>
                        <input type='text' required maxLength={4} value={phoneNumber2} onChange={e => handlePhoneNumber(e, setPhoneNumber2)} />
                     </div>
                  </div>
                  <div className='register-center'><button className='register-button' onClick={clickJoinRequest}>{formatMessage({id:'register.input.button'})}</button></div>
               </div>
            }
         </div>
      },
      { content: <div className='approval-center'>
         <img width={'70px'} height={'70px'} src={checkOff} />
         <p>UKMEDIA 승인대기</p>
         <span>{'회원가입의 정보입력이 완료되었습니다.\n관리자 승인 후 서비스 이용이 가능하며 영업일로 5일 이내\n가입해 주신 이메일 주소로 승인 메일 보내드립니다.'}</span>
      </div> },
   ];

   const renderSteps = () => {
      return <div className='register-steps-background'>
         <div className='register-center'>{registerSteps.map(step => (
            <>{step.idx == curStep ? <img width={'25px'} height={'25px'} style={{marginRight:'-6px'}} src={checkOn}/> : <div className='register-step-circle'/>}
            {step.idx < registerSteps.length ? <div className='register-step-line'/> : <></>}</>
         ))}</div>
         <div className='register-center' style={{marginLeft:'4px'}}>{registerSteps.map(step => (
            <div className='register-step-info'>
               <p className={step.idx == curStep ? 'register-step-name' : 'register-step-name register-step-not-selected'}>{step.name}</p>
               <p className={step.idx == curStep ? 'register-step-detail' : 'register-step-detail register-step-not-selected'}>{step.detail}</p></div>
         ))}</div>
      </div>;
   }

   return (
      <div>
         <div className="background">
            <div className="register-form">
               <img width={'25px'} height={'25px'} style={{ marginLeft:'1115px', marginTop:'10px', cursor:'pointer' }} src={exitIcon} onClick={() => navigate(-1)} />
               <div className='register-logo'><img src={logo} /><p>{formatMessage({ id: 'register.title' })}</p></div>
               {renderSteps()}
               {registerContents[curStep].content}
            </div>
         </div>
      </div>
   )
}

export default RegisterForm;