export const koKR_locationRegistration = {
   'menu.locationRegistration.name': '장소',
   'menu.locationRegistration.code': '소속코드',
   'menu.locationRegistration.address': '주소',
   'menu.locationRegistration.phone': '연락처',
   'menu.locationRegistration.deviceCount': '기기수',
   'menu.locationRegistration.description': '상세설명',
   'menu.locationRegistration.company': '회사',
   'menu.locationRegistration.manager': '담당자',
   'menu.locationRegistration.manager.email': '메일',
   'menu.locationRegistration.manager.phone': '전화번호',
   'menu.locationRegistration.search.name': '장소명 검색',
   'menu.locationRegistration.search.address': '주소에서 검색',
   'menu.locationRegistration.info.message': '에 속한 매장 목록입니다.',
   'menu.locationRegistration.modal.error.name': '이름은 필수 입력 항목입니다.',
   'menu.locationRegistration.modal.error.address': '주소는 필수 입력 항목입니다.',
   'menu.locationRegistration.modal.error.detail': '상세주소는 필수 입력 항목입니다.',
   'menu.locationRegistration.modal.registration.title': '장소 등록',
   'menu.locationRegistration.modal.update.title': '장소 수정',
   'menu.locationRegistration.modal.delete.title': '장소 삭제',
   'menu.locationRegistration.modal.delete.description': '삭제하시겠습니까?',
   'menu.locationRegistration.modal.registration.ok': '등록',
   'menu.locationRegistration.modal.update.ok': '수정',
   'menu.locationRegistration.modal.registration.name': '이름',
   'menu.locationRegistration.modal.registration.address': '주소',
   'menu.locationRegistration.modal.registration.address.detail': '상세주소',
   'menu.locationRegistration.modal.registration.phone': '연락처',
   'menu.locationRegistration.modal.registration.description': '상세설명',
   'menu.locationRegistration.modal.registration.company': '회사',
   'menu.locationRegistration.modal.registration.manager': '담당자',
   'menu.locationRegistration.modal.registration.manager.email': '이메일',
   'menu.locationRegistration.modal.registration.manager.phone': '연락처',
};
