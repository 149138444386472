import { apiReqNoticeList } from 'src/api/management.api';
import ApiTable, { ApiTableRef, ASearchBox, SearchCallbackParams } from 'src/components/core/apiTable/ApiTable';
import { Notice, SearchNoticeParams } from 'src/interface/management/dashboard';
import { LocaleFormatter } from 'src/locales';
import { NoticeMode, setDashboardState } from 'src/stores/dashboard.store';
import { getTimeStringByDate } from 'src/utils/StringUtil';
import { StarFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoticeDetail from './NoticeDetail';
import NoticeWriter from './NoticeWriter';



const NoticeList = () => {
   const dispatch = useDispatch();
   const { selectedNotice, noticeMode } = useSelector(state => state.dashboard)
   const apiTableRef = useRef<ApiTableRef>()
   const tableColums = [
      { title: "No", width: '5%', dataIndex: 'id', key: 'id', render: (data: string, r: Notice) => r.type == 0 ? <div style={{ textAlign: 'center' }}>{data}</div> : <div style={{ textAlign: 'center' }}><StarFilled /></div> },
      {
         title: <LocaleFormatter id="menu.dashboard.Notice.row.subject" />, width: '72%', dataIndex: 'subject', key: 'subject', render: (data: string, r: Notice) => <div className='textlink' style={r.type == 1 ? { fontWeight: 'bold', fontSize: '1.1em' } : {}}
            onClick={() => {
               dispatch(setDashboardState({ selectedNotice: r, noticeMode: NoticeMode.Detail }))
            }}><span className='ellipsesTableContents'>{data}</span></div>
      },
      { title: <LocaleFormatter id="menu.dashboard.Notice.row.author" />, width: '10%', dataIndex: 'author', key: 'author', render: (data: string) => <div style={{ textAlign: 'center' }}>{data}</div> },
      { title: <LocaleFormatter id="menu.dashboard.Notice.row.date" />, width: '13%', dataIndex: 'regDate', key: 'regDate', render: (data: string) => (<div style={{ textAlign: 'center' }}>{getTimeStringByDate(new Date(data), "YYYY. MM. DD")}</div>) },
   ]

   useEffect(() => {
      apiTableRef.current?.setApiParam({})
   }, [])

   useEffect(()=>{
      console.log("=============Scroll To")
      window.scrollTo({ top: 0, behavior: 'smooth' });  
   }, [selectedNotice])

   function onSearchBtnClicked(param: SearchCallbackParams) {
      let newParam = {} as SearchNoticeParams

      console.log(param)

      let key = param.data.selected?.id as 'subject' | 'author' | 'contents'
      newParam[key] = param.data.searchText
      apiTableRef.current?.setApiParam(newParam)
   }

   return (
      <div>
         {selectedNotice && selectedNotice.id && noticeMode == NoticeMode.Detail &&
            <div>
               <NoticeDetail apiTableRef={apiTableRef} />
               <br />
            </div>
         }

         {noticeMode == NoticeMode.Write && <NoticeWriter apiTableRef={apiTableRef} />}

         {/* {(noticeMode == NoticeMode.List || noticeMode == NoticeMode.Detail) && */}
         {(noticeMode == NoticeMode.List) &&
            <ApiTable
               pageApi={apiReqNoticeList}
               columns={tableColums}
               ref={apiTableRef}
               searchAreaLocation={'top'}
               apiCallWhenUndefinedParam
               searchAreaTailElements={noticeMode == NoticeMode.List ? [<Button onClick={() => dispatch(setDashboardState({ noticeMode: NoticeMode.Write, selectedNotice: undefined }))}>등록</Button>] : undefined}
               searchBox={noticeMode == NoticeMode.List ? [
                  {
                     id: 'search',
                     showSelect: true,
                     selectItemsProp: [
                        {
                           id: 'subject',
                           text: '제목'
                        },
                        // {
                        //    id: 'author',
                        //    text: '작성자'
                        // },
                        {
                           id: 'contents',
                           text: '내용'
                        }
                     ],
                     callBackFunc: onSearchBtnClicked
                  } as ASearchBox,
               ] : undefined}
            />}
      </div>
   );
}

export default NoticeList;