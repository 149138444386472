import { MenuList } from '../interface/layout/menu.interface';
import { GetMenu } from './menu';
import { ResMenuItem } from '../interface/user/login';
import { FormatMessageProps, useLocale } from '../locales';

/** Provides the menu list to be shown in the navigation sidebar */
export const GetMenuList = (formatMessage: FormatMessageProps, menu: ResMenuItem[]): MenuList => {
   const menuList: MenuList = [];
   let menuDataMap = GetMenu(formatMessage);
   let firstMenu = menu.filter(menu => menu.parent == undefined);
   for(let item of firstMenu) {
      let menuData = JSON.parse(JSON.stringify(menuDataMap.get(item.code)));
      if(menuData) {
         menuList.push({
            code: menuData.code,
            label: menuData.label,
            icon: menuData.icon,
            path: `/${menuData.code}`,
            write: item.write,
            children: menuData.children,
         });
      }
   }
   for(let item of menu) {
      let menuData = menuDataMap.get(item.code);
      if(menuData && item.parent !== undefined) {
         let parent = menuList.find(i => i.code == item.parent);
         parent && parent.children?.push({
            code: menuData.code,
            label: menuData.label,
            path: `/${item.parent}/${menuData.code}`,
            write: item.write,
         });
      }
   }
   return menuList;
}

