export const enUS_etcMenu = {
   'menu.deviceLog.id': 'ID',
   'menu.deviceLog.app': 'Origin',
   'menu.deviceLog.deviceId': 'DeviceID',
   'menu.deviceLog.serialNumber': 'SerialNumber',
   'menu.deviceLog.address': 'Address',
   'menu.deviceLog.eventDate': 'Date',
   'menu.deviceLog.eventType': 'Type',
   'menu.deviceLog.eventContent': 'Event',
   'menu.deviceLog.description': 'Description',
   'menu.deviceLog.eventType.all': 'All',
   'menu.deviceLog.eventType.error': 'Error',
   'menu.deviceLog.eventType.appStart': 'AppStart',
   'menu.deviceLog.eventType.appFinish': 'AppFinish',
   'menu.deviceLog.eventType.systemFinish': 'SystemFinish',
   'menu.deviceLog.eventType.activate': 'Activate',
   'menu.deviceLog.eventType.survivalReport': 'Survival Report',
   'menu.deviceLog.eventType.deviceEvent': 'Device Event',
   'menu.deviceLog.eventType.deviceReboot': 'System Reboot',
   'menu.deviceLog.eventType.deviceRegistration': 'Device Registration',
   'menu.deviceLog.eventType.deviceUpdate': 'Device Update',
   'menu.deviceLog.eventType.deviceDelete': 'Device Delete',
   'menu.deviceLog.eventType.deviceRegistrationFromContract': 'Device Registration From Contract',
   'menu.deviceLog.eventType.unknown': 'Unknown',
   'menu.deviceLog.search.deviceId': 'DeviceID',
   'menu.deviceLog.search.eventType': 'EventType',
   'menu.callLog.id': 'ID',
   'menu.callLog.api': 'API',
   'menu.callLog.processTime': 'ProcessTime',
   'menu.callLog.request.from': 'ReqFrom',
   'menu.callLog.request.time': 'ReqTime',
   'menu.callLog.request.param': 'ReqParam',
   'menu.callLog.response.from': 'ResTo',
   'menu.callLog.response.code': 'ResCode',
   'menu.callLog.response.resData': 'ResponseData',
   'menu.tableLog.id': 'ID',
   'menu.tableLog.name': 'Event',
   'menu.tableLog.tableName': 'Table',
   'menu.tableLog.maker': 'Maker',
   'menu.tableLog.description': 'Description',
   'menu.tableLog.date': 'Date',
   'menu.tableLog.type': 'Type',
};