import { FC, useEffect, useCallback, useState, Suspense } from 'react';
import { Layout, Drawer } from 'antd';
import './index.less';
import MenuComponent from './menu';
import HeaderComponent from './header';
import { getGlobalState } from 'src/utils/getGloabal';
import TagsView from './tagView';
import { GetMenuList } from 'src/api/layout.api';
import { MenuList, MenuChild } from 'src/interface/layout/menu.interface';
import { Outlet, useLocation } from 'react-router';
import { setUserItem } from 'src/stores/user.store';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstPathCode } from 'src/utils/getFirstPathCode';
import { FormatMessageProps, useLocale } from 'src/locales';

const { Sider, Content } = Layout;
const WIDTH = 992;

const LayoutPage: FC = () => {
   const location = useLocation();
   const { formatMessage } = useLocale();
   const [openKey, setOpenkey] = useState<string>();
   const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
   const [menuList, setMenuList] = useState<MenuList>([]);
   const { device, collapsed, menu } = useSelector(state => state.user);
   const isMobile = device === 'MOBILE';
   const dispatch = useDispatch();

   useEffect(() => {
      const code = getFirstPathCode(location.pathname);

      setOpenkey(code);
   }, [location.pathname]);

   const toggle = () => {
      console.log("menu move");
      dispatch(
         setUserItem({
            collapsed: !collapsed,
         }),
      );
   };

   const initMenuListAll = (menu: MenuList) => {
      const MenuListAll: MenuChild[] = [];

      menu.forEach(m => {
         if (!m?.children?.length) {
            MenuListAll.push(m);
         } else {
            m?.children.forEach(mu => {
               MenuListAll.push(mu);
            });
         }
      });

      return MenuListAll;
   };

   const fetchMenuList = useCallback(async (formatMessage: FormatMessageProps) => {
      const result = await GetMenuList(formatMessage, menu);

      if (result) {
         setMenuList(result);
         dispatch(
            setUserItem({
               menuList: initMenuListAll(result),
            }),
         );
      }
   }, [dispatch]);

   useEffect(() => {
      fetchMenuList(formatMessage);
   }, [menu]);

   useEffect(() => {
      window.onresize = () => {
         const { device } = getGlobalState();
         const rect = document.body.getBoundingClientRect();
         const needCollapse = rect.width < WIDTH;

         dispatch(
            setUserItem({
               device,
               collapsed: needCollapse,
            }),
         );
      };
   }, [dispatch]);

   return (
      <Layout className="layout-page">
         <HeaderComponent collapsed={collapsed} toggle={toggle} />
         <Layout>
            {!isMobile ? (
               <Sider
                  className="layout-page-sider"
                  width={320}
                  trigger={null}
                  collapsible
                  collapsedWidth={isMobile ? 0 : 80}
                  collapsed={collapsed}
                  breakpoint="md"
               >
                  <MenuComponent
                     toggle={toggle}
                     menuList={menuList}
                     openKey={openKey}
                     onChangeOpenKey={k => setOpenkey(k)}
                     selectedKey={selectedKey}
                     onChangeSelectedKey={k => setSelectedKey(k)}
                  />
               </Sider>
            ) : (
               <Drawer
                  width="200"
                  placement="left"
                  bodyStyle={{ padding: 0, height: '100%' }}
                  closable={false}
                  onClose={toggle}
                  visible={!collapsed}
               >
                  <MenuComponent
                     toggle={toggle}
                     menuList={menuList}
                     openKey={openKey}
                     onChangeOpenKey={k => setOpenkey(k)}
                     selectedKey={selectedKey}
                     onChangeSelectedKey={k => setSelectedKey(k)}
                  />
               </Drawer>
            )}
            <Content className="layout-page-content">
               <TagsView onChangeSelectedKey={k => setSelectedKey(k)} />
               <Suspense fallback={null}>
                  <Outlet />
               </Suspense>
            </Content>
         </Layout>
      </Layout>
   );
};

export default LayoutPage;
