export const koKR_publicTips = {
  'gloabal.tips.notfound': '죄송합니다. 방문한 페이지가 존재하지 않습니다.',
  'gloabal.tips.unauthorized': '죄송합니다. 이 페이지에 액세스할 수 있는 권한이 없습니다.',
  'gloabal.tips.loginResult': '이 페이지가 표시되면 로그인한 것입니다.',
  'gloabal.tips.goToLogin': '로그인으로 이동',
  'gloabal.tips.username': '이름',
  'gloabal.tips.password': '비밀번호',
  'gloabal.tips.login': '로그인',
  'gloabal.tips.backHome': '홈으로',
  'gloabal.tips.operation': '기업',
  'gloabal.tips.authorize': '인증',
  'gloabal.tips.delete': '삭제',
  'gloabal.tips.create': '생성',
  'gloabal.tips.modify': '수정',
  'gloabal.tips.search': '검색',
  'gloabal.tips.reset': '초기화',
  'gloabal.tips.deleteConfirm': '이 항목을 삭제하시겠습니까?',
  'gloabal.tips.loading': '로딩...',
  'gloabal.tips.theme.darkTooltip': '다크 테마로 전환',
  'gloabal.tips.theme.lightTooltip': '라이트 테마로 전환',
};
