import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from './utils';
import { ApiType, DefaultApiResult, SearchDefaultParams } from '../interface/management';
import { DeviceSetAd, DeviceSetDvGroup, DeviceSetDevice, SearchDeviceSetDeviceParams, OrganizeAdToDeviceParams, BrandReg, RegistrationAdvertisementParams, DeleteAdvertisementParams, ApproveAdvertisementParams, ExpungeAdFromDeviceParams, DeviceExpunge, SearchDeviceSetAdParams, DeviceSetItem } from '../interface/management/advertisement';
import { apiReqApproveAdvertisement, apiReqBrandListForReg, apiReqDeleteAdvertisement, apiReqOrganizeAdToDevice, apiReqRegistrationAdvertisement, apiReqRemoveAdFromDevice, apiReqS3Config, apiReqSearchAdInDevSetting, apiReqSearchBrandInDevSetting as apiReqSearchDvGroupInDevSetting, apiReqSearchDeviceExpunge, apiReqSearchDeviceInDevSetting, apiReqUpdateAdvertisement } from '../api/management.api';

export interface S3Config {
   bucketName: string;
   dirName: string;
   region: string;
   accessKeyId: string;
   secretAccessKey: string;
   s3Url: string;
}

interface S3ConfigState {
   s3ConfigList?: S3Config[];
}

export interface BrandListState {
   brandList: BrandReg[];
}

interface DevSetState {
   selectedAdList: DeviceSetAd[];
   selectedAdIdList: number[];
   selectedDvGroupId: number,
   selectedItemList: DeviceSetItem[];
   selectedDeviceIdList: number[];
   selectedDvGroupIdList: number[];
   selectedStartDate: string;
   selectedEndDate: string;
   selectedStartTime: string;
   selectedEndTime: string;
}

export interface AdListInDeviceState {
   totalAdCount: number;
   adList: DeviceSetAd[];
}

export interface DvGroupListInDeviceState {
   dvGroupList: DeviceSetDvGroup[];
}

export interface DeviceListInDeviceState {
   totalDeviceCount: number;
   deviceList: DeviceSetDevice[];
}

export interface DeviceListExpungeState {
   expungeDeviceList: DeviceExpunge[];
}

const initialState: S3ConfigState & BrandListState & AdListInDeviceState & DvGroupListInDeviceState & DeviceListInDeviceState & DevSetState & DeviceListExpungeState = {
   s3ConfigList: [],
   brandList: [],
   totalAdCount: 0,
   adList: [],
   selectedAdList: [],
   dvGroupList: [],
   totalDeviceCount: 0,
   deviceList: [],
   selectedItemList: [],
   selectedAdIdList: [],
   selectedDvGroupId: 0,
   selectedDeviceIdList: [],
   selectedDvGroupIdList: [],
   expungeDeviceList: [],
   selectedStartDate: '',
   selectedEndDate: '',
   selectedStartTime: '',
   selectedEndTime: '',
};

const adManagementSlice = createSlice({
   name: 'deviceSetting',
   initialState,
   reducers: {
      init(state) {
         state.totalAdCount = 0;
         state.adList = [];
         state.selectedAdList = [];
         state.dvGroupList = [];
         state.totalDeviceCount = 0;
         state.deviceList = [];
         state.selectedItemList = [];
         state.selectedAdIdList = [];
         state.selectedDvGroupId = 0;
         state.selectedDeviceIdList = [];
      },
      setS3Config(state, action: PayloadAction<Partial<S3ConfigState>>) {
         const { s3ConfigList } = action.payload;

         state.s3ConfigList = JSON.parse(JSON.stringify(s3ConfigList));
         
         Object.assign(state, action.payload);
      },
      setBrandListForReg(state, action: PayloadAction<Partial<BrandListState>>) {
         const { brandList } = action.payload;

         state.brandList = JSON.parse(JSON.stringify(brandList));

         Object.assign(state, action.payload);
      },
      setAdListInDeviceSet(state, action: PayloadAction<Partial<AdListInDeviceState>>) {
         const { totalAdCount, adList } = action.payload;

         totalAdCount && (state.totalAdCount = totalAdCount);
         state.adList = JSON.parse(JSON.stringify(adList));

         Object.assign(state, action.payload);
      },
      setBrandListInDeviceSet(state, action: PayloadAction<Partial<DvGroupListInDeviceState>>) {
         const { dvGroupList: brandList } = action.payload;

         state.dvGroupList = JSON.parse(JSON.stringify(brandList));
      },
      setDeviceListInDeviceSet(state, action: PayloadAction<Partial<DeviceListInDeviceState>>) {
         const { totalDeviceCount, deviceList } = action.payload;

         totalDeviceCount && (state.totalDeviceCount = totalDeviceCount);
         state.deviceList = JSON.parse(JSON.stringify(deviceList));

         Object.assign(state, action.payload);
      },
      setDevSetState(state, action: PayloadAction<Partial<DevSetState>>) {
         Object.assign(state, action.payload);
      },
      setDeviceListExpunge(state, action: PayloadAction<Partial<DeviceListExpungeState>>) {
         const { expungeDeviceList } = action.payload;

         state.expungeDeviceList = JSON.parse(JSON.stringify(expungeDeviceList));

         Object.assign(state, action.payload);
      },
   },
});

export const { init, setS3Config, setBrandListForReg, setAdListInDeviceSet, setBrandListInDeviceSet, setDeviceListInDeviceSet, setDevSetState, setDeviceListExpunge } = adManagementSlice.actions;

export default adManagementSlice.reducer;

export const s3ConfigAsync = createAsyncAction<SearchDefaultParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqS3Config(payload);

      console.log(result);

      if (result) {
         dispatch(setS3Config({ s3ConfigList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const brandListAsync = createAsyncAction<SearchDefaultParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqBrandListForReg(payload);

      console.log(result);

      if (result) {
         dispatch(setBrandListForReg({ brandList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const adListAsync = createAsyncAction<SearchDeviceSetAdParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqSearchAdInDevSetting(payload);

      console.log(result);

      if (result) {
         dispatch(setAdListInDeviceSet({ totalAdCount: result.data.total, adList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const dvGroupListAsync = createAsyncAction<SearchDefaultParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqSearchDvGroupInDevSetting(payload);

      console.log(result);

      if (result) {
         dispatch(setBrandListInDeviceSet({ dvGroupList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const deviceListAsync = createAsyncAction<SearchDeviceSetDeviceParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqSearchDeviceInDevSetting(payload);

      console.log(result);

      if (result) {
         dispatch(setDeviceListInDeviceSet({ totalDeviceCount: result.data.total, deviceList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const organizeAsync = createAsyncAction<OrganizeAdToDeviceParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqOrganizeAdToDevice(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});

export const expungeAsync = createAsyncAction<ExpungeAdFromDeviceParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRemoveAdFromDevice(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)

      return result;
   };
});

export const expungeDeviceListAsync = createAsyncAction<SearchDeviceSetDeviceParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqSearchDeviceExpunge(payload);

      console.log(result);

      if (result) {
         dispatch(setDeviceListExpunge({ expungeDeviceList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const registrationAdvertisementAsync = createAsyncAction<RegistrationAdvertisementParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationAdvertisement(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});

export const updateAdvertisementAsync = createAsyncAction<RegistrationAdvertisementParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateAdvertisement(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)

      return result;
   };
});

export const deleteAdvertisementAsync = createAsyncAction<DeleteAdvertisementParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteAdvertisement(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)

      return result;
   };
});

export const approveAdvertisementAsync = createAsyncAction<ApproveAdvertisementParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqApproveAdvertisement(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)

      return result;
   };
});