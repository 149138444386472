import { FC, lazy } from 'react';
import Dashboard from '../pages/dashboard';
import LoginPage from '../pages/login/login';
import FindIdPage from '../pages/login/findId';
import LayoutPage from '../pages/layout';
import { Navigate, RouteObject } from 'react-router';
import WrapperRouteComponent from './config';
import { useRoutes } from 'react-router-dom';
import RegisterForm from '../pages/login/register';
import CodeCheckerPage from '../pages/codeChecker';
import UserInfoForm from '../pages/login/userInfo';
import UpdateForm from '../pages/login/update';

const lazyWithRetry = (componentImport: any) =>
   lazy(async () => {
      const pageHasAlreadyBeenForceRefreshed = JSON.parse(
         window.localStorage.getItem(
            'page-has-been-force-refreshed'
         ) || 'false'
      );

      try {
         const component = await componentImport();

         window.localStorage.setItem(
            'page-has-been-force-refreshed',
            'false'
         );

         return component;
      } catch (error) {
         if (!pageHasAlreadyBeenForceRefreshed) {
            // Assuming that the user is not on the latest version of the application.
            // Let's refresh the page immediately.
            window.localStorage.setItem(
               'page-has-been-force-refreshed',
               'true'
            );
            return window.location.reload();
         }

         // The page has already been reloaded
         // Assuming that user is already using the latest version of the application.
         // Let's let the application crash and raise the error.
         throw error;
      }
   });

const AdCompanyManagement = lazyWithRetry(() => import('../pages/adManagement/companyManagement'));
const BrandManagement = lazyWithRetry(() => import('../pages/adManagement/brandManagement'));
const AdRegistration = lazyWithRetry(() => import('../pages/adManagement/adRegistration'));
const DeviceOrganize = lazyWithRetry(() => import('../pages/adManagement/deviceOrganize'));
const DeviceExpunge = lazyWithRetry(() => import('../pages/adManagement/deviceExpunge'));
const AdBasedDataManagement = lazyWithRetry(() => import('../pages/dataManagement/adBasedDataManagement'));
const DeviceBasedDataManagement = lazyWithRetry(() => import('../pages/dataManagement/deviceBasedDataManagement'));
const DevCompanyManagement = lazyWithRetry(() => import('../pages/deviceManagement/companyManagement'));
const DeviceRegistration = lazyWithRetry(() => import('../pages/deviceManagement/deviceRegistration'));
const GroupRegistration = lazyWithRetry(() => import('../pages/deviceManagement/groupRegistration'));
const LocationRegistration = lazyWithRetry(() => import('../pages/deviceManagement/locationRegistration'));
const ContractRegistration = lazyWithRetry(() => import('../pages/contractManagement/contractRegistration'));
const ManagerManagement = lazyWithRetry(() => import('../pages/userManament/userInfoManagement'));
const DeviceLog = lazyWithRetry(() => import('../pages/etc/deviceLog'));
const CallLog = lazyWithRetry(() => import("../pages/etc/callLog"));
const TableLog = lazyWithRetry(() => import("../pages/etc/tableLog"));

const routeList: RouteObject[] = [
   {
      path: '/login',
      element: <WrapperRouteComponent element={<LoginPage />} titleId="title.login" />,
   },
   {
      path: '/findId',
      element: <WrapperRouteComponent element={<FindIdPage />} titleId="title.findId" />
   },
   {
      path: '/register',
      element: <WrapperRouteComponent element={<RegisterForm />} titleId="title.register" />,
   },
   {
      path: '/codeChecker',
      element: <WrapperRouteComponent element={<CodeCheckerPage />} titleId="title.codeChecker" />
   },
   {
      path: '/userInfo',
      element: <WrapperRouteComponent element={<UserInfoForm />} titleId="title.userInfo" />
   },
   {
      path: '/update',
      element: <WrapperRouteComponent element={<UpdateForm />} titleId="title.update" />
   },
   {
      element: <WrapperRouteComponent element={<LayoutPage />} titleId="" />,
      children: [
         {
            path: '',
            element: <Navigate to="dashboard" />,
         },
         {
            path: '/dashboard',
            element: <WrapperRouteComponent element={<Dashboard />} titleId="title.dashboard" auth />,
         },
         {
            path: '/adManagement/adCompanyRegAndMng',
            element: <WrapperRouteComponent element={<AdCompanyManagement />} titleId="title.adManagement.registration" auth />,
         },
         {
            path: '/adManagement/adBrandRegAndMng',
            element: <WrapperRouteComponent element={<BrandManagement />} titleId="title.adManagement.registration" auth />,
         },
         {
            path: '/adManagement/adRegAndMng',
            element: <WrapperRouteComponent element={<AdRegistration />} titleId="title.adManagement.registration" auth />,
         },
         {
            path: '/adManagement/adDeviceOrganize',
            element: <WrapperRouteComponent element={<DeviceOrganize />} titleId="title.adManagement.deviceOrganize" auth />,
         },
         {
            path: '/adManagement/adDeviceExpunge',
            element: <WrapperRouteComponent element={<DeviceExpunge />} titleId="title.adManagement.deviceExpunge" auth />,
         },
         {
            path: '/dataManagement/adBasedDataMng',
            element: <WrapperRouteComponent element={<AdBasedDataManagement />} titleId="title.dataManagement.adBased" auth />,
         },
         {
            path: '/dataManagement/deviceBasedDataMng',
            element: <WrapperRouteComponent element={<DeviceBasedDataManagement />} titleId="title.dataManagement.deviceBased" auth />,
         },
         {
            path: '/deviceManagement/locCompanyRegAndMng',
            element: <WrapperRouteComponent element={<DevCompanyManagement />} titleId="title.deviceManagement.locationReg" auth />,
         },
         {
            path: '/deviceManagement/locationRegAndMng',
            element: <WrapperRouteComponent element={<LocationRegistration />} titleId="title.deviceManagement.locationReg" auth />,
         },
         {
            path: '/deviceManagement/deviceRegAndMng',
            element: <WrapperRouteComponent element={<DeviceRegistration />} titleId="title.deviceManagement.deviceReg" auth />,
         },
         {
            path: '/deviceManagement/groupRegAndMng',
            element: <WrapperRouteComponent element={<GroupRegistration />} titleId="title.deviceManagement.groupReg" auth />,
         },
         {
            path: '/contractRegAndMng',
            element: <WrapperRouteComponent element={<ContractRegistration />} titleId="title.contractManagement.contractReg" auth />,
         },
         {
            path: '/userManagement',
            element: <WrapperRouteComponent element={<ManagerManagement />} titleId="title.userManagement.userInfo" auth />,
         },
         {
            path: '/log/deviceLog',
            element: <WrapperRouteComponent element={<DeviceLog />} titleId="title.log.device" auth />,
         },
         {
            path: '/log/callLog',
            element: <WrapperRouteComponent element={<CallLog />} titleId="title.log.event" auth />,
         },
         {
            path: '/log/apromEventLog',
            element: <WrapperRouteComponent element={<TableLog />} titleId="title.log.call" auth />,
         },
      ],
   },
];

const RenderRouter: FC = () => {
   const element = useRoutes(routeList);

   return element;
};

export default RenderRouter;
