import { FC, useEffect, useState } from 'react';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { UserInfo, userInfoUpdateAsync } from "src/stores/user.store";
import { useNavigate } from 'react-router-dom';
import { useLocale } from 'src/locales';
import { phoneNumberFormat } from 'src/utils/StringUtil';
import { Button, Modal, message } from 'antd';

const UpdateForm: FC = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { userId, userInfoList } = useSelector(state => state.user);
   const { formatMessage } = useLocale();
   const [name, setName] = useState<string>('');
   const [phoneNumber1, setPhoneNumber1] = useState<string>('');
   const [phoneNumber2, setPhoneNumber2] = useState<string>('');
   const [prevPassword, setPrevPassword] = useState<string>('');
   const [prevPasswordError, setPrevPasswordError] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const [passwordError, setPasswordError] = useState<string>('');
   const [rePassword, setRepassword] = useState<string>('');
   const [rePasswordError, setRepasswordError] = useState<string>('');

   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => { userInfoList && userInfoList[0] && setUserInfo(userInfoList[0]); }, []);

   const setUserInfo = (userInfo: UserInfo) => {
      setName(userInfo.name);
      setPhoneNumber1(userInfo.phone.substring(3, 7));
      setPhoneNumber2(userInfo.phone.substring(7, 11));
   }

   const onUpdate = () => {
      dispatch(userInfoUpdateAsync({
         email: userId, name: name, phone: `010${phoneNumber1}${phoneNumber2}`, prevPassword: prevPassword, newPassword: password, callbackFunc: (res) => {
            if (res.data.isSuccess) {
               setIsModalOpen(true);
            } else {
               if (res.data.errCode == 100) setPrevPasswordError(formatMessage({ id: 'userInfo.update.error.password' }));
               else message.error(formatMessage({ id: 'register.input.err.request' }));
            }
         }
      }));
   }

   const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>, setPhoneNumberFunc?: (phoneNumber: string) => void) => {
      const { value } = e.target
      const onlyNumber = value.replace(/[^0-9]/g, '');
      setPhoneNumberFunc && setPhoneNumberFunc(onlyNumber);
   }

   const handlePrevPassword = (e: any) => {
      if (prevPasswordError) setPrevPasswordError('');
      setPrevPassword(e.target.value);
   }

   const handlePassword = (e: any) => {
      if (passwordError) setPasswordError('');
      setPassword(e.target.value);
   }

   const checkPassword = () => {
      // 공백이 있거나 8자리 미만이거나 8~16자의 영문 소문자, 숫자, 특수문자 외 문자 사용시
      var isPW = /^[A-Za-z0-9`\-=\\\[\];',\./~!@#\$%\^&\*\(\)_\+|\{\}:"<>\?]{8,16}$/;
      if (password.search(/\s/) > -1 || password.length < 8 || !isPW.test(password)) {
         setPasswordError(formatMessage({ id: 'register.input.password.placeholder' }));
      }
      if (rePassword) checkRepassword();
   }

   const handleRepassword = (e: any) => {
      if (rePasswordError) setRepasswordError('');
      setRepassword(e.target.value);
   }

   const checkRepassword = () => {
      if (password != rePassword) {
         setRepasswordError(formatMessage({ id: 'register.input.repassword.error' }));
      }
   }

   const handleOk = () => {
      setIsModalOpen(false);
      navigate(-2);
   }

   return (
      <div>
         <div className="background">
            <div className="userInfoUpdate-form">
               <div className='userInfo-title'>회원정보수정</div>
               <div className='userInfoUpdate-input'>
                  <p>{formatMessage({ id: 'userInfo.name' })}</p>
                  <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
               </div>
               <div className='userInfoUpdate-input'>
                  <p>{formatMessage({ id: 'userInfo.phone' })}</p>
                  <div className='userInfo-input-phone'>
                     <input type='text' required value={'010'} readOnly /><p>-</p>
                     <input type='text' required maxLength={4} value={phoneNumber1} onChange={e => handlePhoneNumber(e, setPhoneNumber1)} /><p>-</p>
                     <input type='text' required maxLength={4} value={phoneNumber2} onChange={e => handlePhoneNumber(e, setPhoneNumber2)} />
                  </div>
               </div>
               <div className='userInfoUpdate-input'>
                  <p>{formatMessage({ id: 'userInfo.password.prev' })}</p>
                  <input type='password' maxLength={16} value={prevPassword} onChange={handlePrevPassword} />
               </div>
               {prevPasswordError ? <div className='userInfoUpdate-error'>{prevPasswordError}</div> : <></>}
               <div className='userInfoUpdate-input'>
                  <p>{formatMessage({ id: 'userInfo.password.cur' })}</p>
                  <input type='password' maxLength={16} value={password} onChange={handlePassword} onBlur={checkPassword} />
               </div>
               {passwordError ? <div className='userInfoUpdate-error'>{passwordError}</div> : <></>}
               <div className='userInfoUpdate-input'>
                  <p>{formatMessage({ id: 'userInfo.password.re' })}</p>
                  <input type='password' maxLength={16} value={rePassword} onChange={handleRepassword} onBlur={checkRepassword} />
               </div>
               {rePasswordError ? <div className='userInfoUpdate-error'>{rePasswordError}</div> : <></>}
               <button className='join-button' onClick={onUpdate}>수정</button>
            </div>
         </div>
         <Modal title={formatMessage({ id: 'userInfo.update.success.title' })} open={isModalOpen} onOk={handleOk} footer={
            <Button onClick={handleOk}>확인</Button>
         }>
            {formatMessage({ id: 'userInfo.update.success.title' })}
         </Modal>
      </div>
   );

};

export default UpdateForm;
