import { FC } from 'react';
import { ReactComponent as AdvertisementSvg } from 'src/assets/menu/advertisement.svg';
import { ReactComponent as MonitorSvg } from 'src/assets/menu/monitor.svg';
import { ReactComponent as DeviceSvg } from 'src/assets/menu/device.svg';
import { ReactComponent as UsersSvg } from 'src/assets/menu/users.svg';
import { ReactComponent as DashboardSvg } from 'src/assets/menu/dashboard.svg';
import { ReactComponent as ManagementSvg } from 'src/assets/menu/Management.svg';
import { ReactComponent as AccountSvg } from 'src/assets/menu/account.svg';
import { AlignLeftOutlined } from '@ant-design/icons';

interface CustomIconProps {
  type: string;
}

export const CustomIcon: FC<CustomIconProps> = props => {
  const { type } = props;
  let com = <DashboardSvg />;

  if (type === 'dashboard') {
    com = <DashboardSvg />;
  } else if (type === 'device') {
    com = <DeviceSvg />;
  } else if (type === 'advertisement') {
    com = <AdvertisementSvg />;
  } else if (type === 'monitor') {
    com = <MonitorSvg />;
  } else if (type === 'users') {
    com = <UsersSvg />;
  } else if (type === 'logs') {
    com = <AlignLeftOutlined />;
  } else {
    com = <AccountSvg />;
  }

  return <span className="anticon">{com}</span>;
};
