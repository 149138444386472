export const koKR_groupRegistration = {
   'menu.groupRegistration.name': '이름',
   'menu.groupRegistration.description': '상세설명',
   'menu.groupRegistration.deviceCount': '기기수',
   'menu.groupRegistration.search.name': '그룹명 검색',
   'menu.groupRegistration.search.desc': '설명으로 검색',
   'menu.groupRegistration.modal.error': '그룹 이름은 필수 입력 항목입니다.',
   'menu.groupRegistration.modal.registration.title': '그룹 등록',
   'menu.groupRegistration.modal.update.title': '그룹 수정',
   'menu.groupRegistration.modal.delete.title': '그룹 삭제',
   'menu.groupRegistration.modal.delete.description': '삭제하시겠습니까?',
   'menu.groupRegistration.modal.registration.ok': '등록',
   'menu.groupRegistration.modal.update.ok': '수정',
   'menu.groupRegistration.modal.registration.name': '이름',
   'menu.groupRegistration.modal.registration.description': '설명',
   'menu.groupRegistration.modal.registration.device': '기기',
};