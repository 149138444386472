import axios, { AxiosRequestConfig, Method } from 'axios';
import { message as $message } from 'antd';
import { setConfigState } from '../stores/config.store';
import store from '../stores';

const axiosInstance = axios.create({
  timeout: 6000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type":"application/x-www-form-urlencoded",
  }
});

axiosInstance.interceptors.request.use(
  config => {
    store.dispatch(
      setConfigState({
        loading: true,
      }),
    );

    return config;
  },
  error => {
    store.dispatch(
      setConfigState({
        loading: false,
      }),
    );
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  config => {
    store.dispatch(
      setConfigState({
        loading: false,
      }),
    );
    if (config?.data?.message) {
      // $message.success(config.data.message)
    }

    return config?.data;
  },
  error => {
    store.dispatch(
      setConfigState({
        loading: false,
      }),
    );
    // if needs to navigate to login page when request exception
    // history.replace('/login');
    let errorMessage = '시스템에러';

    if (error?.message?.includes('Network Error')) {
      errorMessage = '네트워크 오류입니다. 네트워크를 확인해주세요.';
    } else {
      errorMessage = error?.message;
    }
    console.dir(error);
    error.message && $message.error(errorMessage);

    return {
      status: false,
      message: errorMessage,
      result: null,
    };
  },
);


export type Response<T = any> = Promise<T>;

/**
 *
 * @param method - request methods
 * @param url - request url
 * @param data - request data or params
 */
export const request = <T = any>(
  method: Lowercase<Method>,
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Response<T> => {
  console.log(`request url:`, url, `  //  data:`, data);

  const prefix = '';

  url = prefix + url;
  if (method === 'post') {
    return axiosInstance.post(url, data, config);
  } else {
    return axiosInstance.get(url, {
      params: data,
      ...config,
    });
  }
};
