import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { apiReqApproveManager, apiReqCompanyListForReg, apiReqDeleteBrand, apiReqDeleteCompany, apiReqDeleteManager, apiReqManagerListForReg, apiReqRegistrationBrand, apiReqRegistrationCompany, apiReqRegistrationManager, apiReqUpdateBrand, apiReqUpdateCompany, apiReqUpdateManager } from '../api/management.api';
import { ApiType, DefaultApiResult } from '../interface/management';
import { ApproveManagerParams, CompanyReg, DeleteBrandParams, DeleteCompanyParams, DeleteManagerParams, ManagerReg, RegistrationBrandParams, RegistrationCompanyParams, RegistrationManagerParams, SearchCompanyParams, SearchManagerParams } from '../interface/management/user';
import { createAsyncAction } from './utils';

export interface ManagerListState {
   managerList: ManagerReg[];
}

export interface CompanyListState {
   companyList: CompanyReg[];
}

const initialState: ManagerListState & CompanyListState = {
   managerList: [],
   companyList: [],
};

const userManagementSlice = createSlice({
   name: 'userManagment',
   initialState,
   reducers: {
      setManagerList(state, action: PayloadAction<Partial<ManagerListState>>) {
         const { managerList } = action.payload;

         state.managerList = JSON.parse(JSON.stringify(managerList));

         Object.assign(state, action.payload);
      },
      setCompanyList(state, action: PayloadAction<Partial<CompanyListState>>) {
         const { companyList } = action.payload;

         state.companyList = JSON.parse(JSON.stringify(companyList));

         Object.assign(state, action.payload);
      },
   },
});

export const { setManagerList, setCompanyList } = userManagementSlice.actions;

export default userManagementSlice.reducer;

export const managerListAsync = createAsyncAction<SearchManagerParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqManagerListForReg(payload);

      console.log(result);

      if (result) {
         dispatch(setManagerList({ managerList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const registrationCompanyAsync = createAsyncAction<RegistrationCompanyParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationCompany(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});

export const updateCompanyAsync = createAsyncAction<RegistrationCompanyParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateCompany(payload);
      
      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)
      
      return result;
   };
});

export const deleteCompanyAsync = createAsyncAction<DeleteCompanyParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteCompany(payload);
      
      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)
      
      return result;
   };
});

export const companyListAsync = createAsyncAction<SearchCompanyParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqCompanyListForReg(payload);
      
      console.log(result);
      
      if (result) {
         dispatch(setCompanyList({ companyList: result.data.list }));
         return true;
      }
      
      return false;
   };
});

export const registrationBrandAsync = createAsyncAction<RegistrationBrandParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationBrand(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});

export const updateBrandAsync = createAsyncAction<RegistrationBrandParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateBrand(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)

      return result;
   };
});

export const deleteBrandAsync = createAsyncAction<DeleteBrandParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteBrand(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)

      return result;
   };
});

export const registrationManagerAsync = createAsyncAction<RegistrationManagerParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationManager(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});

export const updateManagerAsync = createAsyncAction<RegistrationManagerParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateManager(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)

      return result;
   };
});

export const approveManagerAsync = createAsyncAction<ApproveManagerParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqApproveManager(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)

      return result;
   };
});

export const deleteManagerAsync = createAsyncAction<DeleteManagerParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteManager(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)

      return result;
   };
});