export const koKR_adRegistration = {
   'menu.adRegistration.name': '광고명',
   'menu.adRegistration.description': '내용',
   'menu.adRegistration.thumbnail': '썸네일',
   'menu.adRegistration.company': '회사',
   'menu.adRegistration.brand': '브랜드',
   'menu.adRegistration.detail': '광고 상세정보',
   'menu.adRegistration.approval': '승인',
   'menu.adRegistration.startDate': '시작일',
   'menu.adRegistration.endDate': '마감일',
   'menu.adRegistration.duration': '운영일',
   'menu.adRegistration.remain': '마감까지',
   'menu.adRegistration.accounts': '운영구좌수',
   'menu.adRegistration.cumulativeAccounts': '총 누적 구좌수',
   'menu.adRegistration.dailyAccounts': '일평균 구좌수',
   'menu.adRegistration.totalPlayCount': '총 누적 광고 재생',
   'menu.adRegistration.totalViewCount': '총 누적 시청',
   'menu.adRegistration.totalURLCount': '총 누적 URL',
   'menu.adRegistration.totalQRCount': '총 누적 QR',
   'menu.adRegistration.organize.step.ad': '광고 선택',
   'menu.adRegistration.organize.step.device': '편성 기기 선택',
   'menu.adRegistration.organize.step.date': '편성일 선택',
   'menu.adRegistration.organize.step.next': '다음',
   'menu.adRegistration.organize.step.prev': '이전',
   'menu.adRegistration.organize.step.organize': '편성',
   'menu.adRegistration.organize.ad.selectAd': '편성할 광고를 선택해 주세요',
   'menu.adRegistration.organize.ad.selectedAd': '선택한 광고',
   'menu.adRegistration.organize.ad.notSigned': '미승인',
   'menu.adRegistration.organize.ad.selectGroup': '그룹 선택',
   'menu.adRegistration.organize.ad.selectDevice': '기기 선택',
   'menu.adRegistration.organize.ad.selectedDevice': '선택한 그룹이나 기기',
   'menu.adRegistration.organize.ad.selectDate': '편성일 선택',
   'menu.adRegistration.organize.ad.selectTime': '편성시간 선택',
   'menu.adRegistration.expunge.selectAd': '편성에서 제외할 광고를 선택해 주세요',
   'menu.adRegistration.expunge.selectDevice': '선택한 광고를 제외할 기기를 선택해 주세요',
   'menu.adRegistration.expunge.delete': '삭제',
   'menu.adRegistration.search.name': '광고명',
   'menu.adRegistration.search.company': '회사',
   'menu.adRegistration.search.brand': '브랜드',
   'menu.adRegistration.organizeDevice': '편성정보',
   'menu.adRegistration.preview': '미리보기',
   'menu.adRegistration.organize.complete': '광고 편성이 완료되었습니다.',
   'menu.adRegistration.modal.error.name': '광고 이름은 필수 입력 항목입니다.',
   'menu.adRegistration.modal.error.brand': '브랜드는 필수 입력 항목입니다.',
   'menu.adRegistration.modal.error.type': '타입은 필수 입력 항목입니다.',
   'menu.adRegistration.modal.error.file': '동영상 혹은 이미지 파일을 선택해 주세요.',
   'menu.adRegistration.modal.registration.title': '광고 등록',
   'menu.adRegistration.modal.update.title': '광고 정보 수정',
   'menu.adRegistration.modal.delete.title': '광고 삭제',
   'menu.adRegistration.modal.organize.title': '편성 기기 정보',
   'menu.adRegistration.modal.preview.title': '광고 미리보기',
   'menu.adRegistration.modal.delete.description': '삭제하시겠습니까?',
   'menu.adRegistration.modal.registration.ok': '등록',
   'menu.adRegistration.modal.update.ok': '수정',
   'menu.adRegistration.modal.preview.info': '정보',
   'menu.adRegistration.modal.preview.approve': '승인',
   'menu.adRegistration.modal.preview.approve.cancel': '승인취소',
   'menu.adRegistration.modal.preview.close': '닫기',
   'menu.adRegistration.modal.registration.name': '이름',
   'menu.adRegistration.modal.registration.description': '내용',
   'menu.adRegistration.modal.registration.detail': '상세 정보',
   'menu.adRegistration.modal.registration.brand': '브랜드',
   'menu.adRegistration.modal.registration.type': '타입',
   'menu.adRegistration.modal.registration.detailUrl': '자세히보기 URL',
   'menu.adRegistration.modal.registration.qrUrl': 'QR코드 URL',
   'menu.adRegistration.modal.registration.file': '광고 파일',
   'menu.adRegistration.modal.registration.file.button': '파일찾기',
   'menu.adRegistration.modal.registration.file.none': '선택된 파일이 없습니다.',
   'menu.adRegistration.modal.registration.video': '영상',
   'menu.adRegistration.modal.registration.thumbnail': '썸네일',
   'menu.adRegistration.modal.registration.image.top': '상단 이미지',
   'menu.adRegistration.modal.registration.image.bottom': '하단 이미지',
   'menu.adRegistration.modal.registration.image': '이미지',
   'menu.adRegistration.modal.registration.type.vertical': '동영상 타입(세로형/가로형)',
   'menu.adRegistration.modal.registration.type.horizontal': '상,하단 이미지와 가로 동영상 타입',
   'menu.adRegistration.modal.registration.type.image': '이미지 타입',
};