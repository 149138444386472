export const enUS_contractRegistration = {
   'menu.contractRegistration.id': 'ID',
   'menu.contractRegistration.contractorName': '계약자',
   'menu.contractRegistration.contractCount': '계약대수',
   'menu.contractRegistration.contractType': '타입',
   'menu.contractRegistration.contractPeriod': '계약기간',
   'menu.contractRegistration.location': '설치장소',
   'menu.contractRegistration.location.address': '주소',
   'menu.contractRegistration.location.id': 'ID',
   'menu.contractRegistration.location.regist': '개통',
   'menu.contractRegistration.location.total': '계약',
   'menu.contractRegistration.description': '비고',
   'menu.contractRegistration.search.company': '회사명 검색',
   'menu.contractRegistration.search.location': '매장명 검색',
   'menu.contractRegistration.modal.error.contractorType': '계약 대상을 선택해주세요',
   'menu.contractRegistration.modal.error.company': '회사를 선택해주세요',
   'menu.contractRegistration.modal.error.location': '매장을 선택해주세요',
   'menu.contractRegistration.modal.error.contractType': '계약 형태를 선택해주세요',
   'menu.contractRegistration.modal.error.date': '계약 시작일을 입력해주세요',
   'menu.contractRegistration.modal.error.period': '계약 기간을 입력해주세요',
   'menu.contractRegistration.modal.error.contractCount': '계약 대수를 1대 이상으로 설정해주세요.',
   'menu.contractRegistration.modal.error.availableCount': '계약 대수만큼 매장에 배치해주세요.',
   'menu.contractRegistration.modal.registration.title': '계약 등록',
   'menu.contractRegistration.modal.update.title': '계약 정보 수정',
   'menu.contractRegistration.modal.delete.title': '계약 삭제',
   'menu.contractRegistration.modal.organizedAd.title': '광고 편성 정보',
   'menu.contractRegistration.modal.delete.description': '삭제하시겠습니까?',
   'menu.contractRegistration.modal.registration.ok': '등록',
   'menu.contractRegistration.modal.update.ok': '수정',
   'menu.contractRegistration.modal.organizedAd.close': '닫기',
   'menu.contractRegistration.modal.registration.contractorType': '계약 대상',
   'menu.contractRegistration.modal.registration.company': '회사',
   'menu.contractRegistration.modal.registration.location': '매장',
   'menu.contractRegistration.modal.registration.address': '주소',
   'menu.contractRegistration.modal.registration.type.company': '회사 목록에서 선택',
   'menu.contractRegistration.modal.registration.type.location': '매장 목록에서 선택',
   'menu.contractRegistration.modal.registration.contractType': '계약 형태',
   'menu.contractRegistration.modal.registration.type.sale': '매매',
   'menu.contractRegistration.modal.registration.type.rent': '임대',
   'menu.contractRegistration.modal.registration.start': '계약 시작일',
   'menu.contractRegistration.modal.registration.startAndEnd': '계약 기간',
   'menu.contractRegistration.modal.registration.deviceCount': '계약 대수',
};