import { Dispatch } from '@reduxjs/toolkit';
import { AppStore } from '../stores';
import { useDispatch, useSelector } from 'react-redux';
import { ResMenuItem } from 'src/interface/user/login';

type ThunkAction<T = any> = (dispatch: Dispatch, state: AppStore['getState']) => Promise<T>;

export const createAsyncAction = <T = any, R = any>(cb: (arg: T) => ThunkAction<R>) => {
  return cb;
};

export function usePermission(code: string): ResMenuItem {
   const { menu } = useSelector(state => state.user);
   let resMenu = menu.find(m => m.code == code);
   return resMenu ? resMenu : { code: 'undefined', read: false, write: false };
}

export const PERMISSION_DASHBOARD = 'dashboard';
export const PERMISSION_AD_MANAGEMENT = 'adManagement';
export const PERMISSION_AD_COMPANY = 'adCompanyRegAndMng';
export const PERMISSION_AD_BRAND = 'adBrandRegAndMng';
export const PERMISSION_AD_REGANDMNG = 'adRegAndMng';
export const PERMISSION_AD_ORGANIZE = 'adDeviceOrganize';
export const PERMISSION_AD_EXPUNGE = 'adDeviceExpunge';
export const PERMISSION_DATA_MANAGEMENT = 'dataManagement';
export const PERMISSION_DATA_AD_BASED = 'adBasedDataMng';
export const PERMISSION_DATA_DEVICE_BASED = 'deviceBasedDataMng';
export const PERMISSION_DEVICE_MANAGEMENT = 'deviceManagement';
export const PERMISSION_DEVICE_COMPANY = 'locCompanyRegAndMng';
export const PERMISSION_LOCATION_REGANDMNG = 'locationRegAndMng';
export const PERMISSION_DEVICE_REGANDMNG = 'deviceRegAndMng';
export const PERMISSION_GROUP_REGANDMNG = 'groupRegAndMng';
export const PERMISSION_CONTRACT_REGANDMNG = 'contractRegAndMng';
export const PERMISSION_USER_MANAGEMENT = 'userManagement';
