import { useLocale } from 'src/locales';
import { MutableRefObject, useEffect, useState } from 'react';
import { NoticeModalRef } from './NoticeModal';
import { TagOutlined } from '@ant-design/icons';
import { noticeListAsync } from 'src/stores/dashboard.store';
import { useDispatch, useSelector } from 'react-redux';
import { Notice } from 'src/interface/management/dashboard';
import { getTimeStringByDate } from 'src/utils/StringUtil';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export interface MainAdListProps {
   noticeModalRef: MutableRefObject<NoticeModalRef | undefined>
}

const TopNoticeList = (props: MainAdListProps) => {
   const dispatch = useDispatch();
   const { formatMessage } = useLocale();
   const { noticeList } = useSelector(state => state.dashboard);

   const [fixedList, setFixedList] = useState<Notice[]>();
   const [normalList, setNormalList] = useState<Notice[]>();

   const fixedSliderSetting = {
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 10000,
   }

   const normalSliderSetting = {
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 6000,
   }

   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (!loading) {
         setLoading(true);
         dispatch(noticeListAsync({limitPeriod: true}));
      }
   }, []);

   useEffect(() => {
      console.log('noticeList', noticeList);
      setLoading(false);
      setNoticeList(noticeList);
   }, [noticeList]);

   const setNoticeList = (list: Notice[]) => {
      setFixedList(list.filter(n => n.type == 1));
      setNormalList(list.filter(n => n.type == 0));
   }

   return (
      <div className='top-notice'>
         <div className='top-notice-title' onClick={() => props.noticeModalRef.current?.showModal()}>공지사항&nbsp;&nbsp;&#62;</div>
         <div className='top-notice-rolling'>
            {fixedList ? <Slider {...fixedSliderSetting}>
               {fixedList.length > 0 ? fixedList.map((n) => {
                  return <div className='top-notice-notice' onClick={() => props.noticeModalRef.current?.showModal(n)}>
                     <div style={{ paddingLeft: '5%', height: '20px' }}><TagOutlined /></div>
                     <div className='ellipsesTableContents'>{n.subject}</div>
                     <div className='ellipsesTableContents-date'>{getTimeStringByDate(new Date(String(n.regDate)), "YYYY. MM. DD")}</div>
                  </div>;
               }) : <div className='top-notice-notice' onClick={() => props.noticeModalRef.current?.showModal()}>
                  <div className='ellipsesTableContents'>최신 공지사항이 없습니다.</div>
               </div>}
            </Slider> : <></>}
         </div>
         <div className='top-notice-rolling'>
            {normalList ? <Slider {...normalSliderSetting}>
               {normalList.length > 0 ? normalList.map((n) => {
                  return <div className='top-notice-notice' onClick={() => props.noticeModalRef.current?.showModal(n)}>
                     <div className='ellipsesTableContents'>{n.subject}</div>
                     <div className='ellipsesTableContents-date'>{getTimeStringByDate(new Date(String(n.regDate)), "YYYY. MM. DD")}</div>
                  </div>;
               }) : <div className='top-notice-notice' onClick={() => props.noticeModalRef.current?.showModal()}>
                  <div className='ellipsesTableContents'>최신 공지사항이 없습니다.</div>
               </div>}
            </Slider> : <></>}
         </div>
      </div>
   );
}

export default TopNoticeList;