export const enUS_companyManagement = {
   'menu.companyManagement.company': 'Company',
   'menu.companyManagement.code': 'Code',
   'menu.companyManagement.type': 'Type',
   'menu.companyManagement.brand': 'Brand',
   'menu.companyManagement.location': 'Location',
   'menu.companyManagement.description': 'Description',
   'menu.companyManagement.manager': 'Manager',
   'menu.companyManagement.email': 'Email',
   'menu.companyManagement.phone': 'Phone',
   'menu.companyManagement.search.name': 'Search by Name',
   'menu.companyManagement.search.desc': 'Search by Description',
   'menu.companyManagement.modal.error': 'Please Input Company Name',
   'menu.companyManagement.modal.registration.title': 'Company Registration',
   'menu.companyManagement.modal.update.title': 'Company Update',
   'menu.companyManagement.modal.delete.title': 'Company Delete',
   'menu.companyManagement.modal.delete.description': 'Do you want delete?',
   'menu.companyManagement.modal.registration.ok': 'Registration',
   'menu.companyManagement.modal.update.ok': 'Update',
   'menu.companyManagement.modal.registration.name': 'Name',
   'menu.companyManagement.modal.registration.type': 'Type',
   'menu.companyManagement.modal.registration.description': 'Description',
   'menu.companyManagement.modal.registration.manager': 'Manager',
   'menu.companyManagement.modal.registration.email': 'Email',
   'menu.companyManagement.modal.registration.phone': 'Phone',
};
