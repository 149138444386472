export const enUS_dashboard = {
   'menu.dashboard.circleGraph.title': 'Possession Of Advertisement',
   'menu.dashboard.circleGraph.sector1': 'Possession by Company',
   'menu.dashboard.circleGraph.sector2': 'Possession by Brands',
   'menu.dashboard.statisticsGraph.viewCount': 'Viewers',
   'menu.dashboard.statisticsGraph.playCount': 'Count of Play',
   'menu.dashboard.AdStatus.today': 'ad of the day',
   'menu.dashboard.AdStatus.week': 'ad of the week',
   'menu.dashboard.AdStatus.month': 'ad of the month',
   'menu.dashboard.AdStatus.row.adName': 'Ad Name',
   'menu.dashboard.AdStatus.row.startDate': 'Start Date',
   'menu.dashboard.AdStatus.row.endDate': 'End Date',
   'menu.dashboard.AdStatus.row.period': 'Period',
   'menu.dashboard.AdStatus.row.accepted': 'Accepted',
   'menu.dashboard.AdStatus.row.deviceCount': 'Device',
   'menu.dashboard.Notice.title': 'Notice',
   'menu.dashboard.Notice.close': 'Close',
   'menu.dashboard.Notice.row.subject': 'Subject',
   'menu.dashboard.Notice.row.author': 'Author',
   'menu.dashboard.Notice.row.date': 'Date',
   'menu.dashboard.Notice.row.contents': 'Contents',
   'menu.dashboard.Notice.row.type': 'Type',
};
