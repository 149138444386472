import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiReqAdDailyAdList, apiReqAdDailyStatistic, apiReqAdStatisticByTime, apiReqCompanyListForReg, apiReqDeleteContract, apiReqDeviceStatisticDaily, apiReqLocationListForReg, apiReqRegistrationContract, apiReqStatisticDeviceList, apiReqUpdateContract } from 'src/api/management.api';
import { createAsyncAction } from './utils';
import { AdDailyAd, AdDailyStatistic, AdStatisticParams, AdStatisticByTime, DeviceStatisticDaily, DeviceStatisticParams, StatisticDevice, StatisticGroup } from 'src/interface/management/data';

export interface AdStatisticState {
   selectedAdId: number
}

export interface AdDailyStatisticState {
   adDailyAdList?: AdDailyAd[],
   adDailyStatistic?: AdDailyStatistic
}

export interface AdStatisticByTimeState {
   adStatisticByTimeList?: AdStatisticByTime[]
}

export interface DeviceStatisticDailyState {
   statisticDeviceList?: StatisticDevice[],
   statisticGroupList?: StatisticGroup[],
}

const initialState: AdStatisticState & AdDailyStatisticState & AdStatisticByTimeState & DeviceStatisticDailyState = {
   selectedAdId: 0,
   adDailyAdList: undefined,
   adDailyStatistic: undefined,
   adStatisticByTimeList: undefined,
   statisticDeviceList: undefined,
   statisticGroupList: undefined,
};

const dataManagementSlice = createSlice({
   name: 'dataManagment',
   initialState,
   reducers: {
      setAdId(state, action: PayloadAction<Partial<AdStatisticState>>) {
         Object.assign(state, action.payload);
      },
      setAdDailyAdList(state, action: PayloadAction<Partial<AdDailyStatisticState>>) {
         const { adDailyAdList } = action.payload;

         state.adDailyAdList = JSON.parse(JSON.stringify(adDailyAdList));

         Object.assign(state, action.payload);
      },
      setAdDailyList(state, action: PayloadAction<Partial<AdDailyStatisticState>>) {
         const { adDailyStatistic } = action.payload;

         state.adDailyStatistic = JSON.parse(JSON.stringify(adDailyStatistic));

         Object.assign(state, action.payload);
      },
      setAdStatisticByTime(state, action: PayloadAction<Partial<AdStatisticByTimeState>>) {
         const { adStatisticByTimeList } = action.payload;

         state.adStatisticByTimeList = JSON.parse(JSON.stringify(adStatisticByTimeList));

         Object.assign(state, action.payload);
      },
      setDeviceStatisticDaily(state, action: PayloadAction<Partial<DeviceStatisticDailyState>>) {
         const { statisticDeviceList, statisticGroupList } = action.payload;

         state.statisticDeviceList = JSON.parse(JSON.stringify(statisticDeviceList));
         state.statisticGroupList = JSON.parse(JSON.stringify(statisticGroupList));

         Object.assign(state, action.payload);
      },
   },
});

export const { setAdId, setAdDailyAdList, setAdDailyList, setAdStatisticByTime, setDeviceStatisticDaily } = dataManagementSlice.actions;

export default dataManagementSlice.reducer;

export const adDailyAdListAsync = createAsyncAction<{}, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqAdDailyAdList();

      console.log(result);

      if (result) {
         dispatch(setAdDailyAdList({ adDailyAdList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const adDailyStatisticAsync = createAsyncAction<AdStatisticParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqAdDailyStatistic(payload);

      console.log(result);

      if (result) {
         dispatch(setAdDailyList({ adDailyStatistic: result.data }));
         return true;
      }

      return false;
   };
});

export const adStatisticByTimeAsync = createAsyncAction<AdStatisticParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqAdStatisticByTime(payload);

      console.log(result);

      if (result) {
         dispatch(setAdStatisticByTime({ adStatisticByTimeList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const statisticDeviceListAsync = createAsyncAction<{}, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqStatisticDeviceList();

      console.log(result);

      if (result) {
         dispatch(setDeviceStatisticDaily({ statisticDeviceList: result.data.deviceList, statisticGroupList: result.data.groupList }));
         return true;
      }

      return false;
   };
});