import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from './utils';
import { apiReqAdInfo, apiReqAdjustOrganizedAdList, apiReqCancelRegistration, apiReqDeleteDevice, apiReqDeleteGroup, apiReqDeleteLocation, apiReqDeviceHistoryList, apiReqDeviceListForReg, apiReqGroupListForReg, apiReqLocationListForReg, apiReqOrganizedAdList, apiReqRegistrationDevice, apiReqRegistrationGroup, apiReqRegistrationLocation, apiReqUpdateDevice, apiReqUpdateGroup, apiReqUpdateLocation } from '../api/management.api';
import { ApiType, DefaultApiResult, DeleteDefaultParams } from '../interface/management';
import { AdInfo, adjustOrganizedAdListParams, CancelRegistrationParams, DeleteDeviceParams, DeviceReg, DvHistory, DvLocationReg, GroupReg, OrganizedAd, OrganizedAdParams, RegistrationDeviceParams, RegistrationGroupParams, RegistrationLocationParams, SearchAdInfoParams, SearchDeviceHistoryParams, SearchGroupParams, SearchLocationParams } from '../interface/management/device';

export interface LocationListState {
   locationList: DvLocationReg[];
}

export interface GroupListState {
   groupList: GroupReg[];
}

export interface DeviceListState {
   deviceList: DeviceReg[];
}

export interface OrganizedAdListState {
   organizedAdList: OrganizedAd[];
   organizedAdHistory: OrganizedAd[];
}

export interface HistoryListState {
   historyList: DvHistory[];
}

export interface AdInfoState {
   adInfo?: AdInfo;
}

const initialState: LocationListState & GroupListState & DeviceListState & OrganizedAdListState & HistoryListState & AdInfoState = {
   locationList: [],
   groupList: [],
   deviceList: [],
   organizedAdList: [],
   organizedAdHistory: [],
   historyList: [],
   adInfo: undefined,
};

const deviceManagementSlice = createSlice({
   name: 'deviceManagment',
   initialState,
   reducers: {
      setLocationList(state, action: PayloadAction<Partial<LocationListState>>) {
         const { locationList } = action.payload;

         state.locationList = JSON.parse(JSON.stringify(locationList));

         Object.assign(state, action.payload);
      },
      setGroupList(state, action: PayloadAction<Partial<GroupListState>>) {
         const { groupList } = action.payload;

         state.groupList = JSON.parse(JSON.stringify(groupList));

         Object.assign(state, action.payload);
      },
      setDeviceList(state, action: PayloadAction<Partial<DeviceListState>>) {
         const { deviceList } = action.payload;

         state.deviceList = JSON.parse(JSON.stringify(deviceList));

         Object.assign(state, action.payload);
      },
      setOrganizedAdList(state, action: PayloadAction<Partial<OrganizedAdListState>>) {
         const { organizedAdList, organizedAdHistory } = action.payload;

         if (organizedAdList) state.organizedAdList = JSON.parse(JSON.stringify(organizedAdList));
         if (organizedAdHistory) state.organizedAdHistory = JSON.parse(JSON.stringify(organizedAdHistory));

         Object.assign(state, action.payload);
      },
      setHistoryList(state, action: PayloadAction<Partial<HistoryListState>>) {
         const { historyList } = action.payload;

         state.historyList = JSON.parse(JSON.stringify(historyList));

         Object.assign(state, action.payload);
      },
      setAdInfo(state, action: PayloadAction<Partial<AdInfoState>>) {
         Object.assign(state, action.payload);
      },
   },
});

export const { setLocationList, setGroupList, setDeviceList, setOrganizedAdList, setHistoryList, setAdInfo } = deviceManagementSlice.actions;

export default deviceManagementSlice.reducer;

export const locationListAsync = createAsyncAction<SearchLocationParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqLocationListForReg(payload);

      console.log(result);

      if (result) {
         dispatch(setLocationList({ locationList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const groupListAsync = createAsyncAction<SearchGroupParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqGroupListForReg(payload);

      console.log(result);

      if (result) {
         dispatch(setGroupList({ groupList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const organizedAdListAsync = createAsyncAction<OrganizedAdParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqOrganizedAdList(payload);

      console.log(result);

      if (result) {
         if (payload.isHistory) dispatch(setOrganizedAdList({ organizedAdHistory: result.data.list }));
         else dispatch(setOrganizedAdList({ organizedAdList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const deviceListAsync = createAsyncAction<{}, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqDeviceListForReg();

      console.log(result);

      if (result) {
         dispatch(setDeviceList({ deviceList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const registrationGroupAsync = createAsyncAction<RegistrationGroupParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationGroup(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});

export const updateGroupAsync = createAsyncAction<RegistrationGroupParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateGroup(payload);
      
      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)
      
      return result;
   };
});

export const deleteGroupAsync = createAsyncAction<DeleteDefaultParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteGroup(payload);
      
      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)
      
      return result;
   };
});

export const registrationLocationAsync = createAsyncAction<RegistrationLocationParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationLocation(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});

export const updateLocationAsync = createAsyncAction<RegistrationLocationParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateLocation(payload);
      
      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)
      
      return result;
   };
});

export const deleteLocationAsync = createAsyncAction<DeleteDefaultParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteLocation(payload);
      
      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)
      
      return result;
   };
});

export const registrationDeviceAsync = createAsyncAction<RegistrationDeviceParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqRegistrationDevice(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});

export const updateDeviceAsync = createAsyncAction<RegistrationDeviceParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqUpdateDevice(payload);
      
      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Update)
      
      return result;
   };
});

export const deleteDeviceAsync = createAsyncAction<DeleteDeviceParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqDeleteDevice(payload);
      
      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Delete)
      
      return result;
   };
});

export const historyListAsync = createAsyncAction<SearchDeviceHistoryParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqDeviceHistoryList(payload);

      console.log(result);

      if (result) {
         dispatch(setHistoryList({ historyList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const adInfoAsync = createAsyncAction<SearchAdInfoParams, DefaultApiResult<AdInfo>>(payload => {
   return async dispatch => {
      const result = await apiReqAdInfo(payload);

      if (result) {
         dispatch(setAdInfo({ adInfo: result.data }));
      }

      return result;

   };
});

export const adjustOrganizedAdList = createAsyncAction<adjustOrganizedAdListParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqAdjustOrganizedAdList(payload);

      console.log(result);

      if (result) {
         dispatch(setOrganizedAdList({ organizedAdList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const cancelRegistrationAsync = createAsyncAction<CancelRegistrationParams, DefaultApiResult<unknown>>(payload => {
   return async dispatch => {
      const result = await apiReqCancelRegistration(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.Registration)

      return result;
   };
});
