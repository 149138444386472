import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { apiCheckCode, apiCheckEmail, apiFindID, apiInitPassword, apiJoinRequest, apiLogin, apiReqUserInfo, apiReqUserInfoUpdate } from '../api/management.api';
import { CheckCodeParams, CheckCodeResult, FindIDParams, FindIDResult, InitPasswordParams, DefaultResult, LoginParams, LoginResult, Role, JoinRequestParams, CheckEmailParams, ResMenuItem, UserInfoUpdateParams } from '../interface/user/login';
import { Locale, UserState } from '../interface/user/user';
import { createAsyncAction } from './utils';
import { getGlobalState } from '../utils/getGloabal';
import { isTrue } from '../utils/StringUtil';
import { ApiType, DefaultApiResult } from 'src/interface/management';

export interface LoginState {
   currentState: number;
   findIdTab: string;
}

export interface UserInfo {
   email: string;
   name: string;
   phone: string;
   authority: string;
   company?: string[];
   brand?: string[];
   location?: string[];
}

export interface PermissionWrite {
   canRead: boolean;
}

interface UserInfoState {
   userInfoList?: UserInfo[];
}

const getMenuFromSessionStorage = (data: string): ResMenuItem[] => {
   return JSON.parse(data);
}

const initialState: UserState & LoginState & UserInfoState = {
   ...getGlobalState(),
   noticeCount: 0,
   locale: (localStorage.getItem('locale')! || 'ko_KR') as Locale,
   saveId: isTrue(localStorage.getItem('saveId')),
   userId: localStorage.getItem('userId') || '',
   menu: getMenuFromSessionStorage(sessionStorage.getItem('menu') || '[]'),
   logged: isTrue(sessionStorage.getItem('logged')),
   nickname: sessionStorage.getItem('nickname') || '',
   menuList: [],
   sessionTime: sessionStorage.getItem('sessionTime') || '',
   currentState: 0,
   findIdTab: '1',
   userInfoList: [],
};

const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      setUserItem(state, action: PayloadAction<Partial<UserState>>) {
         const { saveId, userId, logged, nickname, menu } = action.payload;

         console.log(saveId, userId, logged, nickname, menu);
         
         if (saveId != undefined) localStorage.setItem('saveId', saveId ? 't': 'f');
         if (userId) localStorage.setItem('userId', userId || '');
         if (logged != undefined) sessionStorage.setItem('logged', logged ? 't': 'f');
         if (nickname) sessionStorage.setItem('nickname', nickname);
         if (menu != undefined) sessionStorage.setItem('menu', JSON.stringify(menu));

         Object.assign(state, action.payload);
      },
      setLoginState(state, action: PayloadAction<Partial<LoginState>>) {
         Object.assign(state, action.payload);
      },
      setUserInfo(state, action: PayloadAction<Partial<UserInfoState>>) {
         const { userInfoList } = action.payload;

         state.userInfoList = JSON.parse(JSON.stringify(userInfoList));

         Object.assign(state, action.payload);
      },
   },
});

export const { setUserItem, setLoginState, setUserInfo } = userSlice.actions;

export default userSlice.reducer;

export const loginAsync = createAsyncAction<LoginParams, DefaultApiResult<LoginResult>>(payload => {
   return async dispatch => {
      const result = await apiLogin(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.User)

      return result;
   };
});

export const findIdAsync = createAsyncAction<FindIDParams, DefaultApiResult<FindIDResult>>(payload => {
   return async dispatch => {
      const result = await apiFindID(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.User)

      return result;
   };
});

export const initPasswordAsync = createAsyncAction<InitPasswordParams, DefaultApiResult<DefaultResult>>(payload => {
   return async dispatch => {
      const result = await apiInitPassword(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.User)

      return result;
   };
});

export const checkCodeAsync = createAsyncAction<CheckCodeParams, DefaultApiResult<CheckCodeResult>>(payload => {
   return async dispatch => {
      const result = await apiCheckCode(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.User)

      return result;
   };
});

export const checkEmailAsync = createAsyncAction<CheckEmailParams, DefaultApiResult<CheckCodeResult>>(payload => {
   return async dispatch => {
      const result = await apiCheckEmail(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.User)

      return result;
   };
});

export const joinRequestAsync = createAsyncAction<JoinRequestParams, DefaultApiResult<DefaultResult>>(payload => {
   return async dispatch => {
      const result = await apiJoinRequest(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.User)

      return result;
   };
});

export const userInfoAsync = createAsyncAction<CheckEmailParams, boolean>(payload => {
   return async dispatch => {
      const result = await apiReqUserInfo(payload);

      console.log(result);

      if (result) {
         dispatch(setUserInfo({ userInfoList: result.data.list }));
         return true;
      }

      return false;
   };
});

export const userInfoUpdateAsync = createAsyncAction<UserInfoUpdateParams, DefaultApiResult<DefaultResult>>(payload => {
   return async dispatch => {
      const result = await apiReqUserInfoUpdate(payload);

      if (payload.callbackFunc) payload.callbackFunc(result, ApiType.User)

      return result;
   };
});
