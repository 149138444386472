export const enUS_deviceRegistration = {
   'menu.deviceRegistration.id': 'Serial Num.',
   'menu.deviceRegistration.activationCode': 'Activation Code',
   'menu.deviceRegistration.location': 'Location',
   'menu.deviceRegistration.address': 'Address',
   'menu.deviceRegistration.email': 'Email',
   'menu.deviceRegistration.name': 'Name',
   'menu.deviceRegistration.phone': 'Phone',
   'menu.deviceRegistration.description': 'Note',
   'menu.deviceRegistration.activated': 'Reg.',
   'menu.deviceRegistration.activatedDate': 'ActivatedDate',
   'menu.deviceRegistration.activatedPeriod': 'ActivatedPeriod',
   'menu.deviceRegistration.washCount': 'Wash',
   'menu.deviceRegistration.state': 'State',
   'menu.deviceRegistration.adCount': 'AdCount',
   'menu.deviceRegistration.cumulativeAdCount': 'CumulativeAdCount',
   'menu.deviceRegistration.totalWashCount': 'TotalWashCount',
   'menu.deviceRegistration.totalPlayCount': 'TotalPlayCount',
   'menu.deviceRegistration.totalViewCount': 'TotalViewCount',
   'menu.deviceRegistration.totalURLCount': 'TotalURLCount',
   'menu.deviceRegistration.totalQRCount': 'TotalQRCount',
   'menu.deviceRegistration.search.name': 'Serial Number',
   'menu.deviceRegistration.search.address': 'Address',
   'menu.deviceRegistration.search.description': 'Note',
   'menu.deviceRegistration.organizedAd': 'OrganizedAd',
   'menu.deviceRegistration.history': 'History',
   'menu.deviceRegistration.info.message.group': ' Group includes the devices below.',
   'menu.deviceRegistration.info.message.location': ' has devices below.',
   'menu.deviceRegistration.info.message.contract': ' contract, ',
   'menu.deviceRegistration.modal.error.id': 'Please Input Device Serial Number',
   'menu.deviceRegistration.modal.error.location': 'Please Input Location',
   'menu.deviceRegistration.modal.error.cancel.name': 'Please Input Name of the person canceling',
   'menu.deviceRegistration.modal.error.cancel.phone': 'Please Input Phone of the person canceling',
   'menu.deviceRegistration.modal.error.cancel.company': 'Please Input Company of the person canceling',
   'menu.deviceRegistration.modal.registration.title': 'Device Registration',
   'menu.deviceRegistration.modal.update.title': 'Device Information Update',
   'menu.deviceRegistration.modal.delete.title': 'Device Delete',
   'menu.deviceRegistration.modal.organizedAd.title': 'Organized Advertisement',
   'menu.deviceRegistration.modal.history.title': 'Device History',
   'menu.deviceRegistration.modal.cancel.title': 'Cancel Registration',
   'menu.deviceRegistration.modal.cancel.ment': 'Please enter the information of the person who is canceling device activation.',
   'menu.deviceRegistration.modal.delete.description': 'Do you want delete?',
   'menu.deviceRegistration.modal.registration.ok': 'Registration',
   'menu.deviceRegistration.modal.update.ok': 'Update',
   'menu.deviceRegistration.modal.adjust': 'Adjust OrganizedAd',
   'menu.deviceRegistration.modal.close': 'Close',
   'menu.deviceRegistration.modal.cancel': 'Cancel',
   'menu.deviceRegistration.modal.registration.name': 'Serial Number',
   'menu.deviceRegistration.modal.registration.notRegist': 'Not Registration Yet',
   'menu.deviceRegistration.modal.registration.activateCode': 'Activation Code',
   'menu.deviceRegistration.modal.registration.location': 'Location',
   'menu.deviceRegistration.modal.registration.description': 'Note',
   'menu.deviceRegistration.modal.registration.group': 'Group',
   'menu.deviceRegistration.modal.cancel.name': 'name',
   'menu.deviceRegistration.modal.cancel.phone': 'phone',
   'menu.deviceRegistration.modal.cancel.company': 'company',
   'menu.deviceRegistration.modal.cancel.memo': 'memo',
   'menu.deviceRegistration.modal.cancel.description': 'description',
};