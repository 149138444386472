import { apiReqNoticeList, apiReqSearchDeviceForMain } from 'src/api/management.api';
import ApiTable, { ApiTableRef } from 'src/components/core/apiTable/ApiTable';
import { Notice } from 'src/interface/management/dashboard';
import { LocaleFormatter, useLocale } from 'src/locales';
import { getTimeStringByDate } from 'src/utils/StringUtil';
import { LeftOutlined, RightOutlined, TagOutlined } from '@ant-design/icons';
import { FC, useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { Moment } from 'moment';
import moment from 'moment';
import { getRandomInt } from 'src/utils/mathUtil';
const { RangePicker } = DatePicker;

type RangeValue = [Moment | null, Moment | null] | null;

const MainDeviceList: FC = () => {
   const tableColums = [
      { title: "일자", width: '100px', dataIndex: 'date', key: 'date', render: (data: string) => (<span style={{ textAlign: 'center' }}>{getTimeStringByDate(new Date(data), "YYYY. MM. DD", false, false)}</span>) },
      { title: "재생수", dataIndex: 'playCount', key: 'playCount', align: 'center', },
      { title: "시청자수", dataIndex: 'viewCount', key: 'viewCount', align: 'center', },
      { title: "활성 사용자", dataIndex: 'activationCount', key: 'activationCount', align: 'center', },
      { title: "QR", dataIndex: 'qrCount', key: 'qrCount', align: 'center', },
      { title: "URL", dataIndex: 'urlCount', key: 'urlCount', align: 'center', },
      { title: "설치대수", dataIndex: 'deviceCount', key: 'deviceCount', align: 'center', },
   ];
   
   const navigate = useNavigate();
   const { formatMessage } = useLocale();
   const apiTableRef = useRef<ApiTableRef>();
   const [pickerOpen, setPickerOpen] = useState<boolean>(false);
   const [dates, setDates] = useState<RangeValue>(null);
   const [value, setValue] = useState<RangeValue>(null);

   const disabledDate = (current: Moment) => {
      if (!dates) {
         return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], 'days') > 6;
      const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;
      return !!tooEarly || !!tooLate;
   };

   const onOpenChange = (open: boolean) => {
      if (open) {
         setDates([null, null]);
      } else {
         setDates(null);
      }
   };

   const selectDate = (date: RangeValue) => {
      if(date != null) {
         setValue(date);
         apiTableRef.current?.setApiParam({ 
            startDate: date[0]?.format('YYYY-MM-DD'),
            endDate: date[1]?.format('YYYY-MM-DD')
         });
      }
   }

   useEffect(() => {
      let today = new Date();
      let prevDay = new Date(new Date().setDate(new Date().getDate() - 6));
      apiTableRef.current?.setApiParam({ 
         startDate: getTimeStringByDate(prevDay, 'YYYY-MM-DD', false, false),
         endDate: getTimeStringByDate(today, 'YYYY-MM-DD', false, false)
      });
   }, []);

   return (
      <div>
         <div className='mainListTitle' style={{display:'flex', justifyContent:'space-between'}}>
            <div className='date-selector'>
               <Button onClick={() => setPickerOpen(!pickerOpen)}><Space>일자별 요약{pickerOpen ? <LeftOutlined /> :<RightOutlined />}</Space></Button>
               <RangePicker
                  className={pickerOpen ?'picker-open':'picker-close'}
                  value={dates || value}
                  disabledDate={disabledDate}
                  onCalendarChange={val => setDates(val)}
                  onChange={selectDate}
                  onOpenChange={onOpenChange}
               />
            </div>
            <span style={{ cursor: 'pointer', marginTop: '5px' }} onClick={() => navigate('/dataManagement/deviceBasedDataMng')}>더보기</span>
         </div>
         <ApiTable
            pageApi={apiReqSearchDeviceForMain}
            ref={apiTableRef}
            columns={tableColums}
            pageItemCnt={7}
            combineSearchButton
            searchAreaLocation='bottom'
         />
      </div>
   );
}

export default MainDeviceList;