export const enUS_title = {
   'title.login': 'Login',
   'title.findId': 'FindID',
   'title.register': 'Register',
   'title.userInfo': 'UserInfo',
   'title.update': 'UserInfoUpdate',
   'title.dashboard': 'Dashboard',
   'title.adManagement': 'AD Management',
   'title.adManagement.registration': 'AD Management',
   'title.adManagement.deviceOrganize': 'AD Management',
   'title.adManagement.deviceExpunge': 'AD Management',
   'title.dataManagement': 'Data Management',
   'title.dataManagement.adBased': 'Data Management',
   'title.dataManagement.deviceBased': 'Data Management',
   'title.deviceManagement': 'Device Management',
   'title.deviceManagement.deviceReg': 'Device Management',
   'title.deviceManagement.contractReg': 'Device Management',
   'title.deviceManagement.groupReg': 'Device Management',
   'title.deviceManagement.locationReg': 'Device Management',
   'title.userManagement': 'User Management',
   'title.userManagement.company': 'User Management',
   'title.userManagement.brand': 'User Management',
   'title.userManagement.userInfo': 'User Management',
   'title.deviceLog': 'Device Log',
};
