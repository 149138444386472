import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Input, Modal, notification, Spin } from 'antd';
import cryptoJs from 'crypto-js';
import { WarningOutlined } from '@ant-design/icons';
import { apiReqCodePagePassword } from '../../../api/management.api' //'/api/management.api';
import { DefaultApiResult } from '../../../interface/management';
import { ResError } from '../../../interface/etc/common';

export interface PasswordModalProps{
   setIsUnlock: Dispatch<SetStateAction<boolean>>
}

const PasswordModal = (props: PasswordModalProps) => {
   //const enc: string = "EIdwdvjS+K0YhWHAJfa7JA==";
   const key:string = "ndGXla1GztWkg5PjomzTHPFmRd5XpmWA0ctwQA+tv0E=";
   const iv:string = "luNw+dg5m1YQdHwLQNcCdw=="

   // let inputPassword = "";
   const [pagePassword, setPagePassword] = useState("");
   const [inputPassword, setInputPassword] = useState("");
   const [isModalOpen, setIsModalOpen] = useState(true);
   const [isAccessable, setIsAccessable] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
   };

   useEffect(() => {
      initPassword();
   }, [])

   async function initPassword(){
      const result = await apiReqCodePagePassword() as DefaultApiResult<unknown>
      console.log("initPassword.isSuccess", result.isSuccess);
      if(result.isSuccess){
         setPagePassword(cryptoJs.AES.encrypt(result.data as string, cryptoJs.enc.Base64.parse(key), {
            iv: cryptoJs.enc.Base64.parse(iv),
            padding: cryptoJs.pad.Pkcs7,
            mode: cryptoJs.mode.CBC
         }).toString());
         setIsAccessable(true)
      }else{
         var text = "페이지 정보가 올바르지 않습니다.";
         var errorData = result.data as ResError;
         var description = errorData.errorMessage ? errorData.errorMessage :
            errorData.logMessage ? errorData.logMessage :
               errorData.resCode && errorData.resCodeName && errorData.resCodeDescription ? errorData.resCodeName + `(${errorData.resCode}): ` + errorData.resCodeDescription :
                  "errorData 알 수 없음";

         alert(text + "\n" + description )
         setIsAccessable(false)
      }
   }

   function okClicked() {
      if (!inputPassword || inputPassword.length == 0) return;

      if (!isAccessable){
         notification.open({
            message: <div>페이지 로딩에 문제가 있습니다.</div>,
            description: <div>페이지를 새로고침 후 재시도 바랍니다.</div>,
            icon: <WarningOutlined style={{ color: '#ff5555' }} />,
         });
         return;
      }

      console.log(`inputPw : ${inputPassword}`)
      const pass = inputPassword
      setInputPassword("");

      if (cryptoJs.AES.encrypt(pass, cryptoJs.enc.Base64.parse(key), {
         iv: cryptoJs.enc.Base64.parse(iv),
         padding: cryptoJs.pad.Pkcs7,
         mode: cryptoJs.mode.CBC,
      }).toString() == pagePassword) {
         console.log("비밀번호 일치");
         setIsModalOpen(false);
         props.setIsUnlock(true);
      } else {
         console.log("비밀번호 불일치");
         notification.open({
            message: <div> 비밀번호가 일치하지 않습니다.</div>,
            description: <div>비밀번호는 주기적으로 변경 됩니다 &nbsp; <Spin /> <br/> 기존 비밀번호와 다를 경우 관리자에게 문의 바랍니다.</div>,
            icon: <WarningOutlined style={{ color:'#ff5555'}} />,
         });
      }
   }

   return (
      <>
         {/* <Button type="primary" onClick={showModal}>Open Modal</Button> */}
         <Modal title="접근 비밀번호 입력" open={isModalOpen} onOk={handleOk} centered cancelButtonProps={undefined} closable={false} footer={<Button type='primary' onClick={okClicked}>확인</Button>}>
            <Input.Password style={{ width: '70%' }} value={inputPassword} onChange={(e) => setInputPassword(e.target.value)} onKeyDown={(e)=> (e.key == 'Enter')? okClicked() : undefined } />
         </Modal>
      </>
   );
};

export default PasswordModal;